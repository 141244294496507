import { ActivityHistoryActions, ActivityHistoryActionTypes } from './activity-history.actions';
import {
  ActivityEventsDataInterface,
  FixedAndCurrentVersionInterface,
  ILineViewAdvancedFilterParams,
  OverlappedActivitiesInterface,
  WorkorderNumbersDataInterface,
} from './activity-history.model';
import { ActivityEditModalDataInterface } from '../../view/activity-review/activity-review.model';
import * as _ from 'lodash';
import moment from 'moment';
import { mysqlTimestampFormat } from '../../shared/helper/date';
import { HelperService } from '../../shared/service/helper.service';

export interface ActivityEventsReducerInterface {
  activityEventsDataLoading: boolean;
  activityEventsDataLoaded: boolean;
  activityEventsCurrentPage: number;
  activityEventsOrderBy: 'asc' | 'desc';
  activityEventsPageSize: number;
  activityEventsTotalPages: number;
  activityEventsTotalResults: number;
  activityEvents: ActivityEventsDataInterface[];
  filters: ILineViewAdvancedFilterParams;
}

export interface WorkOrdersReducerInterface {
  workOrdersDataLoaded: boolean;
  workOrdersDataLoading: boolean;
  inProgressWorkOrdersDataLoaded: boolean;
  inProgressWorkOrdersDataLoading: boolean;
  workOrdersCurrentPage: number;
  workOrdersOrderBy: string;
  workOrdersOrderDesc: boolean;
  workOrdersPageSize: number;
  workOrdersTotalPages: number;
  workOrdersTotalResults: number;
  workOrders: WorkorderNumbersDataInterface[];
  inProgressWorkOrderCount: number;
}

export interface ActivityEventsMissingDataReducerInterface {
  activityEventsMissingDataLoading: boolean;
  activityEventsMissingDataLoaded: boolean;
  activityEventsMissingDataCurrentPage: number;
  activityEventsMissingDataOrderBy: 'asc' | 'desc';
  activityEventsMissingDataPageSize: number;
  activityEventsMissingDataTotalPages: number;
  activityEventsMissingDataTotalResults: number;
  activityEventsMissingData: ActivityEventsDataInterface[];
}

export interface ActivityEventsDataReducerInterface {
  selectedActivityData: ActivityEditModalDataInterface;
  selectedActivityDataLoading: boolean;
  selectedActivityDataLoaded: boolean;
  deleteManyActivityHistoryDataLoading: boolean;
  deleteManyActivityHistoryDataLoaded: boolean;
}

export interface ActivityFormDataInterface {
  activityFormDataSaveLoading: boolean;
  activityFormDataSaveLoaded: boolean;
}

export interface OverlappingActivityDataReducerInterface {
  overlapActivityDataLoading: boolean;
  overlapActivityDataLoaded: boolean;
  overlapOngoingActivityDataLoading: boolean;
  overlapOngoingActivityDataLoaded: boolean;
  overlappedActivityData: OverlappedActivitiesInterface;
  mainActivity: FixedAndCurrentVersionInterface;
}

export interface ActivityHistoryState
  extends ActivityEventsReducerInterface,
    WorkOrdersReducerInterface,
    ActivityEventsMissingDataReducerInterface,
    ActivityEventsDataReducerInterface,
    ActivityFormDataInterface,
    OverlappingActivityDataReducerInterface {
  bulkEditLoading: boolean;
  errors: any[];
}

export const initialState: ActivityHistoryState = {
  bulkEditLoading: false,
  activityFormDataSaveLoaded: false,
  activityFormDataSaveLoading: false,
  selectedActivityDataLoaded: false,
  selectedActivityDataLoading: false,
  activityEventsMissingData: [],
  activityEventsMissingDataCurrentPage: 0,
  activityEventsMissingDataLoaded: false,
  activityEventsMissingDataLoading: false,
  activityEventsMissingDataOrderBy: 'asc',
  activityEventsMissingDataPageSize: 0,
  activityEventsMissingDataTotalPages: 0,
  activityEventsMissingDataTotalResults: 0,
  activityEvents: [],
  activityEventsCurrentPage: 0,
  activityEventsOrderBy: 'asc',
  activityEventsPageSize: 0,
  activityEventsTotalPages: 0,
  activityEventsTotalResults: 0,
  workOrdersCurrentPage: 0,
  workOrdersOrderBy: '',
  workOrdersOrderDesc: true,
  workOrdersPageSize: 0,
  workOrdersTotalPages: 0,
  workOrdersTotalResults: 0,
  errors: [],
  selectedActivityData: null,
  workOrders: [],
  inProgressWorkOrderCount: 0,
  activityEventsDataLoading: false,
  activityEventsDataLoaded: false,
  workOrdersDataLoaded: false,
  workOrdersDataLoading: false,
  inProgressWorkOrdersDataLoaded: false,
  inProgressWorkOrdersDataLoading: false,
  overlapActivityDataLoading: false,
  overlapActivityDataLoaded: false,
  overlapOngoingActivityDataLoading: false,
  overlapOngoingActivityDataLoaded: false,
  mainActivity: {},
  overlappedActivityData: {
    activityHistoriesToCreate: [],
    activityHistoriesToDelete: [],
    activityHistoriesToUpdate: [],
    currentVersion: [],
    fixedVersion: [],
    hasAnyChange: false,
    isValid: false,
  },
  deleteManyActivityHistoryDataLoading: false,
  deleteManyActivityHistoryDataLoaded: false,
  filters: null,
};

export function activityHistoryReducer(state = initialState, action: ActivityHistoryActions): ActivityHistoryState {
  switch (action.type) {
    case ActivityHistoryActionTypes.GetWorkOrders:
      return {
        ...state,
        workOrdersDataLoading: true,
      };
    case ActivityHistoryActionTypes.GetWorkOrdersLoaded:
      return {
        ...state,
        workOrders: action.payload.data.map((workOrder: WorkorderNumbersDataInterface) => {
          return {
            ...workOrder,
            isEditButtonVisible: false,
          };
        }),
        workOrdersDataLoading: false,
        workOrdersDataLoaded: true,
        workOrdersCurrentPage: action.payload.page,
        workOrdersOrderBy: action.payload.orderBy,
        workOrdersOrderDesc: action.payload.orderDesc,
        workOrdersPageSize: action.payload.count,
        workOrdersTotalPages: action.payload.pageCount,
        workOrdersTotalResults: action.payload.total,
      };
    case ActivityHistoryActionTypes.GetInProgressWorkOrders:
      return {
        ...state,
        inProgressWorkOrdersDataLoading: true,
        inProgressWorkOrdersDataLoaded: false,
      };
    case ActivityHistoryActionTypes.GetInProgressWorkOrdersLoaded:
      return {
        ...state,
        inProgressWorkOrdersDataLoading: false,
        inProgressWorkOrdersDataLoaded: true,
        inProgressWorkOrderCount: action.payload.count,
      };
    case ActivityHistoryActionTypes.GetActivityEvents:
      return {
        ...state,
        activityEventsDataLoading: true,
      };
    case ActivityHistoryActionTypes.GetActivityEventsLoaded:
      return {
        ...state,
        activityEventsDataLoading: false,
        activityEventsDataLoaded: true,
        activityEvents: action.payload.data,
        activityEventsCurrentPage: action.payload.page,
        activityEventsOrderBy: 'asc',
        activityEventsTotalPages: action.payload.pageCount,
        activityEventsTotalResults: action.payload.total,
      };
    case ActivityHistoryActionTypes.GetActivityEventMissingData:
      return {
        ...state,
        activityEventsMissingDataLoading: true,
      };
    case ActivityHistoryActionTypes.GetActivityEventMissingDataLoaded:
      return {
        ...state,
        activityEventsMissingDataLoading: false,
        activityEventsMissingDataLoaded: true,
        activityEventsMissingData: action.payload.data,
        activityEventsMissingDataCurrentPage: action.payload.page,
        activityEventsMissingDataOrderBy: 'asc',
        activityEventsMissingDataTotalPages: action.payload.pageCount,
        activityEventsMissingDataTotalResults: action.payload.total,
      };
    case ActivityHistoryActionTypes.FetchError:
      const loadedActions = {
        [ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted]: { bulkEditLoading: false },
        [ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted]: {
          activityFormDataSaveLoading: false,
          activityFormDataSaveLoaded: true,
        },
      };

      const actionType = action.actionType || 'noAction';

      return _.assign(
        {
          ...state,
          errors: action.payload,
        },
        loadedActions[actionType] || {},
      );
    case ActivityHistoryActionTypes.GetActivityEventData:
      return {
        ...state,
        selectedActivityDataLoaded: false,
        selectedActivityDataLoading: true,
        activityEventsDataLoading: false,
        activityEventsDataLoaded: false,
        activityEventsMissingDataLoading: false,
        activityEventsMissingDataLoaded: false,
      };
    case ActivityHistoryActionTypes.GetActivityEventDataCompleted:
      return {
        ...state,
        selectedActivityDataLoaded: true,
        selectedActivityDataLoading: false,
        selectedActivityData: action.payload,
      };
    case ActivityHistoryActionTypes.FlushActivityEventData:
      return {
        ...state,
        selectedActivityDataLoaded: false,
        selectedActivityDataLoading: false,
        activityEventsDataLoading: false,
        activityEventsDataLoaded: false,
        activityEventsMissingDataLoaded: false,
        activityEventsMissingDataLoading: false,
        selectedActivityData: null,
      };
    case ActivityHistoryActionTypes.WorkOrdersUpdateGoodAndScrap:
      const returnWorkOrders = [];
      for (let i = 0; i < state.workOrders.length; i += 1) {
        returnWorkOrders.push(state.workOrders[i]);
      }
      return {
        ...state,
        workOrders: returnWorkOrders,
      };

    case ActivityHistoryActionTypes.GetWorkOrderGoodCount:
      return {
        ...state,
        ...{
          workOrders: HelperService.cloneDeep(state.workOrders).map((workOrder: WorkorderNumbersDataInterface) => {
            if (workOrder.id === action.workOrderId) {
              workOrder.goodCount = null;
              workOrder.loadingGoodCount = true;
              workOrder.isEditButtonVisible = true;
            }

            return workOrder;
          }),
        },
      };
    case ActivityHistoryActionTypes.GetWorkOrderGoodCountLoaded:
      return {
        ...state,
        ...{
          workOrders: HelperService.cloneDeep(state.workOrders).map((workOrder: WorkorderNumbersDataInterface) => {
            if (workOrder.id === action.payload['workOrderId']) {
              workOrder.goodCount = action.payload.data.goodCount;
              workOrder.initialCount = action.payload.data.initialCount;
              workOrder.loadingGoodCount = false;
            }

            return workOrder;
          }),
        },
      };
    case ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventData:
      return {
        ...state,
        activityFormDataSaveLoading: true,
        activityFormDataSaveLoaded: false,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: false,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: false,
      };
    case ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventData:
      return {
        ...state,
        bulkEditLoading: true,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: false,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: false,
      };
    case ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted:
      return {
        ...state,
        bulkEditLoading: false,
      };
    case ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted:
      return {
        ...state,
        activityFormDataSaveLoading: false,
        activityFormDataSaveLoaded: true,
      };
    case ActivityHistoryActionTypes.ClearFormDataStatus:
      return {
        ...state,
        bulkEditLoading: false,
        activityFormDataSaveLoading: false,
        activityFormDataSaveLoaded: false,
        selectedActivityDataLoaded: false,
        selectedActivityDataLoading: false,
      };
    case ActivityHistoryActionTypes.ValidateActivityOverlap:
      return {
        ...state,
        mainActivity: action.mainActivity,
        overlapActivityDataLoading: true,
        overlapActivityDataLoaded: false,
      };
    case ActivityHistoryActionTypes.ValidateActivityOverlapCompleted:
      return {
        ...state,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: true,
        overlappedActivityData: action.payload.data,
      };
    case ActivityHistoryActionTypes.ValidateErrorActivityOverlap:
      return {
        ...state,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: true,
      };
    case ActivityHistoryActionTypes.ValidateErrorOngoingActivityOverlap:
      return {
        ...state,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: true,
      };
    case ActivityHistoryActionTypes.ValidateOngoingActivityOverlap:
      return {
        ...state,
        overlapOngoingActivityDataLoading: true,
        overlapOngoingActivityDataLoaded: false,
        mainActivity: action.mainActivity,
      };
    case ActivityHistoryActionTypes.ValidateOngoingActivityOverlapCompleted:
      const overlappedActivityData = HelperService.cloneDeep(action.payload.data);
      const hasOngoingActivityCurrent = overlappedActivityData.currentVersion.findIndex(
        (activity) => activity.canBeOverwritten && activity.isSentRecord && activity.isOngoing,
      );
      const hasOngoingActivityFixed = overlappedActivityData.fixedVersion.findIndex(
        (activity) => activity.canBeOverwritten && activity.isSentRecord && activity.isOngoing,
      );
      const mainActivityInformation = {
        task: state.mainActivity.task,
        workOrder: state.mainActivity.workOrder,
        activity: state.mainActivity.activity,
      };

      if (hasOngoingActivityCurrent !== -1) {
        overlappedActivityData.currentVersion[hasOngoingActivityCurrent] = {
          ...overlappedActivityData.currentVersion[hasOngoingActivityCurrent],
          ...mainActivityInformation,
        };
      }

      if (hasOngoingActivityFixed !== -1) {
        overlappedActivityData.fixedVersion[hasOngoingActivityFixed] = {
          ...overlappedActivityData.fixedVersion[hasOngoingActivityFixed],
          ...mainActivityInformation,
        };
      }

      return {
        ...state,
        overlappedActivityData,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: true,
      };
    case ActivityHistoryActionTypes.SetOverlapActivityParametersDefault:
      return {
        ...state,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: false,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: false,
      };
    case ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlap:
      return {
        ...state,
        bulkEditLoading: true,
        overlapOngoingActivityDataLoading: false,
        overlapOngoingActivityDataLoaded: false,
        overlapActivityDataLoading: false,
        overlapActivityDataLoaded: false,
      };
    case ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted:
      return {
        ...state,
        bulkEditLoading: false,
      };
    case ActivityHistoryActionTypes.SetSelectedFilters:
      return {
        ...state,
        filters: {
          lineViewAdvancedFilter: action.response.lineViewAdvancedFilter,
          lineViewAdvancedFilterPage: action.response.lineViewAdvancedFilterPage,
          missingDataAdvancedFilter: action.response.missingDataAdvancedFilter,
          missingDataAdvancedFilterPage: action.response.missingDataAdvancedFilterPage,
        },
      };
    default:
      return state;
  }
}

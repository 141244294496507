import {
  CountEntriesInterface,
  IncrementDistributionMethod,
  RegularOrHourlyManualCount,
} from '../../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { DecimalHelper } from './decimal-helper';
import { ScwMatInputRule } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { DECIMAL_MAX_VALUE_AS_INT, ECountEntryMethods } from '../../../../constants';
import { ManualCountTypes } from '../../../view/home/models/line.model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { getCurrentDateTimeAsMoment, mysqlTimestampFormat } from '../date';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { take } from 'rxjs/operators';
import * as ManualCountActions from '../../../store/work-order-manual-count/actions';
import * as AppActions from '../../../store/app/actions';
import * as moment from 'moment/moment';
import { maxBy } from 'lodash';
import { HelperService } from '../../service/helper.service';

export type TFlexibleProductionCount =
  | { goodCount: string; scrapCount: string }
  | { initialCount: string; goodCount: string }
  | { initialCount: string; scrapCount: string };
export type TFlexibleCount = { reworkCount: string } & TFlexibleProductionCount;

export type TAllCounts = { goodCount: string; scrapCount: string; initialCount: string; reworkCount: string };

export enum ECountTypes {
  goodCount = 'goodCount',
  scrapCount = 'scrapCount',
  initialCount = 'initialCount',
}

export interface IManageCountOutput {
  previousCounts: TAllCounts;
  increments: TAllCounts;
  totalCounts: TAllCounts;
}

@Injectable()
export class CountHelper {
  private readonly errors = {
    goodCount: this.translate.instant(
      'main.workOrder.workOrderManuelCountForm.goodCount.errors.goodCountIsLessThanZero',
    ),
    initialCount: this.translate.instant(
      'main.workOrder.workOrderManuelCountForm.initialCount.errors.initialCountIsLessThanZero',
    ),
    reworkCount: this.translate.instant(
      'main.workOrder.workOrderManuelCountForm.reworkCount.errors.reworkCountIsLessThanZero',
    ),
  };

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
  ) {}

  public getErrorIfResultingCountIsNegative(
    errorTarget: 'goodCount' | 'initialCount' | 'reworkCount',
    inputCounts: TAllCounts,
    previousCounts: TAllCounts,
    countEntryMethod: ECountEntryMethods,
  ): [ScwMatInputRule] | never[] {
    let isNegative = false;

    switch (countEntryMethod) {
      case ECountEntryMethods.REDUCIBLE_INCREMENTAL:
        isNegative = this.decimalHelper.isLessThan(
          this.decimalHelper.add(inputCounts[errorTarget], previousCounts[errorTarget]),
          '0',
        );
        break;
      case ECountEntryMethods.REDUCIBLE_CUMULATIVE:
      case ECountEntryMethods.EDIT_OR_INCREMENTAL_ADD:
        isNegative = this.decimalHelper.isLessThan(inputCounts[errorTarget], '0');
        break;
      case ECountEntryMethods.CUMULATIVE_ADD:
        isNegative = this.decimalHelper.isLessThan(inputCounts[errorTarget], previousCounts[errorTarget]);
        break;
    }

    if (!isNegative) {
      return [];
    }

    // DECIMAL_MAX_VALUE_AS_INT is used so this rule always produces error
    return [
      {
        minValue: DECIMAL_MAX_VALUE_AS_INT,
        message: this.errors[errorTarget],
      },
    ];
  }

  public convertFlexibleCountToAllCounts(value: TFlexibleCount): TAllCounts {
    if ('goodCount' in value && 'scrapCount' in value) {
      return {
        ...value,
        initialCount: this.decimalHelper.add(value.goodCount, value.scrapCount),
      };
    }

    if ('initialCount' in value && 'goodCount' in value) {
      return {
        ...value,
        scrapCount: this.decimalHelper.subtract(value.initialCount, value.goodCount),
      };
    }

    return {
      ...value,
      goodCount: this.decimalHelper.add(value.initialCount, value.scrapCount),
    };
  }

  public convertInputCountsIntoAllCounts(values: CountEntriesInterface, manualCountType: ManualCountTypes): TAllCounts {
    const entry1 = this.decimalHelper.sanitizeString(values.entry1);
    const entry2 = this.decimalHelper.sanitizeString(values.entry2);
    const reworkCount = this.decimalHelper.sanitizeString(values.reworkCount);

    switch (manualCountType) {
      case ManualCountTypes.YIELD_AND_SCRAP:
        const goodAndScrap = {
          goodCount: entry1,
          scrapCount: entry2,
        };

        return {
          ...goodAndScrap,
          reworkCount,
          initialCount: this.extractTargetCount(goodAndScrap, ECountTypes.initialCount),
        };
      case ManualCountTypes.INITIAL_AND_YIELD:
        const initialAndGood = {
          initialCount: entry1,
          goodCount: entry2,
        };

        return {
          ...initialAndGood,
          reworkCount,
          scrapCount: this.extractTargetCount(initialAndGood, ECountTypes.scrapCount),
        };
      case ManualCountTypes.INITIAL_AND_SCRAP:
        const initialAndScrap = {
          initialCount: entry1,
          scrapCount: entry2,
        };

        return {
          ...initialAndScrap,
          reworkCount,
          goodCount: this.extractTargetCount(initialAndScrap, ECountTypes.goodCount),
        };
    }
  }

  public convertFlexibleCountsIntoInputCounts(
    previousCounts: TAllCounts,
    manualCountType: ManualCountTypes,
  ): CountEntriesInterface {
    const formattedGoodCount = this.decimalHelper.formatBySeparator(previousCounts.goodCount, false);
    const formattedScrapCount = this.decimalHelper.formatBySeparator(previousCounts.scrapCount, false);
    const formattedInitialCount = this.decimalHelper.formatBySeparator(previousCounts.initialCount, false);
    const reworkCount = this.decimalHelper.formatBySeparator(previousCounts.reworkCount, false);

    switch (manualCountType) {
      case ManualCountTypes.YIELD_AND_SCRAP:
        return { entry1: formattedGoodCount, entry2: formattedScrapCount, reworkCount };
      case ManualCountTypes.INITIAL_AND_YIELD:
        return { entry1: formattedInitialCount, entry2: formattedGoodCount, reworkCount };
      case ManualCountTypes.INITIAL_AND_SCRAP:
        return { entry1: formattedInitialCount, entry2: formattedScrapCount, reworkCount };
    }
  }

  public submitReducibleCountDiff(
    counts: IManageCountOutput,
    workOrderId: number,
    lineId: number,
    productionCounts: RegularOrHourlyManualCount[],
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const isInitialLessThanZero = this.decimalHelper.isLessThan(counts.increments.initialCount, '0');
    const isGoodLessThanZero = this.decimalHelper.isLessThan(counts.increments.goodCount, '0');
    const isReworkLessThanZero = this.decimalHelper.isLessThan(counts.increments.reworkCount, '0');

    if (!isInitialLessThanZero && !isGoodLessThanZero && !isReworkLessThanZero) {
      this.store
        .select('user', 'timezone')
        .pipe(take(1))
        .subscribe((timezone) => {
          const submitTimestamp: string = this.helperService.nowAsISO();
          const systemCountAddedCounts = this.getOverriddenSystemCountCompensatedTotals(
            counts,
            productionCounts,
            submitTimestamp,
          );
          this.store.dispatch(
            new ManualCountActions.CreateManualCount({
              workOrderId,
              lineId,
              goodCount: systemCountAddedCounts.increments.goodCount,
              scrapCount: systemCountAddedCounts.increments.scrapCount,
              reworkCount: systemCountAddedCounts.increments.reworkCount,
              timestamp: submitTimestamp,
            }),
          );
        });

      return;
    }

    const isScrapLessThanZero = this.decimalHelper.isLessThan(counts.increments.scrapCount, '0');

    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading(
        {
          workOrderId,
          goodCount: counts.totalCounts.goodCount,
          scrapCount: counts.totalCounts.scrapCount,
          reworkCount: counts.totalCounts.reworkCount,
          doApproveOngoingShiftHour: false,
          ...(isScrapLessThanZero && isGoodLessThanZero && isReworkLessThanZero
            ? {}
            : { incrementDistributionMethod: IncrementDistributionMethod.lastHour }),
        },
        true,
      ),
    );
  }

  public extractIncrementsFromTotalCounts(previousCounts: TAllCounts, totalCounts: TAllCounts): TAllCounts {
    return {
      initialCount: this.decimalHelper.subtract(totalCounts.initialCount, previousCounts.initialCount),
      goodCount: this.decimalHelper.subtract(totalCounts.goodCount, previousCounts.goodCount),
      scrapCount: this.decimalHelper.subtract(totalCounts.scrapCount, previousCounts.scrapCount),
      reworkCount: this.decimalHelper.subtract(totalCounts.reworkCount, previousCounts.reworkCount),
    };
  }

  private getOverriddenSystemCountCompensatedTotals(
    counts: IManageCountOutput,
    productionCounts: RegularOrHourlyManualCount[],
    submitTimestamp: string,
  ): IManageCountOutput {
    const sameHourManualCounts: RegularOrHourlyManualCount[] = productionCounts.filter(
      (tableData: RegularOrHourlyManualCount) =>
        !tableData.isSystem && moment(tableData.timestamp).endOf('h').isSame(moment(submitTimestamp).endOf('h')),
    );

    const maxManualCountTimestampWithinPostDataHour: string | undefined = maxBy(
      sameHourManualCounts,
      'timestamp',
    )?.timestamp;

    if (
      maxManualCountTimestampWithinPostDataHour !== undefined &&
      submitTimestamp <= maxManualCountTimestampWithinPostDataHour
    ) {
      return counts;
    }

    const sameHourSystemCount: RegularOrHourlyManualCount | undefined = productionCounts.find(
      (tableData: RegularOrHourlyManualCount) =>
        tableData.isSystem && moment(tableData.timestamp).endOf('h').isSame(moment(submitTimestamp).endOf('h')),
    );

    return sameHourSystemCount
      ? {
          ...counts,
          increments: this.addSameHourSystemCount(counts.increments, sameHourSystemCount),
          totalCounts: this.addSameHourSystemCount(counts.totalCounts, sameHourSystemCount),
        }
      : counts;
  }

  private addSameHourSystemCount(basis: TAllCounts, sameHourSystemCount: RegularOrHourlyManualCount): TAllCounts {
    return {
      initialCount: this.decimalHelper.add(
        basis.initialCount,
        this.decimalHelper.add(
          this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
          this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
        ),
      ),
      goodCount: this.decimalHelper.add(
        basis.goodCount,
        this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
      ),
      scrapCount: this.decimalHelper.add(
        basis.scrapCount,
        this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
      ),
      reworkCount: this.decimalHelper.add(
        basis.reworkCount,
        this.decimalHelper.sanitizeString(sameHourSystemCount.reworkCount || '0'),
      ),
    };
  }

  private extractTargetCount(value: TFlexibleProductionCount, target: ECountTypes): string {
    switch (target) {
      case ECountTypes.goodCount:
        return 'goodCount' in value
          ? value.goodCount
          : this.decimalHelper.subtract(value.initialCount, value.scrapCount);
      case ECountTypes.scrapCount:
        return 'scrapCount' in value
          ? value.scrapCount
          : this.decimalHelper.subtract(value.initialCount, value.goodCount);
      case ECountTypes.initialCount:
        return 'initialCount' in value ? value.initialCount : this.decimalHelper.add(value.goodCount, value.scrapCount);
    }
  }
}

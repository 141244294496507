import {
  EWidgetType,
  WidgetConfigurationInterface,
} from './app/shared/component/page-configuration/page-configuration.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip';

export enum ELoadStatus {
  Initial,
  Loading,
  Success,
  Failure,
}

export interface IPage {
  title: string;
  icon: string;
  relatedModule?: string;
}

export interface ITableHeader {
  value: string;
  name: string;
  type?: PageConfigurationTypes;
  class?: string;
  width?: string;
  disabled?: boolean;
  selected?: boolean;
  sortable?: boolean;
  linkedTo?: string;
  opposedTo?: string[];
  colspan?: number;
  parentId?: string;
  isHiddenOnDashboardMode?: boolean;
  tooltip?: string;
  tooltipPlacement?: NgbTooltip['placement'];
  subTitle?: string;
  checkbox?: {
    value?: boolean;
    location?: 'right' | 'bottom';
    disabled?: boolean;
  };
}

export type TTableHeader<AvailableColumnNames> = Omit<ITableHeader, 'value'> & { value: AvailableColumnNames };

export enum PageConfigurationTypes {
  WIDGET = 'widget',
  TABLE = 'table',
  CHART = 'chart',
  HEADER_GROUP = 'headerGroup',
  CUSTOM = 'custom',
  BUTTON_GROUP = 'buttonGroup',
}

export enum PageRefreshIntervals {
  activity_logs = 120,
  work_orders = 120,
  production_review = 60,
  kpi_view = 180,
  line_view = 60,
  asset_view = 60,
  daily_production_performance = 600,
  global_view = 900,
  activity_view = 60,
  activity_timeline_report = 300,
  site_view = 120,
  labor_timeline = 300,
  asset_timeline = 300,
  line_view_enhanced = 60,
  labor_asset_view = 120,
  global_line_view = 120,
  production_view = 60,
  layout_plan_view = 120,
  iot_device_report = 180,
}

export interface BulkResponseFailSuccessInterface<T> {
  fail: T[];
  success: T[];
}

export interface MergedArrayDataInterface {
  id: number;
  errorMessages?: string;
}

type TNonBreakingSpace = '\u00a0';

export type TDecimalSeparator = '.' | ',';

export type TThousandSeparator = '.' | ',' | ' ' | TNonBreakingSpace;

export interface ISeparators {
  decimalSeparator: TDecimalSeparator;
  thousandSeparator: TThousandSeparator;
}

export interface IDataTableHeaders {
  headers: ITableHeader[];
  widgets: WidgetConfigurationInterface<EWidgetType.WIDGET>[];
  headerGroups?: ITableHeader[];
}

import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FeedbackModalComponentStore } from './feedback-modal.component.store';
import { provideComponentStore } from '@ngrx/component-store';
import { ScwMatTextareaModule } from '../../../shared/component/scw-mat-ui/scw-mat-textarea/scw-mat-textarea.module';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf } from '@angular/common';
import { IFeedbackModalData, IRatingFeedback, IRatingStar, ISubmitFeedback } from './feedback-modal.model';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'scw-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  standalone: true,
  imports: [ScwMatButtonModule, ScwMatTextareaModule, MatIconModule, NgClass, NgForOf, TranslateModule],
  providers: [provideComponentStore(FeedbackModalComponentStore)],
})
export class FeedbackModalComponent implements OnInit {
  @ViewChild('feedback_modal') feedbackModalRef: TemplateRef<void>;

  @Input() currentUserId: number = null;

  private readonly feedbackSessionStorageKey: string = 'isFeedbackActive';
  private readonly sessionStorageFeedbackExpirationTimeHour: number = 5;
  private subscriptions: Subscription[] = [];
  private modalRef: NgbModalRef;
  public ratingStarClass: string = 'star-gray star-hover star';
  public ratingStarHoverClass: string = 'star-gold star star-hover';
  public feedBackData: IFeedbackModalData | null = null;
  public selectedRating: number | null = null;
  public textPlaceholder: string | null = this.translate.instant('feedback.placeholder.noRating');
  public feedbackText: string | null = null;
  public stars: IRatingStar[] = [
    {
      value: 1,
      class: this.ratingStarClass,
      hint: this.translate.instant('feedback.starHint.poor'),
    },
    {
      value: 2,
      class: this.ratingStarClass,
      hint: null,
    },
    {
      value: 3,
      class: this.ratingStarClass,
      hint: null,
    },
    {
      value: 4,
      class: this.ratingStarClass,
      hint: null,
    },
    {
      value: 5,
      class: this.ratingStarClass,
      hint: this.translate.instant('feedback.starHint.excellent'),
    },
  ];
  private feedbackPlaceholderMap = new Map<number | null, string>([
    [null, this.translate.instant('feedback.placeholder.noRating')],
    [1, this.translate.instant('feedback.placeholder.oneStar')],
    [2, this.translate.instant('feedback.placeholder.twoStar')],
    [3, this.translate.instant('feedback.placeholder.threeStar')],
    [4, this.translate.instant('feedback.placeholder.fourStar')],
    [5, this.translate.instant('feedback.placeholder.fiveStar')],
  ]);

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
    private readonly componentStore: FeedbackModalComponentStore,
    private readonly toast: ToastrService,
    private readonly elementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.componentStore.feedbackData$.subscribe((feedBack$) => {
        if (feedBack$.feedbackData?.length) {
          this.feedBackData = feedBack$.feedbackData[0];
          this.openModal();
        }
      }),
      this.componentStore.submitFeedbackData$.subscribe((submit$) => {
        if (submit$.submitFeedbackData && submit$.submitFeedbackData.isCompleted) {
          this.showCustomSuccess();
        }
      }),
    );

    if (this.currentUserId && this.isFeedbackActive()) {
      this.componentStore.getFeedbackData();
      this.saveToSessionStorage();
    }
  }

  private isFeedbackActive(): boolean {
    const storedData: string | undefined = sessionStorage.getItem(this.feedbackSessionStorageKey);

    if (!storedData) {
      return true;
    }
    const hoursPassed: number = moment().diff(moment(storedData), 'hours');
    return hoursPassed >= this.sessionStorageFeedbackExpirationTimeHour;
  }

  private saveToSessionStorage(): void {
    sessionStorage.setItem(this.feedbackSessionStorageKey, moment().toISOString());
  }

  public closeModal(): void {
    this.modalRef?.close();
  }

  public dismissModal(): void {
    const dataToSubmit: ISubmitFeedback<IRatingFeedback> = {
      feedbackId: this.feedBackData.id,
      isDismissed: true,
    };

    this.componentStore.submitFeedback(dataToSubmit);
    this.closeModal();
  }

  public selectStar(value: number): void {
    this.selectedRating = this.selectedRating === value ? null : value;
    this.textPlaceholder = this.feedbackPlaceholderMap.get(this.selectedRating);
  }

  public submitFeedback(): void {
    if (this.selectedRating === null) {
      return;
    }

    const dataToSubmit: ISubmitFeedback<IRatingFeedback> = {
      feedbackId: this.feedBackData.id,
      questionId: this.feedBackData.questionGroup[0].groupId,
      answer: { text: this.feedbackText, score: this.selectedRating },
    };

    this.componentStore.submitFeedback(dataToSubmit);
    this.closeModal();
  }

  private openModal(): void {
    this.modalRef = this.ngbModal.open(this.feedbackModalRef, {
      windowClass: 'scw-modal-sm feedback-modal-window',
      backdrop: false,
      keyboard: false,
      container: this.elementRef.nativeElement,
    });
  }

  private showCustomSuccess(): void {
    this.toast.success(
      `<i class="fa-solid fas fa-hand-holding-heart"></i> ${this.translate.instant('feedback.toast.success')}`,
      null,
      {
        enableHtml: true,
        toastClass: 'feedback-custom-toast',
        positionClass: 'toast-bottom-right',
      },
    );
  }
}

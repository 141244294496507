import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, merge, Observable, Subscription, take } from 'rxjs';
import { ScwMatButtonModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatFormModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { ScwModalSize } from 'src/app/shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { ScwMatSelectModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.module';
import { ScwMatTextareaModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatModalSeparatorModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { ComponentUtilities } from 'src/app/shared/helper/component-utilities';
import { GenericHelperService } from 'src/app/shared/service/generic.helper.service';
import { ToastHelperService } from 'src/app/shared/service/toast/toast.helper.service';
import { TagType } from 'src/app/store/settings/tags/tags.model';
import { ELoadStatus } from 'src/constants.model';
import { IActivityComment, ICommentTag } from '../comment-feed-service/comment-feed.model';
import { MomentDatePipe } from '../../pipes/moment-date.pipe';
import {
  AllowedCommentObjectType,
  ETagBackgroundColors,
  IInMemoryComment,
  TFormProcess,
  TModalMode,
} from './comment-feed-modal.model';
import { CommentFeedStore } from './comment-feed-modal.store';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import { ScwMatTextAreaRule } from 'src/app/shared/component/scw-mat-ui/scw-mat-textarea/scw-mat-textarea.model';
import * as moment from 'moment';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { ImagePreviewModalComponent } from '../../../shared/component/file-upload/image-preview-modal/image-preview-modal.component';
import { FileUploadModule } from '../../../shared/component/file-upload/file-upload.module';
import { EFileUploadSource } from '../../../shared/component/file-upload/file-upload.model';
import {
  EFileType,
  IDeleteFileParams,
  IDeleteFileResponse,
  IFile,
  IGetFileParams,
  IGetFileResponse,
  IUploadFileResponse,
} from '../../../store/file-upload/file-upload.model';
import * as _ from 'lodash';
import { CameraModalComponent } from '../../../shared/component/file-upload/camera-modal/camera-modal.component';
import { FileHelperService } from '../../../shared/helper/file-helper.service';
import { HelperService } from '../../../shared/service/helper.service';

@OnDestroyDecorator
@Component({
  selector: 'app-comment-feed-modal',
  standalone: true,
  imports: [
    CommonModule,
    MomentDatePipe,
    ScwMatButtonModule,
    ScwMatFormModule,
    ScwMatModalSeparatorModule,
    ScwMatSelectModule,
    ScwMatTextareaModule,
    TranslateModule,
    FileUploadModule,
  ],
  templateUrl: './comment-feed-modal.component.html',
  styleUrls: ['./comment-feed-modal.component.scss'],
  providers: [CommentFeedStore],
})
export class CommentFeedModalComponent implements OnInit, OnDestroy {
  @Input() public readonly activityName: string | undefined;
  @Input() public readonly mode: TModalMode = 'feed';
  @Input() public readonly objectIds: readonly (number | undefined)[] | undefined;
  @Input() public readonly objectType: AllowedCommentObjectType | undefined;

  @Input() public readonly readOnly: boolean = false;
  @Input() private readonly defaultTagTypes: readonly TagType[] | undefined;

  @Input() private readonly inMemoryComments: IInMemoryComment[] = [];
  @Output() private readonly inMemoryCommentsChange = new EventEmitter<IInMemoryComment[]>();

  @ViewChild('confirmDeleteModal') private readonly confirmDeleteModal: TemplateRef<unknown>;
  @ViewChild('image_preview_modal', { static: false }) imagePreviewModalComponent: ImagePreviewModalComponent;

  public readonly inMemoryMode$: Observable<boolean> = this.commentFeedStore.inMemoryMode$;
  public readonly rawInMemoryComments$: Observable<IInMemoryComment[]> = this.commentFeedStore.rawInMemoryComments$;
  public readonly comments$: Observable<IActivityComment[]> = this.commentFeedStore.comments$;
  public readonly commentsLoadStatus$: Observable<ELoadStatus> = this.commentFeedStore.commentsLoadStatus$;
  public readonly commentToDeleteIndex$: Observable<number> = this.commentFeedStore.commentToDeleteIndex$;
  public readonly commentToEdit$: Observable<IActivityComment | null> = this.commentFeedStore.commentToEdit$;
  public readonly formProcess$: Observable<TFormProcess> = this.commentFeedStore.formProcess$;
  public readonly userDateTimeFormat$: Observable<string> = this.commentFeedStore.userDateTimeFormat$;
  public readonly userDateTimezone$: Observable<string> = this.commentFeedStore.userDateTimezone$;
  public readonly tags$: Observable<ICommentTag[]> = this.commentFeedStore.tags$;
  public readonly tagsLoadStatus$: Observable<ELoadStatus> = this.commentFeedStore.tagsLoadStatus$;
  public readonly whiteColorTextList: string[] = Object.values(ETagBackgroundColors);
  public readonly whiteGPTIcon: string = '../assets/icon/openAI_icons/chatgpt_icon_white.svg';
  public readonly blackGPTIcon: string = '../assets/icon/openAI_icons/chatgpt_icon_black.svg';
  public readonly busy$: Observable<boolean> = this.formProcess$.pipe(
    map((process: TFormProcess) => process !== 'idle'),
  );
  public readonly tagDropdownLabel$: Observable<string> = this.tagsLoadStatus$.pipe(
    map((status: ELoadStatus) => {
      const statusText: string | undefined = this.getNonSuccessStatusText(status);
      return this.translate.instant('activityCommentModal.label.commentTag') + (statusText ? ` (${statusText})` : '');
    }),
  );
  public readonly userSiteId$: Observable<number> = this.commentFeedStore.siteId$;
  public readonly commentManyFileUpload$: Observable<IUploadFileResponse[]> =
    this.commentFeedStore.commentManyFileUpload$;
  public readonly file$: Observable<IGetFileResponse> = this.commentFeedStore.file$;

  public readonly fileDeleteStatus$: Observable<IDeleteFileResponse> = this.commentFeedStore.fileDeleteStatus$;
  public readonly imageLoadStatus$: Observable<ELoadStatus> = this.commentFeedStore.imageLoadStatus$;

  public readonly LoadStatus = ELoadStatus;
  public readonly trackById = ComponentUtilities.genericTrackByIdFunction;
  public readonly commentMessageFormRules: ScwMatTextAreaRule[] = [
    { required: true },
    {
      custom: true,
      validator: (value: string): boolean => this.sanitizeCommentMessage(value).length > 0,
      message: this.translate.instant('scwMatForm.validation.required'),
    },
  ];

  public readonly EFileType = EFileType;
  public addFormComment: string;
  public addFormTags: readonly ICommentTag[] | null;
  public editFormComment: string;
  public editFormTags: readonly ICommentTag[] | null;
  public modalOpenedAt: string | undefined;
  public commentDateAsISO: string | undefined;
  public editFormFiles: IFile[] = [];
  public commentObjectToEdit: IActivityComment;
  public fileRequestParams: IGetFileParams[] = [];
  public siteId$: number;
  public deletedFile: IFile;
  public editModalIndex: number | null = null;
  public deletedFileCommentIndex: number | null = null;
  public editFormFolder: number | null;
  public fileDeleteConfirmationModalRef: NgbModalRef;
  public isCommentDeleted: boolean = false;
  public isImageFileDeleted: boolean = false;
  public selectedImage: IFile = null;
  public files: IFile[] = [];

  private readonly lastBulkInsertResponse$: Observable<unknown> = this.commentFeedStore.lastBulkInsertResponse$;
  private readonly lastDeletedComment$: Observable<IActivityComment | undefined> =
    this.commentFeedStore.lastDeletedComment$;
  private readonly lastEditedComment$: Observable<IActivityComment | undefined> =
    this.commentFeedStore.lastEditedComment$;
  private readonly lastPersistedComment$: Observable<IActivityComment> = this.commentFeedStore.lastPersistedComment$;
  private readonly lastSavedInMemoryComment$: Observable<IInMemoryComment> =
    this.commentFeedStore.lastSavedInMemoryComment$;

  private confirmDeleteModalRef: NgbModalRef | undefined;
  private subscriptions: Subscription[] = [];

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly commentFeedStore: CommentFeedStore,
    private readonly ngbModal: NgbModal,
    private readonly toast: ToastHelperService,
    private readonly translate: TranslateService,
    private readonly fileHelperService: FileHelperService,
    public readonly helperService: HelperService,
  ) {}

  public ngOnInit(): void {
    this.loadData();

    this.subscriptions.push(
      this.commentToDeleteIndex$.subscribe(() => {
        if (!this.isImageFileDeleted) {
          this.confirmDeleteModalRef = this.ngbModal.open(this.confirmDeleteModal, {
            windowClass: ScwModalSize.small,
          });
          this.confirmDeleteModalRef.result
            .then(() => {
              this.isCommentDeleted = true;
              this.commentFeedStore.deleteComment();
            })
            .catch(() => this.commentFeedStore.unsetCommentToDeleteIndex());
        } else {
          this.fileDeleteConfirmationModalRef.result.then(() => {
            this.isCommentDeleted = true;
            this.commentFeedStore.deleteComment();
            this.commentFeedStore.unsetCommentToDeleteIndex();
            this.commentFeedStore.unsetCommentToEdit();
            this.isImageFileDeleted = false;
            this.commentObjectToEdit = null;
          });
        }
      }),
      this.commentToEdit$
        .pipe(filter((comment: IActivityComment | null): comment is IActivityComment => comment !== null))
        .subscribe((comment: IActivityComment) => {
          this.isCommentDeleted = false;
          this.editFormComment = comment.commentMessage;
          this.editFormFolder = comment.folderId;
          this.editFormTags = comment.commentTags ? [...comment.commentTags] : [];
          this.editFormFiles = comment.files?.filter((item: IFile) => !_.isNil(item.folderId));
          this.commentObjectToEdit = comment;
        }),
      CameraModalComponent.filesSubject.subscribe((item: IFile[]) => {
        let isFileNamesValid: boolean = false;

        if (!_.isNil(this.commentObjectToEdit)) {
          if (this.editFormFiles === undefined) {
            this.editFormFiles = [];
          }

          isFileNamesValid = this.fileHelperService.isSameFileExist(
            this.editFormFiles.filter((file: IFile) => file.type === EFileType.PDF),
            item,
          );

          if (isFileNamesValid) {
            this.editFormFiles.push(...item);
          }

          return;
        }

        isFileNamesValid = this.fileHelperService.isSameFileExist(
          this.files.filter((file: IFile) => file.type === EFileType.PDF),
          item,
        );

        if (isFileNamesValid) {
          this.files = _.concat(this.files, item);
        }
      }),
      this.comments$.subscribe((comments: IActivityComment[]) => {
        if (comments.length) {
          comments.forEach((comment: IActivityComment) => {
            if (comment.folderId && !comment.files?.length) {
              this.fileRequestParams.push({
                folderId: comment.folderId,
                isThumbnail: 1,
                entityName: EFileUploadSource.COMMENTS,
                siteId: this.siteId$,
              });
            }
          });
        }

        if (this.fileRequestParams.length) {
          this.commentFeedStore.getManyImages(this.fileRequestParams);
        }

        this.fileRequestParams = [];
      }),

      this.commentManyFileUpload$.subscribe((response: IUploadFileResponse[]) => {
        if (response.length) {
          this.decideCreateOrUpdateCommentOperationForMultipleFiles(response);
        }

        this.files = [];
      }),
      this.lastDeletedComment$.subscribe((response: IActivityComment) => {
        if (response.files?.length) {
          const deleteManyFileParams: IDeleteFileParams[] = response.files.map((item: IFile) => {
            return {
              siteId: this.siteId$,
              entityName: EFileUploadSource.COMMENTS,
              fileName: item.filePath,
            };
          });

          this.commentFeedStore.deleteManyFile(deleteManyFileParams);
        }
      }),
      this.file$.subscribe((file: IGetFileResponse) => {
        if (file) {
          const blobURL: string = FileHelperService.byteStringToBlobUrl(file.base64Data, 'application/pdf');
          window.open(blobURL, '_blank');
        }
      }),
      this.lastEditedComment$.subscribe((response: IActivityComment) => {
        this.editFormTags = response.commentTags;
        this.editFormComment = response.commentMessage;
      }),
      this.fileDeleteStatus$.subscribe((response: IDeleteFileResponse) => {
        if (
          response &&
          (this.deletedFile || this.editFormFiles.length > 0 || this.editFormComment) &&
          (!this.isCommentDeleted || this.isImageFileDeleted)
        ) {
          _.remove(this.editFormFiles, (file) => file.filePath === response['data'].filePath);

          if (this.editFormFiles?.length) {
            this.commentFeedStore.deleteFile({ filePath: response['data'].filePath });
          }

          if (this.editFormComment !== '' && this.editFormFiles?.length === 0) {
            this.commentFeedStore.editComment({
              index: this.editModalIndex,
              message: this.sanitizeCommentMessage(this.editFormComment),
              tags: this.editFormTags?.map((tag: ICommentTag) => tag.id) ?? null,
              folderId: null,
            });
          }

          if (this.editFormComment === '' && this.editFormFiles.length === 0) {
            this.commentFeedStore.setCommentToDeleteIndex(this.editModalIndex);
          }

          this.deletedFile = null;
        }
      }),
      merge(
        this.lastBulkInsertResponse$,
        this.lastEditedComment$,
        this.lastDeletedComment$,
        this.lastPersistedComment$,
      ).subscribe(() => {
        this.toast.showGenericChangesSavedSuccessfullyToastMessage();
        this.editModalIndex = null;
      }),
      merge(this.lastBulkInsertResponse$, this.lastPersistedComment$, this.lastSavedInMemoryComment$).subscribe(() => {
        this.resetAddForm();

        if (this.mode === 'requiredComment' || (this.objectIds && this.objectIds.length > 1)) {
          this.activeModal.close();
        }
      }),
      this.userDateTimezone$.subscribe((timezone: string) => {
        this.modalOpenedAt = moment().tz(timezone).format(mysqlTimestampFormat);
        this.commentDateAsISO = this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(this.modalOpenedAt);
      }),
      this.userSiteId$.subscribe((siteId: number) => {
        this.siteId$ = siteId;
      }),
      this.tags$.subscribe((tags: readonly ICommentTag[]) => {
        this.addFormTags =
          this.defaultTagTypes
            ?.map((type: TagType) => tags.find((tag: ICommentTag) => tag.tagType === type))
            .filter((tag: ICommentTag | undefined): tag is ICommentTag => tag !== undefined) ?? null;
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  public closeModal(): void {
    combineLatest([this.inMemoryMode$, this.rawInMemoryComments$])
      .pipe(take(1))
      .subscribe(([inMemoryMode, comments]: [boolean, IInMemoryComment[]]) => {
        if (inMemoryMode) {
          this.inMemoryCommentsChange.emit(comments);
        }
      });

    this.commentFeedStore.userHasTakenAction$.pipe(take(1)).subscribe((userHasTakenAction: boolean) => {
      this.activeModal.dismiss(userHasTakenAction);
    });
  }

  public submitCreationForm(valid: boolean): void {
    this.deletedFileCommentIndex = null;

    if (!valid || !this.objectIds || !this.objectType) {
      return;
    }

    if (this.files.length) {
      this.commentFeedStore.uploadManyImage({
        entityName: EFileUploadSource.COMMENTS,
        siteId: this.siteId$,
        files: this.files,
      });
      return;
    }

    this.commentFeedStore.createComment({
      commentMessage: this.sanitizeCommentMessage(this.addFormComment),
      objectIds: this.objectIds,
      objectType: this.objectType,
      commentTags: this.addFormTags,
      timestamp: this.commentDateAsISO,
    });
  }

  public submitEditForm(valid: boolean, index: number): void {
    if (!valid) {
      return;
    }

    const newlyAddedFiles: IFile[] = this.editFormFiles?.filter((item: IFile) => _.isNil(item.folderId));

    if (newlyAddedFiles?.length) {
      this.uploadImageToEditedComment(newlyAddedFiles);
    } else {
      this.commentFeedStore.editComment({
        index,
        message: this.sanitizeCommentMessage(this.editFormComment),
        tags: this.editFormTags?.map((tag: ICommentTag) => tag.id) ?? null,
        folderId: this.editFormFolder ?? null,
      });
    }

    this.editModalIndex = index;
    this.deletedFileCommentIndex = index;
    this.commentObjectToEdit = null;
  }

  private getNonSuccessStatusText(status: ELoadStatus): string | undefined {
    switch (status) {
      case ELoadStatus.Loading:
        return this.translate.instant('activityCommentModal.label.loading');
      case ELoadStatus.Failure:
        return this.translate.instant('activityCommentModal.label.failed');
      case ELoadStatus.Initial:
        return this.translate.instant('activityCommentModal.label.pending');
    }
    return undefined;
  }

  private loadData(): void {
    if (!this.objectIds || !this.objectType) {
      return;
    }

    this.commentFeedStore.loadTags();
    this.commentFeedStore.loadCommentTypes();

    if (GenericHelperService.allElementsDefined(this.objectIds)) {
      this.commentFeedStore.loadComments({ objectIds: this.objectIds, objectType: this.objectType });
    } else {
      this.commentFeedStore.setInMemoryComments(this.inMemoryComments);
    }
  }

  private resetAddForm(): void {
    this.addFormTags = null;
    this.addFormComment = '';
  }

  private sanitizeCommentMessage(comment: string): string {
    return comment?.trim();
  }

  public openPreviewModal(index?: number, isEdit: boolean = false, fileNumber?: number, files?: IFile[]): void {
    if (!_.isNil(index)) {
      this.editModalIndex = index;
    }

    this.imagePreviewModalComponent.openImagePreviewModal(
      isEdit ? this.editFormFolder : undefined,
      isEdit ? files ?? this.editFormFiles : this.files,
      fileNumber,
    );
  }

  public showDeleteItemModal(content: TemplateRef<any>, file?: IFile, commentIndex?: number): void {
    if (!_.isNil(commentIndex)) {
      this.editModalIndex = commentIndex;
    }

    if (this.commentObjectToEdit) {
      this.deletedFile = this.editFormFiles.find((item: IFile): boolean => item === file);
    } else {
      this.deletedFile = this.files.find((item: IFile): boolean => item === file);
    }

    this.fileDeleteConfirmationModalRef = this.ngbModal.open(content, {
      windowClass: ScwModalSize.small,
    });
  }

  public deleteImage(): void {
    if (this.commentObjectToEdit) {
      if (!_.isNil(this.deletedFile) && this.deletedFile.folderId) {
        this.imagePreviewModalComponent.onDelete(this.deletedFile);
      } else {
        _.remove(this.editFormFiles, this.deletedFile);
      }
    }

    _.remove(this.files, this.deletedFile);
    this.fileDeleteConfirmationModalRef?.close();
    this.selectedImage = null;
    this.isImageFileDeleted = true;
  }

  public deleteButtonClicked(deletedFile: IFile): void {
    this.deletedFile = deletedFile;
    this.isImageFileDeleted = true;

    _.remove(this.files, (file: IFile): boolean =>
      file.original ? file.original === deletedFile.original : file.thumbnail === deletedFile.thumbnail,
    );

    if ((deletedFile.type = EFileType.PDF)) {
      _.remove(this.editFormFiles, (file: IFile): boolean => file.filePath === deletedFile.filePath);
      return;
    }

    _.remove(this.editFormFiles, (file: IFile): boolean =>
      file.original ? file.original === deletedFile.original : file.thumbnail === deletedFile.thumbnail,
    );
  }

  private uploadImageToEditedComment(newlyAddedFiles: IFile[]): void {
    this.commentFeedStore.uploadManyImage({
      entityName: EFileUploadSource.COMMENTS,
      files: newlyAddedFiles,
      siteId: this.siteId$,
      folderId: this.editFormFolder ?? undefined,
    });
  }

  private decideCreateOrUpdateCommentOperationForMultipleFiles(response: IUploadFileResponse[]): void {
    if (_.isNil(this.editModalIndex) && _.isNil(this.deletedFileCommentIndex)) {
      this.commentFeedStore.createComment({
        commentMessage: this.sanitizeCommentMessage(this.addFormComment),
        objectIds: this.objectIds,
        objectType: this.objectType,
        commentTags: this.addFormTags,
        timestamp: this.commentDateAsISO,
        folderId: response[0].folderId,
      });
    } else if (!_.isNil(this.deletedFileCommentIndex)) {
      this.commentFeedStore.editComment({
        index: this.deletedFileCommentIndex,
        message: this.sanitizeCommentMessage(this.editFormComment),
        tags: this.editFormTags?.map((tag: ICommentTag) => tag.id) ?? null,
        folderId: response[0].folderId,
      });
      this.deletedFileCommentIndex = null;
    } else {
      this.commentFeedStore.editComment({
        index: this.editModalIndex,
        message: this.sanitizeCommentMessage(this.editFormComment),
        tags: this.editFormTags?.map((tag: ICommentTag) => tag.id) ?? null,
        folderId: response[0].folderId,
      });
      this.editModalIndex = null;
    }
  }

  public clickCancelButtonOnEditComment(): void {
    const editedCommentFiles: IFile[] = this.commentObjectToEdit?.files;

    this.editModalIndex = null;
    this.deletedFileCommentIndex = null;
    this.commentObjectToEdit = null;
    this.editFormFiles = this.editFormFiles?.filter((item: IFile) => !_.isNil(item.folderId));
    this.commentFeedStore.unsetCommentToEdit();

    if (editedCommentFiles?.length !== this.editFormFiles?.length) {
      this.loadData();
    }
  }

  public downloadPdf(file: IFile): void {
    if (file.id) {
      this.commentFeedStore.getFile({
        isThumbnail: 0,
        entityName: EFileUploadSource.COMMENTS,
        siteId: this.siteId$,
        fileId: file.id,
      });
      return;
    }

    const base64Part: string = file.original.split(',')[1];
    const blobURL: string = FileHelperService.byteStringToBlobUrl(base64Part, 'application/pdf');
    window.open(blobURL, '_blank');
  }
}

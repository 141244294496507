import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  GroupedByTypes,
  CheckInLogCardDataInterface,
  CheckInLogCardResponseInterface,
  CheckInLogInterface,
  CheckInLogTableResponseInterface,
  CheckInTableDataCommonInterface,
  CheckInTableGroupedByNoneInterface,
  CheckInTableGroupedByLineInterface,
} from './check-in-log.model';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  CheckInLogFormRequestPayloadInterface,
  CheckInLogFormTypes,
  CheckInLogReportTypes,
  CheckInTableQueryParams,
} from '../../../view/reports/check-in-logs/check-in-logs.model';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
  ExcelDateFormatInformationInterface,
} from '../../../shared/service/excel/excel-helper.service';
import * as ObjectActions from '../../../store/reports/check-in-log/check-in-log.actions';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ValueType } from 'exceljs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { minutesToHm } from '../../../shared/helper/date';
import { UserService } from '../../../shared/service/user/user.service';
import { MomentDatePipe } from 'src/app/standalone/pipes/moment-date.pipe';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { camelCase } from '@swimlane/ngx-datatable';
import { IFilterOutput, TargetEndpoints } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable({
  providedIn: 'root',
})
export class CheckInLogService {
  private timezone: string = 'utc';
  private dateFormatInformation: ExcelDateFormatInformationInterface;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
    private readonly userService: UserService,
    private readonly momentDatePipe: MomentDatePipe,
    private readonly entityTranslatorService: EntityTranslatorService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly helperService: HelperService,
  ) {
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;
    let locale$: string;
    let dateFormatRaw$: string;
    let dateTimeFormatRaw$: string;

    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;

          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }

          dateFormatRaw$ = state.dateFormat;
          dateTimeFormatRaw$ = state.dateTimeFormat;
          locale$ = state.locale;

          this.dateFormatInformation = {
            timezone,
            dateFormat$,
            timeFormat$,
            locale$,
            dateFormatRaw$,
            dateTimeFormatRaw$,
          };
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly api: string = this.baseUrl;
  private readonly lines: string = `${this.api}/lines`;
  private readonly userCheckIn: string = `${this.api}/check-ins`;
  private readonly userCheckInBulkDelete: string = `${this.api}/check-ins/bulk/delete`;
  private readonly CHECK_IN_LOG = {
    GET: {
      CARDS_DATA: `${this.userCheckIn}/card-data`,
      USER_CHECK_INS_GROUPED_BY: {
        none: `${this.userCheckIn}/object-check-ins`,
        object: `${this.userCheckIn}/grouped-by-object`,
        line: `${this.userCheckIn}/grouped-by-line`,
        calendarDate: `${this.userCheckIn}/grouped-by-date`,
        businessDate: `${this.userCheckIn}/grouped-by-date`,
        station: `${this.userCheckIn}/grouped-by-station`,
      },
    },
  };
  private readonly dataProperty: string = 'data';

  getCheckInCardData(data: CheckInTableQueryParams): Promise<CheckInLogCardDataInterface> {
    return new Promise((resolve, reject) => {
      const options = this.formatCheckInParams(data, false);
      this.http
        .get(this.CHECK_IN_LOG.GET.CARDS_DATA, { params: options })
        .subscribe((response: CheckInLogCardResponseInterface) => {
          if (response.hasOwnProperty(this.dataProperty)) {
            resolve(response.data);
            return response.data;
          }
          reject();
          return null;
        });
    });
  }

  public formatCheckInParams(params: CheckInTableQueryParams, isLimitOffset: boolean): HttpParams {
    let httpParams = new HttpParams();

    if (isLimitOffset) {
      httpParams = httpParams.append('offset', String(params.offset)).append('limit', String(params.pageSize));
    }

    httpParams = httpParams
      .set('siteIds', params.siteIds)
      .set('objectIds', params.objectIds)
      .set('shiftIds', params.shiftIds)
      .set('sourceObjectIds', params.sourceObjectIds)
      .set('destinationTypeId', params.destinationTypeId)
      .set('sourceTypeId', params.sourceTypeId)
      .set('startDate', params.startDate)
      .set('endDate', params.endDate)
      .set('groupByOption', params.groupByOption);

    if (params.status !== undefined) {
      httpParams = httpParams.set('status', params.status);
    }

    if (params.orderDesc !== undefined && isLimitOffset) {
      const direction = params.orderDesc ? 'DESC' : 'ASC';
      httpParams = httpParams.set('sort', `${params.orderBy},${direction}`);
    }
    const searchParamsAdvancedFilter: { [key: string]: any } = { $and: [] };

    if (params.advancedFilter && params.advancedFilter.filters.length) {
      const advancedFilter: IFilterOutput[] = params.advancedFilter.filters;

      for (const filter of advancedFilter) {
        searchParamsAdvancedFilter['$and'].push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            TargetEndpoints.Custom,
            filter.value,
          ),
        );
      }

      httpParams = httpParams.set('advancedFilterParams', JSON.stringify(searchParamsAdvancedFilter));
    }

    return httpParams;
  }

  getCheckInTableData(
    data: CheckInTableQueryParams,
    groupedBy: GroupedByTypes,
  ): Promise<CheckInLogTableResponseInterface> {
    const options = this.formatCheckInParams(data, true);
    const url = this.CHECK_IN_LOG.GET.USER_CHECK_INS_GROUPED_BY.hasOwnProperty(groupedBy)
      ? this.CHECK_IN_LOG.GET.USER_CHECK_INS_GROUPED_BY[groupedBy]
      : this.CHECK_IN_LOG.GET.USER_CHECK_INS_GROUPED_BY.none;

    return new Promise((resolve, reject) => {
      this.http.get(url, { params: options }).subscribe((response: CheckInLogTableResponseInterface) => {
        if (response.hasOwnProperty(this.dataProperty)) {
          resolve(response);
          return response;
        }
        reject();
        return null;
      });
    });
  }

  public loadLines(): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    const baseHttpParams = new HttpParams().append('limit', '1000');
    const options = {
      params: baseHttpParams,
    };

    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(this.lines, options);
  }

  public submitCheckInLog(
    formType: CheckInLogFormTypes,
    checkInLogForm: CheckInLogFormRequestPayloadInterface,
  ): Observable<BaseOneResponseInterface<CheckInLogInterface>> {
    const { id, ...requestPayload } = checkInLogForm;
    const requestType: 'post' | 'patch' = formType === CheckInLogFormTypes.Edit ? 'patch' : 'post';
    const requestURL: string = formType === CheckInLogFormTypes.Edit ? `${this.userCheckIn}/${id}` : this.userCheckIn;

    return this.http[requestType]<BaseOneResponseInterface<CheckInLogInterface>>(requestURL, requestPayload);
  }

  public deleteCheckInLog(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.userCheckIn}/${id}`);
  }

  public bulkDeleteCheckInLog(checkIns: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        checkIns,
      },
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.userCheckInBulkDelete}`, httpOptions);
  }

  public downloadExcel(queryParams: CheckInTableQueryParams, groupedBy: GroupedByTypes): void {
    const observables: Observable<CheckInLogTableResponseInterface>[] = [];

    if (queryParams.sourceTypeId === 1) {
      observables.push(
        this.translate.get('excel.laborLogs.excelName', {
          date: moment().tz(this.timezone).format(this.dateFormatInformation.dateFormat$),
        }),
      );
    } else {
      observables.push(
        this.translate.get('excel.assetLogs.excelName', {
          date: moment().tz(this.timezone).format(this.dateFormatInformation.dateFormat$),
        }),
      );
    }

    observables.push(from(this.getCheckInTableData(queryParams, groupedBy)));

    forkJoin(observables).subscribe((responseList) => {
      if (Array.isArray(responseList) && responseList.length >= 2) {
        responseList[1].data.forEach(
          (
            checkInData:
              | CheckInTableDataCommonInterface
              | CheckInTableGroupedByNoneInterface
              | CheckInTableGroupedByLineInterface,
          ) => this.entityTranslatorService.translate(checkInData),
        );
      }

      const checkInLogsTitle =
        queryParams.sourceTypeId === 1
          ? this.translate.instant('excel.laborLogs.excelSheetName')
          : this.translate.instant('excel.assetLogs.excelSheetName');
      let excelName: string = _.get(responseList, '0', checkInLogsTitle);
      const excelData:
        | CheckInTableDataCommonInterface[]
        | CheckInTableGroupedByNoneInterface[]
        | CheckInTableGroupedByLineInterface[] = _.get(responseList, '1.data', []);
      const excelOptions: CreateExcelInterface = this.getCheckInItemsExcelColumns(queryParams.sourceTypeId, groupedBy);

      excelOptions.data = this.formatExcelValues(excelData, queryParams.sourceTypeId, groupedBy);

      if (excelName === undefined) {
        excelName = checkInLogsTitle;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle: checkInLogsTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          withData: true,
          excelRowFormatLimit: 5001,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          { name: queryParams.sourceTypeId === 1 ? 'laborLogs' : 'assetLogs', withData: true },
          worksheets,
          this.timezone,
          this.dateFormatInformation.dateFormat$,
          this.dateFormatInformation.timeFormat$,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchDataError({}));
          },
        );
    });
  }

  private getCheckInItemsExcelColumns(
    sourceObjectType: CheckInLogReportTypes,
    groupedBy: GroupedByTypes,
  ): CreateExcelInterface {
    const siteHeaderTranslation: string = this.translate.instant('general.siteName');
    const lineHeaderTranslation: string = this.translate.instant('reports.checkInLogs.tableColumn.lineName');
    const laborTrackerTranslation: string = this.translate.instant('cico.modules.laborTracker.name');
    const assetTrackerTranslation: string = this.translate.instant('cico.modules.assetTracker.name');
    const durationTranslation: string =
      sourceObjectType === CheckInLogReportTypes.LaborLogs
        ? this.translate.instant('reports.checkInLogs.tableColumn.laborHours')
        : this.translate.instant('reports.checkInLogs.tableColumn.duration');
    const startDateTranslation: string =
      groupedBy !== GroupedByTypes.CALENDAR_DATE
        ? this.translate.instant('reports.checkInLogs.tableColumn.checkIn')
        : this.translate.instant('reports.checkInLogs.tableColumn.startDate');
    const endDateTranslation: string =
      groupedBy !== GroupedByTypes.CALENDAR_DATE
        ? this.translate.instant('reports.checkInLogs.tableColumn.checkOut')
        : this.translate.instant('reports.checkInLogs.tableColumn.endDate');
    let checkInLogsColumns: CreateExcelInterface;
    switch (groupedBy) {
      case 'none':
        checkInLogsColumns = {
          columns: [
            {
              header: siteHeaderTranslation,
              key: 'siteName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
              key: 'station',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            ...(sourceObjectType === 1
              ? [
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.role'),
                    key: 'role',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.details'),
                    key: 'details',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                ]
              : []),
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.status'),
              key: 'status',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: startDateTranslation,
              key: 'startDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: endDateTranslation,
              key: 'endDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedStartDate'),
              key: 'calculatedStartDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedEndDate'),
              key: 'calculatedEndDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      case 'object':
        checkInLogsColumns = {
          columns: [
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      case 'line':
        checkInLogsColumns = {
          columns: [
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === 1
                  ? this.translate.instant('reports.checkInLogs.tableColumn.userCount')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assetCount'),
              key: 'objectCount',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === 1
                  ? this.translate.instant('reports.checkInLogs.tableColumn.users')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assets'),
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      case 'calendarDate':
        checkInLogsColumns = {
          columns: [
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: siteHeaderTranslation,
              key: 'siteName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
              key: 'station',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: startDateTranslation,
              key: 'calendarStartDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: endDateTranslation,
              key: 'calendarEndDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            ...(sourceObjectType === 1
              ? [
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.role'),
                    key: 'role',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.details'),
                    key: 'details',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.status'),
                    key: 'status',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                ]
              : []),
          ],
        };
        break;
      case 'businessDate':
        checkInLogsColumns = {
          columns: [
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: siteHeaderTranslation,
              key: 'siteName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
              key: 'station',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedStartDate'),
              key: 'calculatedStartDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedEndDate'),
              key: 'calculatedEndDate',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            ...(sourceObjectType === 1
              ? [
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.role'),
                    key: 'role',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.details'),
                    key: 'details',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                  {
                    header: this.translate.instant('reports.checkInLogs.tableColumn.status'),
                    key: 'status',
                    width: ExcelColumnWidthEnum.DEFAULT,
                    type: ValueType.String,
                    style: { numFmt: '@' },
                    dataValidation: {
                      type: CellTypes.CUSTOM,
                      allowBlank: false,
                      formulae: [],
                      showErrorMessage: true,
                      showInputMessage: true,
                    },
                  },
                ]
              : []),
          ],
        };
        break;
      case 'station':
        checkInLogsColumns = {
          columns: [
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
              key: 'station',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === CheckInLogReportTypes.LaborLogs
                  ? this.translate.instant('reports.checkInLogs.tableColumn.userCount')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assetCount'),
              key: 'objectCount',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === CheckInLogReportTypes.LaborLogs
                  ? this.translate.instant('reports.checkInLogs.tableColumn.users')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assets'),
              key: 'sourceObjectViewName',
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      default:
        break;
    }
    return checkInLogsColumns;
  }

  public formatExcelValues(
    excelData:
      | CheckInTableDataCommonInterface[]
      | CheckInTableGroupedByNoneInterface[]
      | CheckInTableGroupedByLineInterface[],
    sourceTypeId: number,
    groupedBy: GroupedByTypes,
  ): CheckInTableDataCommonInterface[] | CheckInTableGroupedByNoneInterface[] | CheckInTableGroupedByLineInterface[] {
    const formattedExcelValues = _.cloneDeep(excelData);
    const available = this.translate.instant('general.available');
    const onGoing = this.translate.instant('general.ongoing');
    let busy;
    if (sourceTypeId === 1) {
      busy = this.translate.instant('general.busy');
    } else {
      busy = this.translate.instant('general.unavailable');
    }

    formattedExcelValues.forEach((data) => {
      data.status = data.status === 0 ? busy : available;
      data.startDate = this.momentDatePipe.transform(
        this.helperService.convertFromISOFormatToGivenTimezone(data.startDate),
        this.dateFormatInformation.dateTimeFormatRaw$,
      );
      data.endDate = data.endDate
        ? this.momentDatePipe.transform(
            this.helperService.convertFromISOFormatToGivenTimezone(data.endDate),
            this.dateFormatInformation.dateTimeFormatRaw$,
          )
        : onGoing;
      data.calendarStartDate = this.momentDatePipe.transform(
        data.calendarStartDate,
        this.dateFormatInformation.dateFormatRaw$,
      );
      data.calendarEndDate = data.calendarEndDate
        ? this.momentDatePipe.transform(data.calendarEndDate, this.dateFormatInformation.dateFormatRaw$)
        : onGoing;
      data.duration = minutesToHm(_.toInteger(data.duration));
      data.calculatedStartDate = this.momentDatePipe.transform(
        this.helperService.convertFromISOFormatToGivenTimezone(data.calculatedStartDate),
        this.dateFormatInformation.dateTimeFormatRaw$,
      );
      data.calculatedEndDate = this.momentDatePipe.transform(
        this.helperService.convertFromISOFormatToGivenTimezone(data.calculatedEndDate),
        this.dateFormatInformation.dateTimeFormatRaw$,
      );

      if (data.sourceTypeId === CheckInLogReportTypes.LaborLogs) {
        data.role = data.customRole
          ? data.role
          : this.translate.instant(`general.lookups.level.${camelCase(data.role)}`);
      }

      if (groupedBy === GroupedByTypes.STATION && data?.station === null) {
        data.line = this.translate.instant('general.many');
        data.station = this.translate.instant('general.none');
      }
    });
    return formattedExcelValues;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { activityStyles } from '../../../../shared/helper/home-helper';
import { dateStringToDateObject, getCurrentDateTime } from '../../../../shared/helper/date';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import * as _ from 'lodash';
import { CustomColors } from '../../../../shared/service/color/color.model';
import { ColorService } from '../../../../shared/service/color/color.service';
import { HomeStateInterface } from '../../../../store/home/home.model';
import { IHomeMetricSetItem } from '../../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import {
  EHomeMetrics,
  EShiftSplit,
} from '../../../settings/home-page-display-settings/home-page-display-settings.constants';

@Component({
  selector: 'activity-duration-metric',
  templateUrl: './activity-duration-metric.component.html',
})
export class ActivityDurationMetricComponent implements OnInit, OnDestroy {
  public activityDurationMetric = {
    activityStart$: null,
    activityTarget$: null,
  };
  public activityDurationTextClass: string;
  public activityDurationTextStyle: string;
  public activityTimer: string = '';
  public activityDurationText: string = '';
  public activityTimerInterval: Observable<number>;
  private userStoreSubscription: Subscription;
  private lineStoreSubscription: Subscription;
  private userTimezone: string | null;
  private alive: boolean = true;
  public activityDurationRateCapped: number = null;
  public activityDurationRate: string = null;
  public showProgressBar: boolean = true;
  public showExpectedValue: boolean = true;
  public expectedValue: string = null;
  private siteDecimalScaleLimit$: number;
  public customColors$: CustomColors;
  private storeSubscriptions: Subscription[] = [];
  public unit: string = '';
  public shiftSplit: boolean = false;

  constructor(
    public helperService: HelperService,
    private translate: TranslateService,
    private store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private colorService: ColorService,
  ) {
    this.activityDurationTextClass = null;
  }

  activityDurationPercentage(isLimited): number {
    if (this.activityDurationMetric.activityTarget$ === null) {
      return 0;
    }

    const totalHourAndMinute: string = this.activityDurationMetric.activityTarget$;
    const oneHour: number = 3600000;

    const startTime: Date = dateStringToDateObject(this.activityDurationMetric.activityStart$);
    const endTime: Date = new Date(
      Number(
        this.decimalHelper.add(
          startTime.getTime().toString(),
          this.decimalHelper.multiply(totalHourAndMinute, oneHour.toString()),
        ),
      ),
    );

    const now: Date = getCurrentDateTime(this.userTimezone);
    const endInMS: number = endTime.getTime() - startTime.getTime();
    const elapsedInMS: number = now.getTime() - startTime.getTime();
    const percentage: number = (elapsedInMS / endInMS) * 100;

    if (endInMS === 0) {
      return 0;
    }

    if (isLimited && percentage > 100) {
      return 100;
    }

    if (isLimited && percentage < 0) {
      return 0;
    }

    return percentage;
  }

  public ngOnInit(): void {
    this.activityDurationText = this.translate.instant('activityCards.activityDurationLabel');
    this.userStoreSubscription = this.store.select('user').subscribe((state) => {
      this.userTimezone = state.timezone;
      this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
    });
    this.storeSubscriptions.push(
      combineLatest([
        this.store.select('user').pipe(filter((value) => value.isUserLoaded === true)),
        this.store.select('mainStore').pipe(filter((value) => value.getActiveSitesLoaded)),
        this.store
          .select('line')
          .pipe(filter((value) => value.activityType !== undefined && value.activityType !== null)),
        this.store.select('lineViewStore').pipe(filter((value) => value.selectedSiteId !== undefined)),
      ]).subscribe(([user, main, line, lineView]) => {
        const site: number = lineView.selectedSiteId ?? user.siteId;
        const sitesColors: Record<number, CustomColors | undefined> = _.fromPairs(
          main.activeSites.map((site) => [site.id, site.configuration?.colors]),
        );
        const clientColors: CustomColors | undefined = user.clientInfo?.configuration?.colors;
        this.customColors$ = this.colorService.pickCustomColors([site], sitesColors, clientColors);
        this.activityDurationTextStyle =
          line.activityType === 'downTimePlanned' ? this.customColors$.activity.plannedDownTime.card : null;
      }),
      this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
        if (state.homeMetricSetItemsLoaded) {
          const activityDurationMetric: IHomeMetricSetItem | undefined = state.homeMetricSetItems.find(
            (homeMetric: IHomeMetricSetItem) => homeMetric.metric === EHomeMetrics.ACTIVITY_DURATION,
          );
          const metaShiftSplit: string | undefined =
            activityDurationMetric?.homeMetricSetProperties?.meta['shiftSplit'];
          this.shiftSplit = !!metaShiftSplit ? metaShiftSplit === EShiftSplit.YES : true;
        }
      }),
    );

    this.lineStoreSubscription = this.store.select('line').subscribe((state) => {
      let styles = activityStyles(state.activityType, state.activitySubType);
      if (!styles) {
        styles = activityStyles('idleTime');
      }

      this.activityDurationTextClass = styles.activityDurationTextClass;
      let activityStart: string = !!state.activityHistoryActivityStart
        ? state.activityHistoryActivityStart
        : state.activityStart;
      activityStart = this.shiftSplit ? state.activityStart : activityStart;
      this.activityDurationMetric.activityStart$ =
        this.helperService.convertFromISOFormatToGivenTimezone(activityStart);
      this.activityDurationMetric.activityTarget$ = state.activityTarget;
      this.expectedValue = this.decimalHelper.toFixedValue(
        HelperService.cloneDeep(state.activityTarget),
        this.siteDecimalScaleLimit$,
      );
      this.showProgressBar = state.activityType !== 'runTime';
      this.showExpectedValue = state.activityType !== 'runTime';
      this.unit = state.activityType !== 'runTime' ? this.translate.instant('general.shortHour') : '';
      this.activityDurationRateCapped = Math.round(this.activityDurationPercentage(true));
      this.activityDurationRate = this.decimalHelper.toFixedValue(this.activityDurationPercentage(false).toString(), 2);
    });

    this.activityTimerInterval = interval(1000);
    this.activityTimerInterval.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.activityTimer = this.helperService.activityTimerFunction(this.activityDurationMetric.activityStart$);
    });
  }

  public ngOnDestroy(): void {
    if (this.lineStoreSubscription) {
      this.lineStoreSubscription.unsubscribe();
    }
    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }
    this.alive = false;
    for (const subscription of this.storeSubscriptions) {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }
}

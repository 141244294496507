// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { defaultEnvironmentConfigs } from './environment.defaults';

export const environment = {
  ...defaultEnvironmentConfigs,
  ssoEnv: 'DEV',
  ssoUrl: 'https://df-sso.scm3d.com',
  appInsights: {
    instrumentationKey: 'b333e547-acc9-4ca1-ad70-610a395e32eb',
    cloudRole: 'df-angular-dev',
  },
  fileUploadUrl: 'https://dev-file-manager.scm3d.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IFeedbackModalData, IRatingFeedback, ISubmitFeedback } from './feedback-modal.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackModalService {
  private routes = {
    feedbacks: '/feedback',
    submitFeedback: '/feedback/submit-feedback',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  public getFeedbacks(params?: HttpParams): Observable<GetManyResponseInterface<IFeedbackModalData>> {
    return this.http.get<GetManyResponseInterface<IFeedbackModalData>>(`${this.api}${this.routes.feedbacks}`, {
      params,
    });
  }

  public submitFeedback(
    payload: ISubmitFeedback<IRatingFeedback>,
  ): Observable<BaseOneResponseInterface<IFeedbackModalData>> {
    return this.http.post<BaseOneResponseInterface<IFeedbackModalData>>(
      `${this.api}${this.routes.submitFeedback}`,
      payload,
    );
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IActivityHistoryRequestParameter,
  IRequestBody,
  IRootCauseAnalysisElasticChartNode,
  IElasticActivityLog,
  IHitsInterface,
  ElasticActivityLogResponseInterface,
  ElasticStackChartResponseInterface,
  IBucketInterface,
  IBucketItemInterface,
  IComparisonChartDataInterface,
  IComparisonActivityInterface,
  IComparisonEquipmentInterface,
  IComparisonTaskInterface,
  IElasticRequestBody,
  IEntityTranslationKeyPropertyMap,
  IEntityTranslationKeyPropertyMapForSubProperties,
} from './root-cause-analysis-elastic.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import moment from 'moment';
import { HelperService } from '../../../shared/service/helper.service';
import { ElasticHelperService } from '../../../shared/service/elastic-helper.service';
import * as _ from 'lodash';
import {
  IActivityLog,
  IOptimizedActivityLogsBody,
  RootCauseAnalysisFilterInterface,
} from '../root-cause-analysis/root-cause-analysis.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { RootCauseAnalysisService } from '../root-cause-analysis/root-cause-analysis.service';
import { ICurrentShift } from '../../../shared/service/filter/service.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ShiftService } from '../../../shared/service/filter/shift.service';
import { SitesService } from '../../../shared/service/settings/sites/sites.service';
import { IEntityTranslation } from '../../../shared/service/entity-translator/entity-translator.model';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../shared/helper/date';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';

@Injectable({
  providedIn: 'root',
})
export class RootCauseAnalysisElasticService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    @Inject('PROXY_URL') private readonly proxy: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private readonly helperService: HelperService,
    private readonly elasticHelperService: ElasticHelperService,
    private readonly rootCauseAnalysisService: RootCauseAnalysisService,
    private readonly shiftService: ShiftService,
    private readonly sitesService: SitesService,
  ) {}

  private readonly routes = {
    activityHistory: `${this.proxy}/activity-histories`,
    activityHistoryAll: `${this.proxy}/activity-histories/search`,
    activityHistoryCount: `${this.proxy}/activity-histories/count`,
    rootCauseAnalysis: `${this.api}/activity-histories/root-cause-analysis`,
    lines: `${this.api}/lines/root-cause-analysis-elastic-lines`,
  };

  private entityTranslationKeyPropertyMap: IEntityTranslationKeyPropertyMap = {
    activity: 'activityName',
    task: 'taskName',
    equipmentList: 'equipmentName',
  };

  private entityTranslationKeyPropertyMapForSubProperties: IEntityTranslationKeyPropertyMapForSubProperties = {
    task: (index: number): string => `tasks[${index}].taskName`,
    equipmentList: (index: number): string => `equipments[${index}].equipmentName`,
  };

  public getRootCauseAnalysisElasticChartData(
    params: IActivityHistoryRequestParameter,
  ): Observable<ElasticStackChartResponseInterface> {
    const body: IElasticRequestBody = this.elasticHelperService.getElasticQueryBodyOfStackChart(params);
    return this.http.post<ElasticStackChartResponseInterface>(
      `${this.routes.activityHistoryAll}`,
      body,
      {
        headers: new HttpHeaders({'X-HTTP-Method': 'GET'}),
      },
    );
  }

  public loadActivityLogElasticData(
    params: IActivityHistoryRequestParameter,
  ): Observable<ElasticActivityLogResponseInterface> {
    const body: IElasticRequestBody = this.elasticHelperService.getElasticQueryBodyOfActivityHistory(params);
    let url: string;
    let orderBy: string = params?.orderBy

    if (orderBy) {
      if (orderBy === 'userFullName') {
        orderBy = 'userName'
      }

      url = `${this.routes.activityHistory}?size=${params.size}&from=${params.from - 1}&sort=${orderBy},${params.orderByString}`;
    } else {
      url = `${this.routes.activityHistory}?size=${params.size}&from=${params.from - 1}`;
    }
    return this.http.post<ElasticActivityLogResponseInterface>(
      url,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public loadElasticStackChartData(
    params: IActivityHistoryRequestParameter,
  ): Observable<ElasticStackChartResponseInterface> {
    const body: IElasticRequestBody = this.elasticHelperService.getElasticQueryBodyOfStackChart(params);

    return this.http.post<ElasticStackChartResponseInterface>(
      `${this.routes.activityHistoryAll}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public loadElasticComparisonData(
    params: IActivityHistoryRequestParameter,
  ): Observable<ElasticStackChartResponseInterface> {
    const body: IElasticRequestBody = this.elasticHelperService.getElasticComparisonQueryBodyOfStackChart(params);

    return this.http.post<ElasticStackChartResponseInterface>(
      `${this.routes.activityHistoryAll}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getElasticLineData(params: any): Observable<ResponseArrayInterface<IActivityLog>> {
    const body: IRequestBody = this.rootCauseAnalysisService.getQueryBodyOfLine(params, false);
    return this.http.post<ResponseArrayInterface<IActivityLog>>(`${this.routes.lines}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public formatActivityHistoryData(activityHistories: IHitsInterface): IElasticActivityLog[] {
    const formattedActivities: IElasticActivityLog[] = [];

    for (const activityHistoryData of activityHistories['hits']) {
      const activity = activityHistoryData._source;
      const duration: number = moment(activity.end).diff(moment(activity.start), 'seconds');
      const entityTranslations: IEntityTranslation[] = this.combineEntityTranslations(activity);

      const formattedActivity: IElasticActivityLog = {
        id: activity.id,
        siteId: activity.site.id,
        lineId: activity.line.id,
        lineTitle: activity.line.title,
        lineType: activity.lineType.lineType,
        lineTypeId: activity.lineType.id,
        start: activity.start,
        end: activity.end,
        duration: duration >= 0 ? duration : 0,
        activityId: activity.id,
        activityName: activity.activity.name,
        activityTypeText: activity.activity.type,
        taskId: activity.task.id,
        taskName: activity.task.title,
        workOrderNumber: activity.workOrderSchedule.woNumber,
        productId: String(activity.workOrderSchedule.product.id),
        productName: String(activity.workOrderSchedule.product.name),
        productDescription: String(activity.workOrderSchedule.product.description),
        hasProduct: Boolean(activity.workOrderSchedule.product.id),
        ucl: Number(activity.task.ucl),
        lcl: Number(activity.task.lcl),
        equipmentId: activity.equipmentList.id,
        equipmentName: activity.equipmentList.name,
        crewSize: activity.crewSize,
        description: activity.description,
        userId: activity.user?.id,
        userFullName: activity.user?.fullName,
        shiftDay: activity.shiftDay,
        shiftId: activity.shift.id ? activity.shift.id : null,
        shiftName: activity?.shift?.name,
        isLine: 0,
        siteName: activity.site.name,
        primaryKey: null,
        durationWithUserFormat: null,
        durationMin: null,
        location: null,
        phaseId: activity.phase.id,
        preRunPhaseName: activity.site.preRunPhaseName,
        postRunPhaseName: activity.site.postRunPhaseName,
        runPhaseName: activity.site.runPhaseName,
        productInfo: activity.workOrderSchedule.product.id
          ? `${activity.workOrderSchedule.product.name} - ${activity.workOrderSchedule.product.description}`
          : '',
        targetDuration: activity.workOrderSchedule.targetDuration,
        jobNumber: activity.workOrderSchedule.jobNumber,
        processOrder: activity.workOrderSchedule.processOrder,
        quantityOrdered: activity.workOrderSchedule?.quantityOrdered?.toString(),
        workOrderId: activity.workOrderSchedule?.id?.toString(),
        entityTranslations: entityTranslations,
      };

      formattedActivities.push(formattedActivity);
    }

    return formattedActivities;
  }

  public formatStackChartData(stackChartData: any, groupBy: string): IBucketInterface[] {
    const formattedBuckets: any[] = [];
    for (const data of stackChartData[groupBy].buckets) {
      const formattedBucket: any = {
        docCount: data.doc_count,
        activityType: data.activityType,
        key: groupBy === 'product' ? {
          product: data.key[groupBy],
          product_id: data.key.id,
          description: data.key.description
        } : data.key,
        durationType: this.getDurationTypes(data.activityType.buckets),
        ...(data['key_as_string'] && { keyAsString: data['key_as_string'] }),
      };

      formattedBuckets.push(formattedBucket);
    }

    return formattedBuckets;
  }

  private getDurationTypes(data: any): any {
    const durationTypes = [];
    data.forEach((item: IBucketItemInterface) => {
      durationTypes.push(item.key);
    });
    return durationTypes;
  }

  public formatElasticStackChartData(stackChartData: any, params: any): any[] {
    const formattedBuckets: any[] = [];
    for (const data of stackChartData[params.groupBy].buckets) {
      if (params.field) {
        const formattedBucket: IBucketInterface = {
          totalDuration: data.duration.value,
          filterBy: data[params.field],
          key: params.groupBy === 'product' ? {
            id: data.key.id,
            product: `${data.key.product} - ${data.key.description}`
          } : {
            [params.groupBy]: data.key[params.groupBy],
            id: Number(data.key.id)
          },
        };
        formattedBuckets.push(formattedBucket);
      } else {
        const formattedBucket: IBucketInterface = {
          filterBy: params.isBusinessDate ? data.shiftDay : data.start,
          key: params.groupBy === 'product' ? {
            product_id: data.key.id,
            product: `${data.key.product} - ${data.key.description}`
          } : {
            [params.groupBy]: data.key[params.groupBy],
            id: Number(data.key.id)
          },
        };
        formattedBuckets.push(formattedBucket);
      }
    }

    if(params.field){
      formattedBuckets.forEach((data:any)=>{
        let totalDuration: number = 0;
        data.filterBy.buckets.forEach((bucket:any)=>{
          totalDuration += bucket.duration.value;
        })
        if(data.totalDuration > totalDuration) {
          data.filterBy.buckets.push({
            doc_count: 1,
            duration: {
              value: data.totalDuration - totalDuration
            },
            key: 'N/A'
          })
        }
      })
    }

    return formattedBuckets;
  }

  public formatChartData(chartData: any, isActivity: boolean, isEquipment: boolean, isTask: boolean):
    IRootCauseAnalysisElasticChartNode[] {
    const formattedBuckets: IRootCauseAnalysisElasticChartNode[] = [];
    for (const data of chartData.buckets) {
      let formattedBucket: IRootCauseAnalysisElasticChartNode = {};
      let activityEntityTranslations: IEntityTranslation[] = [];
      let taskEntityTranslations: IEntityTranslation[] = [];
      let equipmentEntityTranslations: IEntityTranslation[] = [];

      if (isActivity) {
        data.activity.buckets.forEach((activity) => {
          activityEntityTranslations = [
            ...activityEntityTranslations,
            ...this.combineEntityTranslations(activity, true),
          ];
        });

        data?.task?.buckets.forEach((activityTask, index: number) => {
          activityEntityTranslations = [
            ...activityEntityTranslations,
            ...this.combineEntityTranslations(activityTask, true, true, index),
          ];
        });

        data?.equipment?.buckets
          .filter((item) => item.key !== 'N/A')
          .forEach((activityEquipment, index: number) => {
            activityEntityTranslations = [
              ...activityEntityTranslations,
              ...this.combineEntityTranslations(activityEquipment, true, true, index),
            ];
          });
      }

      if (isTask) {
        data.task.buckets.forEach((task) => {
          taskEntityTranslations = [...taskEntityTranslations, ...this.combineEntityTranslations(task, true)];
        });
      }

      if (isEquipment) {
        data.equipment.buckets.forEach((equipment, index: number) => {
          equipmentEntityTranslations = [
            ...equipmentEntityTranslations,
            ...this.combineEntityTranslations(equipment, true, false, index),
          ];
        });
      }

      for (const item of data.activityType.buckets) {
        formattedBucket = {
          ...formattedBucket,
          activityId: isActivity ? Number(data.key.id) : isTask ? data.activity.buckets[0].key : null,
          activityName: isActivity ? data.key.activity : isTask ? data.activity.buckets[0].key : null,
          equipmentId: isEquipment ? Number(data.key.id) : null,
          equipmentName: isEquipment ? data.key.equipment : null,
          taskId: isTask ? Number(data.key.id) : null,
          taskName: isTask ? data.key.task : null,
          activityType: item.key,
          duration: data.duration.value,
          noEquipmentAssigned: isTask ? this.calculateNoEquipmentAssignedDuration(data) : 0,
          entityTranslations: isActivity
            ? activityEntityTranslations
            : isTask
            ? taskEntityTranslations
            : isEquipment
            ? equipmentEntityTranslations
            : [],
        };
        if (data.hasOwnProperty('activity') && data?.activity.buckets.length > 0) {
          formattedBucket = {
            ...formattedBucket,
            activities: this.getActivities(data.activity.buckets)
          }
        } else {
          formattedBucket = {
            ...formattedBucket,
            activities: [],
          }
        }
        if (data.hasOwnProperty('equipment') && data?.equipment.buckets.length > 0) {
          formattedBucket = {
            ...formattedBucket,
            equipments: this.getEquipments(data.equipment.buckets),
          }
        } else {
          formattedBucket = {
            ...formattedBucket,
            equipments: [],
          }
        }
        if (data.hasOwnProperty('task') && data?.task.buckets.length > 0) {
          formattedBucket = {
            ...formattedBucket,
            tasks: this.getTasks(data.task.buckets, data.key.hasOwnProperty('activity') ? data.key.activity : null),
          }
        } else {
          formattedBucket = {
            ...formattedBucket,
            tasks: [],
          }
        }
        formattedBuckets.push(formattedBucket);
      }
    }

    return formattedBuckets;
  }

  public formatComparisonData(comparisonData: any, params: any): any[] {
    const formattedBuckets: any[] = [];
    const comparisonGroupName: string = params.comparisonGroup;
    for (const data of comparisonData[comparisonGroupName].buckets) {
      const formattedBucket: IComparisonChartDataInterface = {
        activities: this.getComparisonActivities(data.activity.buckets),
        equipments: this.getComparisonEquipments(data.equipment.buckets),
        tasks: this.getComparisonTasks(data.task.buckets),
        groupId: Number(data.key['id']),
        groupName: params.comparisonGroup === 'product' ? `${data.key.product} - ${data.key.description}` : data.key[comparisonGroupName],
      };
      formattedBuckets.push(formattedBucket);
    }
    return formattedBuckets;
  }

  private getComparisonActivities(data: any[]): IComparisonActivityInterface[] {
    const activities: IComparisonActivityInterface [] = [];
    data.forEach((item: any) => {
      activities.push(({
        activityId: item.key,
        activityName: item.key,
        activityType: item.key,
        duration: item.duration.value,
      }));
    });

    return activities;
  }

  private getComparisonEquipments(data: any[]): IComparisonEquipmentInterface[] {
    const equipments: IComparisonEquipmentInterface [] = [];
    data.forEach((item: any) => {
      if (item?.key !== 'N/A')
        equipments.push(({
          equipmentId: item.key,
          equipmentName: item.key,
          activityType: item.key,
          duration: item.duration.value,
        }));
    });

    return equipments;
  }

  private getComparisonTasks(data: any[]): IComparisonTaskInterface[] {
    const tasks: IComparisonTaskInterface [] = [];
    data.forEach((item: any) => {
      tasks.push(({
        taskId: item.key,
        taskName: item.key ?? 'N/A',
        activityType: item.key,
        duration: item.duration.value,
      }));
    });

    return tasks;
  }

  private getActivities(data: any[]): any {
    const activities = [];
    data.forEach((item: IBucketItemInterface) => {
      activities.push({
        ...item,
        activityId: Number(item.key.id) ?? Number(item.key),
        activityName: item.key.activity ?? item.key,
        duration: item.duration.value,
      });
    });
    return activities;
  }

  private getEquipments(data: any[]): any {
    const equipments = [];
    data.forEach((item: any) => {
      if ((item.key.equipment ?? item.key) !== 'N/A') {
        equipments.push({
          ...item,
          equipmentId: Number(item.key.id) ?? Number(item.key),
          equipmentName: item.key.equipment ?? item.key,
          duration: item.duration.value,
        });
      }
    });

    return equipments;
  }

  private getTasks(data: any[], activity?: string): any {
    const tasks = [];
    data.forEach((item: any) => {
      tasks.push({
        ...item,
        taskId: Number(item.key.id) ?? Number(item.key),
        taskName: item.key.task ?? item.key,
        duration: item.duration.value,
        activity: activity,
        targetDuration: item.include_fields?.hits?.hits[0]?._source.task.target,
        taskRepetitionCount: item.doc_count
      });
    });
    return tasks;
  }

  private calculateNoEquipmentAssignedDuration(data: any): number {
    let duration: number = 0;
    if (data.equipment?.buckets.length === 0) {
      data.activityType.buckets.forEach((item: any) => {
        duration += item.duration.value;
      });
    }
    return duration;
  }

  public getRootCauseAnalysisElasticObservables(
    params: RootCauseAnalysisFilterInterface,
  ): Observable<| ResponseArrayInterface<ICurrentShift>
    | ResponseArrayInterface<IActivityLog>
    | GetManyResponseInterface<SiteCRUDInterface>>[] {
    return [
      this.shiftService.getCurrentShifts(new HttpParams().append('useReplica', true)),
      this.getElasticLineData(params),
      this.sitesService.getSites(
        new HttpParams().set('limit', 5000).append('fields', 'preRunPhaseName,runPhaseName,postRunPhaseName'),
      ),
    ];
  }

  private combineEntityTranslations(
    data: any,
    isChart: boolean = false,
    isActivityData: boolean = false,
    index?: number,
  ): IEntityTranslation[] {
    const entityTranslations: IEntityTranslation[] = [];

    if (isChart) {
      let entity: any = null;

      if (data.include_fields) {
        data.include_fields.hits.hits.forEach((item) => {
          entity = item._source;

          Object.entries(
            isActivityData ? this.entityTranslationKeyPropertyMapForSubProperties : this.entityTranslationKeyPropertyMap,
          ).forEach(([key, value]) => {
            if (key in (entity ?? {})) {
              Object.values(entity[key]?.translations ?? {}).forEach((translations: IEntityTranslation[]) => {
                if (translations) {
                  translations.forEach((translation: IEntityTranslation) => {
                    const entityTranslationObject: IEntityTranslation = {
                      language: translation.language,
                      propertyName: isActivityData ? value(index) : value,
                      value: translation.value,
                    };
                    entityTranslations.push(entityTranslationObject);
                  });
                }
              });
            }
          });
        });
      }

      return entityTranslations;
    }

    Object.entries(this.entityTranslationKeyPropertyMap).forEach(([key, value]) => {
      if (key in (data ?? {})) {
        Object.values(data[key]?.translations ?? {}).forEach((translations: IEntityTranslation[]) => {
          if (translations) {
            translations.forEach((translation: IEntityTranslation) => {
              const entityTranslationObject: IEntityTranslation = {
                language: translation.language,
                propertyName: value,
                value: translation.value,
              };
              entityTranslations.push(entityTranslationObject);
            });
          }
        });
      }
    });

    if (data.workOrderSchedule && data.workOrderSchedule.product && data.workOrderSchedule.product.translations) {
      Object.entries(data.workOrderSchedule.product.translations).forEach(([key, translations]: [string, IEntityTranslation[]]) => {
        if (translations) {
          translations.forEach((translation: IEntityTranslation) => {
            const entityTranslationObject: IEntityTranslation = {
              language: translation.language,
              propertyName: key === 'name' ? 'productName' : 'productDescription',
              value: translation.value,
            };
            entityTranslations.push(entityTranslationObject);
          });
        }
      });
    }

    return entityTranslations;
  }
}

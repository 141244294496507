<app-base-modal (onClose)="onCancel.emit()">
  <ng-container modal-title>{{ 'main.workOrder.manageCountModal.header' | translate }}</ng-container>
  <ng-container modal-body>
    <scw-mat-form #form class="container">
      <div class="row">
        <div class="col-12">
          <scw-mat-input
            [label]="inputLabels.entry1"
            [(inputModel)]="inputValues.entry1"
            (inputModelChange)="resetRules()"
            [rules]="rules.entry1"
            #countEntry1
          ></scw-mat-input>
        </div>
        <div class="col-12">
          <scw-mat-input
            [label]="inputLabels.entry2"
            [(inputModel)]="inputValues.entry2"
            (inputModelChange)="resetRules()"
            [rules]="rules.entry2"
            #countEntry2
          ></scw-mat-input>
        </div>
        <div *ngIf="isReworkCountsEnabled$ | async" class="col-12">
          <scw-mat-input
            [label]="inputLabels.reworkCount"
            [(inputModel)]="inputValues.reworkCount"
            (inputModelChange)="resetRules()"
            [rules]="rules.reworkCount"
            #rework
          ></scw-mat-input>
        </div>
      </div>
    </scw-mat-form>
  </ng-container>
  <ng-container modal-footer>
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="onAdvancedModalButtonClicked.emit()">
        {{ 'main.workOrder.manageCountModal.advancedCountManagementButton' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="onCancel.emit()">{{ 'general.cancel' | translate }}</scw-mat-button>
      <scw-mat-button type="cta" (onClick)="confirm(form.isValid)">{{
        'general.saveChanges' | translate
      }}</scw-mat-button>
    </div>
  </ng-container>
</app-base-modal>

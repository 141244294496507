import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../service/helper.service';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import {
  EPerformanceChartMode,
  EProductionPerformanceDeepDiveShiftDayGroupBy,
  IProductionPerformanceDeepDiveShiftDayRequest,
  IProductionPerformanceDeepDiveShiftDayResponse,
} from '../../../store/reports/performance-deep-dive/performance-deep-dive.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { DropdownOptionInterface } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { EStackChartGroupBy } from '../../../view/reports/root-cause-analysis/charts/charts.model';
import {
  DailyProductionPerformanceActivityLogsChartInterface,
  DailyProductionPerformanceActivityLogsInterface,
  DailyProductionPerformanceActivityLogsTooltipInterface,
  DailyProductionPerformanceDataInterface,
  DailyProductionPerformanceEstimationDataInterface,
  DailyProductionPerformanceProductionCountInterface,
  EstimationDataInterface,
  ProductionPerformanceActivitySensorCountInterface,
  ProductionPerformanceActivityTargetCountInterface,
} from '../../../store/dashboards/daily-production-performance/daily-production-performance.model';
import * as echarts from 'echarts';
import * as moment from 'moment';
import { activityColors } from '../../helper/app-helper';
import { ActionsSubject } from '@ngrx/store';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import { ScwMatInputComponent } from '../scw-mat-ui/scw-mat-input/scw-mat-input.component';
import { Subscription } from 'rxjs';
import * as DailyProductionPerformanceActions from '../../../store/dashboards/daily-production-performance/daily-production-performance.actions';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss'],
})
export class PerformanceChartComponent implements OnChanges, AfterViewInit {
  private readonly subscriptions: Subscription[] = [];
  private activityOverlapCategoryCount: number;
  private activitiesCategorized: DailyProductionPerformanceActivityLogsInterface[] = [];
  @Input() set chartData(currentChartData) {
    if (_.isEqual(this.currentChartData, currentChartData) || _.isNil(currentChartData)) {
      return;
    }

    if (this.isDailyProductionPerformance && _.isNil(currentChartData.estimations)) {
      this.currentChartData = null;
      this.isChartMissingEstimationData = true;
      this.defaultChartData = null;
      return;
    }

    if (!this.isDailyProductionPerformance && _.isEmpty(currentChartData.activityTargetCounts)) {
      this.currentChartData = null;
      this.defaultChartData = null;
      return;
    }

    this.currentChartData = currentChartData;
    this.defaultChartData = currentChartData;
    this.isChartMissingEstimationData = false;
    this.updateChartDataWithGroupBy([]);
  }

  @Input() set setActivityLogData(currentActivityLog) {
    if (_.isEqual(this.activityLogData, currentActivityLog) || _.isNil(currentActivityLog)) {
      return;
    }

    this.activityLogData = currentActivityLog;
    this.updateChartData();
  }

  @Input() isPerformanceChartLoading: boolean = false;
  @Input() effectiveRunTimeProductionPercentage: string = '70';
  @Input() isDailyProductionPerformance: boolean = true;
  @Input() useGroupByAndChartMode: boolean = false;
  @Input() shiftDay: IProductionPerformanceDeepDiveShiftDayResponse[] = [];
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() getShiftOfDay = new EventEmitter<IProductionPerformanceDeepDiveShiftDayRequest>();
  @ViewChild('effectiveRunTimeProduction') effectiveRunTimeProduction: ScwMatInputComponent;

  public currentChartData: DailyProductionPerformanceDataInterface;
  public activityLogData: DailyProductionPerformanceActivityLogsInterface[];
  private lastEffectiveRunTimeProductionPercentage: string = this.effectiveRunTimeProductionPercentage;
  private isEffectiveRunTimeProductionPercentageValid: boolean = true;
  private inputTimeout: NodeJS.Timeout;
  private inputDelay: number = 600;
  public readonly effectiveRunTimeProductionPercentageRules: ScwMatInputRule[] = [
    { required: true },
    this.decimalHelper.getDecimalNonZeroInputRule('0', '100'),
  ];

  private readonly sensorCountStyleThreshold: number = 100;
  private readonly effectiveTargetKey: string = 'performanceChart.productionTarget';
  private readonly productionKey: string = 'performanceChart.actualProduction';
  private readonly effectiveEstimationKey: string = 'performanceChart.reachableProduction';
  private readonly estimationKey: string = 'performanceChart.productEstimation';
  private readonly sensorCountKey: string = 'performanceChart.sensorCount';
  private readonly nowLineKey: string = 'performanceChart.nowLine';
  private readonly activitiesKey: string = 'performanceChart.activities';
  private readonly tooltipKeys: DailyProductionPerformanceActivityLogsTooltipInterface = {
    task: 'performanceChart.activitiesTooltip.task',
    startDate: 'performanceChart.activitiesTooltip.startDate',
    endDate: 'performanceChart.activitiesTooltip.endDate',
    duration: 'performanceChart.activitiesTooltip.duration',
    product: 'performanceChart.activitiesTooltip.product',
    workOrder: 'performanceChart.activitiesTooltip.workOrder',
  };
  public informativeModalHeader: string;
  public informativeModalData: { header: string; messages: string[] }[];

  private readonly axisNameFontStyle: string = 'italic';

  public performanceChartInstance;
  public performanceChartOptions;
  public formattedStartDate: string;
  public formattedEndDate: string;
  public isChartMissingEstimationData: boolean = false;
  public performanceChartModeButtons: ScwMatButtonGroupButtons[] = [
    {
      value: EPerformanceChartMode.OEE_BASED,
      text: this.translate.instant('performanceDeepDive.chart.performanceChartMode.oeeBased'),
    },
    {
      value: EPerformanceChartMode.PERFORMANCE_BASED,
      text: this.translate.instant('performanceDeepDive.chart.performanceChartMode.performanceBased'),
    },
  ];
  public chartMode: EPerformanceChartMode = EPerformanceChartMode.OEE_BASED;
  public chartGroupByData: DropdownOptionInterface[] = [
    {
      id: EStackChartGroupBy.ALL_TIME,
      name: this.translate.instant('general.allTime'),
    },
    {
      id: EStackChartGroupBy.SHIFT,
      name: this.translate.instant('general.shift'),
    },
    {
      id: EStackChartGroupBy.DAY,
      name: this.translate.instant('general.day'),
    },
    {
      id: EStackChartGroupBy.WEEK,
      name: this.translate.instant('general.week'),
    },
    {
      id: EStackChartGroupBy.MONTH,
      name: this.translate.instant('general.month'),
    },
  ];

  public chartGroupBy: DropdownOptionInterface[] = [this.chartGroupByData[0]];
  public defaultChartData: DailyProductionPerformanceDataInterface;

  constructor(
    private readonly storeActions: ActionsSubject,
    private readonly decimalHelper: DecimalHelper,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(DailyProductionPerformanceActions.DAILY_PRODUCTION_PERFORMANCE_DATA_LOADING))
        .subscribe(() => {
          this.emitInput();
        }),
    );
  }

  public ngAfterViewInit(): void {
    this.informativeModalHeader = this.isDailyProductionPerformance
      ? this.translate.instant('performanceChart.infoModal.title')
      : this.translate.instant('performanceChart.infoModal.titleWithoutKpi');
    this.informativeModalData = [
      ...(this.isDailyProductionPerformance
        ? [
            {
              header: this.translate.instant('dailyProductionPerformance.kpiCard.productionEstimationVariance'),
              messages: [this.translate.instant('performanceChart.infoModal.productEstimationVariance')],
            },
            {
              header: this.translate.instant('dailyProductionPerformance.kpiCard.remaining'),
              messages: [this.translate.instant('performanceChart.infoModal.remainingDescription')],
            },
            {
              header: this.translate.instant('performanceChart.infoModal.unplannedDownTimeDuration'),
              messages: [this.translate.instant('performanceChart.infoModal.unplannedDownTimeDurationDescription')],
            },
            {
              header: this.translate.instant('performanceChart.infoModal.plannedDownTimeDuration'),
              messages: [this.translate.instant('performanceChart.infoModal.plannedDownTimeDurationDescription')],
            },
            {
              header: this.translate.instant('dailyProductionPerformance.kpiCard.currentProductSpeed'),
              messages: [this.translate.instant('dailyProductionPerformance.kpiCard.currentProductSpeedDescription')],
            },
          ]
        : []),
      {
        header: this.translate.instant(this.effectiveTargetKey),
        messages: [
          this.isDailyProductionPerformance
            ? this.translate.instant('performanceChart.infoModal.productionTargetDescription')
            : this.translate.instant('performanceChart.infoModal.productionTargetDescriptionDeepDive'),
        ],
      },
      {
        header: this.translate.instant(this.productionKey),
        messages: [
          this.isDailyProductionPerformance
            ? this.translate.instant('performanceChart.infoModal.actualProductionDescription')
            : this.translate.instant('performanceChart.infoModal.actualProductionDescriptionDeepDive'),
        ],
      },
      ...(this.isDailyProductionPerformance
        ? [
            {
              header: this.translate.instant(this.effectiveEstimationKey),
              messages: [this.translate.instant('performanceChart.infoModal.reachableProductionDescription')],
            },
            {
              header: this.translate.instant(this.estimationKey),
              messages: [this.translate.instant('performanceChart.infoModal.productionEstimationDescription')],
            },
          ]
        : []),
      {
        header: this.translate.instant(this.sensorCountKey),
        messages: [this.translate.instant('performanceChart.infoModal.sensorCountDescription')],
      },
    ];
    this.performanceChartOptions = {
      tooltip: {
        trigger: 'axis',
        position(pt) {
          return [pt[0] - 50, '10%'];
        },
        formatter: (items): string => {
          const info: string[] = items.map(
            (item): string =>
              `<span class='tooltip-icon' style='background-color:${
                item.borderColor || item.color
              };'> </span><strong>${this.translate.instant(
                item.seriesName,
              )}:</strong>  ${this.decimalHelper.toFixedValue(item.data[1], 2)}<br>`,
          );
          info.unshift(
            `<strong>${this.translate.instant(
              'general.dateLabel',
            )}:</strong> ${this.helperService.setUserDateTimeFormat(items[0].data[0], true)}<br>`,
          );
          return info.join('');
        },
      },
      legend: {
        data: [
          {
            name: this.effectiveTargetKey,
          },
          {
            name: this.productionKey,
          },
          ...(this.isDailyProductionPerformance
            ? [
                {
                  name: this.effectiveEstimationKey,
                },
                {
                  name: this.estimationKey,
                },
              ]
            : []),
          {
            name: this.sensorCountKey,
          },
        ],
        formatter: (name) => {
          return this.translate.instant(name);
        },
      },
      grid: [
        {
          left: '10%',
          right: '8%',
          height: '70%',
        },
        {
          left: '10%',
          right: '8%',
          top: '90%',
          height: '10%',
        },
      ],
      xAxis: [
        {
          type: 'time',
          name: this.translate.instant('deepDiveAnalysis.charts.timePeriod'),
          nameLocation: 'middle',
          scale: true,
          nameTextStyle: {
            padding: 12,
            fontStyle: this.axisNameFontStyle,
          },
        },
        ...(this.isDailyProductionPerformance
          ? [
              {
                type: 'value',
                gridIndex: 1,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
              },
            ]
          : []),
      ],
      yAxis: [
        {
          type: 'value',
          name: this.translate.instant('performanceChart.productionCount'),
          nameLocation: 'middle',
          nameTextStyle: {
            padding: 60,
            fontStyle: this.axisNameFontStyle,
          },
        },
        ...(this.isDailyProductionPerformance
          ? [
              {
                type: 'category',
                scale: true,
                data: [],
                gridIndex: 1,
                axisLabel: { show: true },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
              },
            ]
          : []),
      ],
      series: [
        {
          name: this.effectiveTargetKey,
          label: {
            formatter: (params) => {
              return this.translate.instant(params.name);
            },
          },
          type: 'line',
          smooth: false,
          symbol: 'circle',
          symbolSize: 8,
          showSymbol: false,
          data: [],
          z: 2,
          lineStyle: {
            color: '#9B9BA1',
            width: 4,
            type: 'dashed',
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: '#9B9BA1',
            color: '#FFFFFF',
          },
        },
        {
          name: this.productionKey,
          label: {
            formatter: (params) => {
              return this.translate.instant(params.name);
            },
          },
          type: 'line',
          data: [],
          symbol: 'circle',
          symbolSize: 8,
          showSymbol: false,
          z: 5,
          lineStyle: {
            color: '#1F1FAB',
            width: 7,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: '#1F1FAB',
            color: '#FFFFFF',
          },
          markLine: {
            silent: true,
            lineStyle: {
              width: 4,
              color: '#333333',
              type: 'dashed',
            },
          },
        },
        ...(this.isDailyProductionPerformance
          ? [
              {
                name: this.effectiveEstimationKey,
                label: {
                  formatter: (params) => {
                    return this.translate.instant(params.name);
                  },
                },
                type: 'line',
                data: [],
                symbol: 'circle',
                symbolSize: 8,
                showSymbol: false,
                z: 3,
                lineStyle: {
                  color: '#DD332B',
                  width: 6,
                },
                itemStyle: {
                  borderWidth: 2,
                  borderColor: '#DD332B',
                  color: '#FFFFFF',
                },
              },
              {
                name: this.estimationKey,
                label: {
                  formatter: (params) => {
                    return this.translate.instant(params.name);
                  },
                },
                type: 'line',
                data: [],
                symbol: 'circle',
                showSymbol: false,
                symbolSize: 8,
                z: 4,
                lineStyle: {
                  color: '#7373F0',
                  width: 7,
                },
                itemStyle: {
                  borderWidth: 2,
                  borderColor: '#7373F0',
                  color: '#FFFFFF',
                },
              },
              {
                name: this.nowLineKey,
                label: {
                  formatter: (params) => {
                    return this.translate.instant(params.name);
                  },
                },
                type: 'line',
              },
              {
                name: this.activitiesKey,
                tooltip: {
                  trigger: 'item',
                  position: 'top',
                  formatter: (items): string => this.getActivityLogsTooltip(items),
                },
                label: {
                  formatter: (params) => {
                    return this.translate.instant(params.name);
                  },
                },
                type: 'custom',
                renderItem: this.renderActivityChartItem,
                itemStyle: {
                  opacity: 1,
                },
                encode: {
                  x: [1, 2],
                  y: 0,
                },
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: [],
              },
            ]
          : []),
        {
          name: this.sensorCountKey,
          label: {
            formatter: (params) => {
              return this.translate.instant(params.name);
            },
          },
          type: 'scatter',
          symbol: 'circle',
          showSymbol: false,
          symbolSize: 8,
          data: [],
          z: 3,
          lineStyle: {
            color: '#3EB0A3',
            width: 2,
          },
          itemStyle: {
            color: '#3EB0A3',
            borderColor: '#3EB0A3',
          },
        },
      ],
    };
  }

  public onInputModelChange(): void {
    this.lastEffectiveRunTimeProductionPercentage = this.effectiveRunTimeProductionPercentage;

    clearTimeout(this.inputTimeout);

    this.inputTimeout = setTimeout((): void => {
      if (
        this.effectiveRunTimeProductionPercentage === this.lastEffectiveRunTimeProductionPercentage &&
        this.isEffectiveRunTimeProductionPercentageValid
      ) {
        clearTimeout(this.inputTimeout);
        this.emitInput();
        this.updateChartData();
      }
    }, this.inputDelay);
  }

  public emitInput(): void {
    this.onInput.emit(this.effectiveRunTimeProductionPercentage);
    this.lastEffectiveRunTimeProductionPercentage = this.effectiveRunTimeProductionPercentage;
    return;
  }

  public updateChartData(): void {
    if (_.isNil(this.defaultChartData)) {
      return;
    }

    this.currentChartData = this.defaultChartData;
    this.formattedStartDate = this.helperService.setUserDateTimeFormat(this.currentChartData?.startDateTime, true);
    this.formattedEndDate = this.helperService.setUserDateTimeFormat(this.currentChartData?.endDateTime, true);

    if (this.currentChartData !== undefined) {
      const formattedActivitySensorCounts = this.findTimeChangeFromData(this.currentChartData?.activitySensorCounts);
      this.currentChartData = {
        ...this.currentChartData,
        activitySensorCounts: formattedActivitySensorCounts,
      };
    }

    const { sensorData } = this.currentChartData?.activitySensorCounts.reduce(
      (
        total: { sensorTotal: string; sensorData: unknown[][] },
        count: ProductionPerformanceActivitySensorCountInterface,
      ) => {
        if (count?.timeChanged === true) {
          total.sensorData.push([count.start, '0']);
          total.sensorTotal = '0';
        }

        total.sensorTotal = this.decimalHelper.add(total.sensorTotal, count.count);
        total.sensorData.push([count.end, String(total.sensorTotal)]);
        return total;
      },
      {
        sensorTotal: '0',
        sensorData: [],
      },
    );

    if (this.currentChartData !== undefined) {
      const formattedProductionCounts = this.findTimeChangeFromData(this.currentChartData?.productionCounts);
      this.currentChartData = {
        ...this.currentChartData,
        productionCounts: formattedProductionCounts,
      };
    }

    const { productionData, productionTotal } = this.currentChartData?.productionCounts.reduce(
      (
        total: { productionTotal: string; productionData: unknown[][] },
        count: DailyProductionPerformanceProductionCountInterface,
      ) => {
        if (count?.timeChanged === true) {
          total.productionData.push([count.start, '0']);
          total.productionTotal = '0';
        }

        total.productionTotal = this.decimalHelper.add(total.productionTotal, count.count);
        total.productionData.push([count.end, String(total.productionTotal)]);

        return total;
      },
      {
        productionTotal: '0',
        productionData: [],
      },
    );

    if (productionData.length > 0) {
      productionData.unshift([this.currentChartData.startDateTime, '0']);
    }

    if (this.currentChartData !== undefined) {
      const formattedActivityTargetCounts = this.findTimeChangeFromData(this.currentChartData.activityTargetCounts);
      this.currentChartData = {
        ...this.currentChartData,
        activityTargetCounts: formattedActivityTargetCounts,
      };
    }

    const { effectiveTargetData, effectiveTargetTotal } = this.currentChartData?.activityTargetCounts.reduce(
      (
        total: { effectiveTargetData: string[][]; effectiveTargetTotal: string },
        count: ProductionPerformanceActivityTargetCountInterface,
      ) => {
        if (
          this.chartMode === EPerformanceChartMode.PERFORMANCE_BASED &&
          [ActivityTypes.RUN_TIME, ActivityTypes.DOWN_TIME].includes(count.activityType)
        ) {
          total.effectiveTargetTotal = this.decimalHelper.add(total.effectiveTargetTotal, count.count);
        } else if (this.chartMode === EPerformanceChartMode.OEE_BASED) {
          total.effectiveTargetTotal = this.decimalHelper.add(total.effectiveTargetTotal, count.count);
        }

        if (count?.timeChanged === true) {
          total.effectiveTargetData.push([count.start, '0']);
          total.effectiveTargetTotal = '0';
        }

        total.effectiveTargetData.push([count.end, String(total.effectiveTargetTotal)]);
        return total;
      },
      {
        effectiveTargetTotal: '0',
        effectiveTargetData: [],
      },
    );

    effectiveTargetData.unshift([this.currentChartData.startDateTime, '0']);

    let effectiveTargetEstimationData: string[][];
    let effectiveEstimationData: string[][];
    let productionEstimationData: string[][];

    if (this.isDailyProductionPerformance) {
      _.find(this.performanceChartOptions.series, {
        name: this.nowLineKey,
      }).markLine = {
        data: [
          {
            name: this.nowLineKey,
            label: {
              formatter: (params) => {
                return this.translate.instant(params.name);
              },
            },
            xAxis: this.currentChartData.currentDateTime,
          },
        ],
        silent: false,
        symbol: 'none',
        z: 0,
        lineStyle: {
          type: 'dashed',
          width: 1,
          color: '#C3C3C3',
        },
        emphasis: {
          disabled: false,
          label: {
            show: true,
          },
        },
      };

      const estimationData = this.currentChartData.estimations?.estimationData?.reduce(
        (
          reducer: EstimationDataInterface,
          estimation: DailyProductionPerformanceEstimationDataInterface,
        ): EstimationDataInterface => {
          reducer.effectiveTargetEstimationTotal = this.decimalHelper.add(
            reducer.effectiveTargetEstimationTotal,
            estimation.estimatedActivityTargetCount,
          );
          reducer.effectiveTargetEstimationData.push([estimation.end, String(reducer.effectiveTargetEstimationTotal)]);

          reducer.effectiveEstimationTotal = this.decimalHelper.add(
            reducer.effectiveEstimationTotal,
            estimation.estimatedActivityTargetCount,
          );
          reducer.effectiveEstimationData.push([estimation.end, String(reducer.effectiveEstimationTotal)]);

          reducer.productionEstimationTotal = this.decimalHelper.add(
            reducer.productionEstimationTotal,
            estimation.estimatedProductionCount,
          );
          reducer.productionEstimationData.push([estimation.end, String(reducer.productionEstimationTotal)]);

          return reducer;
        },
        {
          effectiveTargetEstimationTotal: effectiveTargetTotal,
          effectiveTargetEstimationData: [...effectiveTargetData],
          effectiveEstimationTotal: '0',
          effectiveEstimationData: [],
          productionEstimationTotal: '0',
          productionEstimationData: [],
        },
      );

      if (!_.isNil(estimationData)) {
        effectiveTargetEstimationData = estimationData.effectiveTargetEstimationData;
        effectiveEstimationData = estimationData.effectiveEstimationData;
        productionEstimationData = estimationData.productionEstimationData;
        effectiveEstimationData.unshift([this.currentChartData.currentDateTime, '0']);
        productionEstimationData.unshift([this.currentChartData.currentDateTime, '0']);
      }
    }

    if (this.isDailyProductionPerformance) {
      if (this.effectiveRunTimeProductionPercentage?.length > 0) {
        this.effectiveRunTimeProduction.clearErrorMessage();
      }

      if (!_.isNil(effectiveTargetEstimationData)) {
        _.find(this.performanceChartOptions.series, {
          name: this.effectiveTargetKey,
        }).data = this.applyEffectiveRunTimeProductionPercentage(effectiveTargetEstimationData);
      }

      if (!_.isNil(this.activityLogData)) {
        this.formatActivity();
        this.setMinimumAndMaximumDates();
        this.categorizeActivitiesByOverlap();
        this.performanceChartOptions.yAxis[1].data = this.getActivityChartCategoryData();
        this.performanceChartInstance?.setOption(this.performanceChartOptions);
        _.find(this.performanceChartOptions.series, {
          name: this.activitiesKey,
        }).data = this.getChartFormattedActivityData();
      }
    } else {
      if (!_.isNil(effectiveTargetData)) {
        _.find(this.performanceChartOptions.series, {
          name: this.effectiveTargetKey,
        }).data = this.applyEffectiveRunTimeProductionPercentage(effectiveTargetData);
      }
    }

    if (!_.isNil(productionData)) {
      _.find(this.performanceChartOptions.series, {
        name: this.productionKey,
      }).data = productionData;
    }

    if (!_.isNil(sensorData)) {
      const sensorSeries = _.find(this.performanceChartOptions.series, {
        name: this.sensorCountKey,
      });

      sensorSeries.data = sensorData;

      if (sensorData.length > this.sensorCountStyleThreshold) {
        sensorSeries.type = 'line';
        sensorSeries.itemStyle = {
          color: '#ffffff',
          borderColor: '#3EB0A3',
          borderWidth: 2,
        };
        sensorSeries.lineStyle = {
          color: '#3EB0A3',
          borderColor: 'none',
        };
      } else {
        sensorSeries.type = 'scatter';
        sensorSeries.itemStyle = {
          color: '#3EB0A3',
        };
      }
    }

    if (this.isDailyProductionPerformance) {
      if (!_.isNil(effectiveEstimationData)) {
        _.find(this.performanceChartOptions.series, {
          name: this.effectiveEstimationKey,
        }).data = this.applyEffectiveRunTimeProductionPercentage(effectiveEstimationData).map((dataPoint) => {
          const newDataPoint = _.cloneDeep(dataPoint);
          newDataPoint[1] = this.decimalHelper.add(newDataPoint[1], productionTotal);
          return newDataPoint;
        });
      }

      if (!_.isNil(productionEstimationData)) {
        _.find(this.performanceChartOptions.series, {
          name: this.estimationKey,
        }).data = productionEstimationData.map((dataPoint) => {
          const newDataPoint = _.cloneDeep(dataPoint);
          newDataPoint[1] = this.decimalHelper.add(newDataPoint[1], productionTotal);
          return newDataPoint;
        });
      }
    }

    this.performanceChartInstance?.setOption({
      series: this.performanceChartOptions.series,
      xAxis: {
        min: this.performanceChartOptions.startDateTime,
        max: this.performanceChartOptions.endDateTime,
      },
    });

    if (this.isDailyProductionPerformance) {
      this.performanceChartOptions.xAxis = [
        {
          ...this.performanceChartOptions.xAxis[0],
          min: this.performanceChartOptions.startDateTime,
          max: this.performanceChartOptions.endDateTime,
        },
        {
          ...this.performanceChartOptions.xAxis[1],
          min: moment(this.currentChartData.startDateTime).valueOf(),
          max: moment(this.currentChartData.endDateTime).valueOf(),
        },
      ];
    }
  }

  public onChartInit(event): void {
    this.performanceChartInstance = event;
    this.performanceChartInstance?.on('legendselectchanged', () => {
      if (this.isDailyProductionPerformance) {
        this.performanceChartInstance?.setOption({
          xAxis: [
            {
              ...this.performanceChartOptions.xAxis[0],
              min: this.currentChartData.startDateTime,
              max: this.currentChartData.endDateTime,
            },
            {
              ...this.performanceChartOptions.xAxis[1],
              min: moment(this.currentChartData.startDateTime).valueOf(),
              max: moment(this.currentChartData.endDateTime).valueOf(),
            },
          ],
        });
      }
    });
  }

  public applyEffectiveRunTimeProductionPercentage(data: string[][]): string[][] {
    return data.map((dataPoint: string[]) => {
      const newDataPoint = _.cloneDeep(dataPoint);
      newDataPoint[1] = this.decimalHelper.multiply(
        dataPoint[1],
        this.decimalHelper.divide(this.decimalHelper.sanitizeString(this.effectiveRunTimeProductionPercentage), '100'),
      );
      return newDataPoint;
    });
  }

  public onModeChanged(mode: EPerformanceChartMode): void {
    this.chartMode = mode;

    this.updateChartDataWithGroupBy(this.chartGroupBy);
  }

  public updateChartDataWithGroupBy(chartTimeOption: DropdownOptionInterface[]): void {
    this.chartGroupBy = chartTimeOption;
    let groupByParams: EProductionPerformanceDeepDiveShiftDayGroupBy;

    switch (this.chartGroupBy[0]?.id) {
      case EStackChartGroupBy.SHIFT:
        groupByParams = EProductionPerformanceDeepDiveShiftDayGroupBy.START;
        break;
      case EStackChartGroupBy.DAY:
        groupByParams = EProductionPerformanceDeepDiveShiftDayGroupBy.CALENDAR_DATE;
        break;
      case EStackChartGroupBy.WEEK:
        groupByParams = EProductionPerformanceDeepDiveShiftDayGroupBy.WEEK;
        break;
      case EStackChartGroupBy.MONTH:
        groupByParams = EProductionPerformanceDeepDiveShiftDayGroupBy.MONTH;
        break;
      default:
        this.chartGroupBy = [this.chartGroupByData[0]];
        this.shiftDay = [];
        this.updateChartData();

        return;
    }

    const endDate: string =
      moment(this.currentChartData.endDateTime) > moment(this.currentChartData.currentDateTime)
        ? moment(this.currentChartData.currentDateTime).format(mysqlDateFormat)
        : moment(this.currentChartData.endDateTime).format(mysqlDateFormat);

    const getShiftDayDataParams: IProductionPerformanceDeepDiveShiftDayRequest = {
      startDate: moment(this.currentChartData.startDateTime).format(mysqlDateFormat),
      endDate: endDate,
      groupBy: groupByParams,
    };

    this.getShiftOfDay.emit(getShiftDayDataParams);
  }

  public findTimeChangeFromData(
    chartData:
      | ProductionPerformanceActivityTargetCountInterface[]
      | ProductionPerformanceActivitySensorCountInterface[]
      | DailyProductionPerformanceProductionCountInterface[],
  ) {
    if (chartData === undefined) {
      return chartData;
    }

    let formattedData = [];
    const formattedDataMap = new Map();

    chartData.forEach((row) => {
      const data = HelperService.cloneDeep(row);
      data.count =
        data.count !== undefined
          ? data.count
          : data.sensorCount !== undefined
          ? data.sensorCount
          : data.activityTargetCount;
      formattedDataMap.set(row.start, data);
    });

    this.shiftDay.forEach((shiftData: IProductionPerformanceDeepDiveShiftDayResponse) => {
      const rowData = formattedDataMap.get(moment(shiftData.start).format(mysqlTimestampFormat));

      if (!rowData) {
        formattedDataMap.set(moment(shiftData.start).format(mysqlTimestampFormat), {
          start: moment(shiftData.start).format(mysqlTimestampFormat),
          end: moment(shiftData.start).add(1, 'hour').format(mysqlTimestampFormat),
          count: '0',
          timeChanged: true,
        });
      } else {
        rowData.timeChanged = true;
      }
    });

    formattedData = Array.from(formattedDataMap.values());
    formattedData?.sort(
      (a: DailyProductionPerformanceProductionCountInterface, b: DailyProductionPerformanceProductionCountInterface) =>
        moment(a.start, mysqlTimestampFormat).diff(moment(b.start, mysqlTimestampFormat)),
    );

    return formattedData;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('isPerformanceChartLoading')) {
      this.isPerformanceChartLoading = changes?.['isPerformanceChartLoading']?.currentValue;
    }

    if (!_.isNil(this.currentChartData)) {
      this.onChartInit(this.performanceChartInstance);
      this.updateChartData();
    }

    if (this.useGroupByAndChartMode && this.currentChartData !== undefined && changes.hasOwnProperty('shiftDay')) {
      this.updateChartData();
    }
  }

  public inputIsValid(event: boolean): void {
    this.isEffectiveRunTimeProductionPercentageValid = event;
  }

  private formatActivity(): void {
    this.activityLogData = this.activityLogData.map(
      (item: DailyProductionPerformanceActivityLogsInterface, index: number) => {
        return {
          ...item,
          id: index,
          start: moment(item.start).valueOf(),
          end: moment(item.end).valueOf(),
          categoryIndex: 0,
        };
      },
    );
    this.activityLogData.sort(
      (a: DailyProductionPerformanceActivityLogsInterface, b: DailyProductionPerformanceActivityLogsInterface) =>
        Number(a.start) - Number(b.start),
    );
  }

  private getChartFormattedActivityData(): DailyProductionPerformanceActivityLogsChartInterface[] {
    return this.activityLogData?.map((activity: DailyProductionPerformanceActivityLogsInterface) => {
      return {
        name: activity.activityName,
        workOrder: activity.workOrderNumber,
        task: activity.taskName,
        product: activity.productId,
        duration: activity.duration,
        startDate: activity.start,
        endDate: activity.end,
        value: [
          activity.categoryIndex,
          Number(activity.start),
          Number(activity.start) + Number(activity.end) - Number(activity.start),
          Number(activity.end) - Number(activity.start),
        ],
        itemStyle: {
          color:
            activity.activityTypeText === ActivityTypes.DOWN_TIME_PLANNED && activity.siteConfigurationCustomColor
              ? activity.siteConfigurationCustomColor
              : activityColors(activity.activityTypeText),
        },
      };
    });
  }

  private renderActivityChartItem(
    params: echarts.CustomSeriesRenderItemParams,
    api: echarts.CustomSeriesRenderItemAPI,
  ): echarts.CustomSeriesRenderItemReturn {
    const categoryIndex: string | number = api.value(0);
    const start: number[] = api.coord([api.value(1), categoryIndex]);
    const end: number[] = api.coord([api.value(2), categoryIndex]);
    const height: number = api.size([0, 1])[1] * 0.6;
    const rectShape: {
      x: number;
      y: number;
      width: number;
      height: number;
    } = echarts.graphic.clipRectByRect(
      {
        height,
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
      },
      {
        x: _.get(params.coordSys, 'x'),
        y: _.get(params.coordSys, 'y'),
        width: _.get(params.coordSys, 'width'),
        height: _.get(params.coordSys, 'height'),
      },
    );

    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: api.style(),
      }
    );
  }
  private getActivityChartCategoryData(): string[] {
    const emptyStringCount: number = this.activityOverlapCategoryCount ? this.activityOverlapCategoryCount - 1 : 0;
    return [this.translate.instant(this.activitiesKey), ...[...Array(emptyStringCount).keys()].map(() => '')];
  }

  private categorizeActivitiesByOverlap(): void {
    for (const outerActivity of this.activityLogData) {
      this.checkEachActivityForOverlap(outerActivity);
    }

    this.activitiesCategorized = _.cloneDeep(this.activityLogData);
    this.activityOverlapCategoryCount = _.uniq(
      this.activitiesCategorized.map(
        (activity: DailyProductionPerformanceActivityLogsInterface) => activity.categoryIndex,
      ),
    ).length;
  }

  private checkEachActivityForOverlap(outerActivity): void {
    for (const innerActivity of this.activityLogData) {
      if (outerActivity.id === innerActivity.id) {
        continue;
      }

      const outerActivityStart: number = Number(outerActivity.start);
      const innerActivityStart: number = Number(innerActivity.start);
      const innerActivityEnd: number = Number(innerActivity.end);
      outerActivity.categoryIndex = outerActivity.categoryIndex ?? 0;
      innerActivity.categoryIndex = innerActivity.categoryIndex ?? 0;

      if (
        ((outerActivityStart > innerActivityStart && outerActivityStart < innerActivityEnd) ||
          outerActivityStart === innerActivityStart) &&
        outerActivity.categoryIndex === innerActivity.categoryIndex
      ) {
        outerActivity.categoryIndex = outerActivity.categoryIndex + 1;
        this.checkEachActivityForOverlap(outerActivity);
        break;
      }
    }
  }
  private setMinimumAndMaximumDates(): void {
    let minimumDateInMs: number = moment().startOf('day').valueOf();
    let maximumDateInMs: number = moment().valueOf();
    this.activityLogData.forEach((activity: DailyProductionPerformanceActivityLogsInterface) => {
      const activityStart: number = Number(activity.start);
      const activityEnd: number = Number(activity.end);

      if (activityStart < minimumDateInMs) {
        minimumDateInMs = activityStart;
      }

      if (activityEnd > maximumDateInMs) {
        maximumDateInMs = activityEnd;
      }
    });
  }

  private getActivityLogsTooltip(items): string {
    return `<div><span class="tooltip-icon" style="background-color:${items.color};"> </span><strong>${
      items.data.name
    }</strong></div>
       <div><strong>${this.translate.instant(this.tooltipKeys.task)}: </strong>${items.data.task ?? '-'}</div>
      <div><strong>${this.translate.instant(this.tooltipKeys.startDate)}: </strong>${moment(
      items.data.startDate,
    ).format(mysqlTimestampFormat)}</div>
      <div><strong>${this.translate.instant(this.tooltipKeys.endDate)}: </strong>${moment(items.data.endDate).format(
      mysqlTimestampFormat,
    )}</div>
      <div><strong>${this.translate.instant(this.tooltipKeys.duration)}: </strong>${this.helperService.formatDuration(
      items.data.duration,
      true,
      true,
    )}</div>
      <div><strong>${this.translate.instant(this.tooltipKeys.product)}: </strong>${items.data.product ?? '-'}</div>
      <div><strong>${this.translate.instant(this.tooltipKeys.workOrder)}: </strong>${
      items.data.workOrder ?? '-'
    }</div>`;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { FilterCardComponent } from '../filter/filter-card/filter-card.component';
import {
  ActivityEventsDataInterface,
  IActivityHistoryBulkCreateExcelData,
  IActivityHistoryBulkCreateExcelErrorData,
  IActivityHistoryBulkCreateExcelUserData,
  IActivityHistoryTableData,
  IActivityHistoryWithLastComment,
  IActivityTaskPhase,
  ILineViewParams,
  IWorkOrderFilter,
  IWorkOrdersForExcelResponse,
} from '../../../store/activity-history/activity-history.model';
import {
  ACTIVITY_HISTORY_BULK_EDIT_ENTRY_VISIBLE_PROPERTY,
  ACTIVITY_HISTORY_SINGLE_EDIT_ENTRY_VISIBLE_PROPERTY,
  EActivityHistoryTableTypes,
  IActivityHistoryTableConfiguration,
  IShownFieldsForActivity,
} from '../../../view/activity-history/activity-history.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { ETableModalTypes } from '../../service/datatable/datatable.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivityEditModalDataInterface } from '../../../view/activity-review/activity-review.model';
import * as ActivityHistoryActions from '../../../store/activity-history/activity-history.actions';
import * as AppActions from '../../../store/app/actions';
import { ActivityHistoryService } from '../../service/activity-history/activity-history.service';
import * as _ from 'lodash';
import { ECloseDialogMessage } from '../../../view/home/work-order/work-order.model';
import { ofType } from '@ngrx/effects';
import { filter, map, take } from 'rxjs/operators';
import { ActivityHistoryState } from '../../../store/activity-history/activity-history.reducer';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { User } from '../../../store/user/model';
import { MainStateInterface } from '../../../store/main/main.model';
import { HelperService } from '../../service/helper.service';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import {
  EActivityHistoryTableCalledFrom,
  EErrorMessages,
  ELanguages,
  ESortType,
  smallModal,
} from '../../../../constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatatableHeaderInterface } from '../datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { IAdvancedFilterStore } from '../../../store/advanced-filter/advanced-filter.model';
import { IAdvancedFilterOutput, TargetEndpoints } from '../filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../filter/advanced-filter/advanced-filter.service';
import moment from 'moment/moment';
import { ToastHelperService } from '../../service/toast/toast.helper.service';
import { App } from '../../../store/app/model';
import {
  WorkOrderInterface,
  WorkOrderScheduleStateInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../filter/filter.class';
import {
  ECommentLogsCommentType,
  ECommentLogsObjectType,
  ICommentCountWithLastCommentMessage,
  ICommentCountWithLastCommentMessageRequestedObject,
} from '../../../store/reports/comment-logs/comment-logs.model';
import * as CommentLogsActions from '../../../store/reports/comment-logs/comment-logs.actions';
import { BorderColors } from '../scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { ActivityFormEnabledFields, ActivityHistoryBulkUpdateModel } from '../activity-form/activity-form.model';
import { SimplifiedDataInterface, TaskCRUDInterface } from '../../../store/settings/tasks/tasks.model';
import { PhaseOptionNames } from '../../model/enum/phase-options';
import { IActivitiesRequestParams } from '../../../view/settings/activities/activities.model';
import { ScwMatDropdownMenuItem } from '../scw-mat-ui/scw-mat-dropdown/scw-mat-dropdown.model';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { IEntityTranslation } from '../../service/entity-translator/entity-translator.model';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import { ESitesFields } from '../../../view/settings/sites/sites.model';
import { ExcelHelperService } from '../../service/excel/excel.helper.service';
import { ActivityHistoryActionTypes } from '../../../store/activity-history/activity-history.actions';

@Component({
  selector: 'app-activity-history-table',
  templateUrl: './activity-history-table.component.html',
  styleUrls: ['./activity-history-table.component.scss'],
})
export class ActivityHistoryTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('error_modal', { static: false }) errorModalComponent: ErrorModalComponent;
  @ViewChild('uploader') uploaderElementRef: ElementRef;
  @ViewChild(FilterCardComponent) filterCard: FilterCardComponent;
  @ViewChild('bulk_error_modal') bulkErrorModalTemplateRef: ViewChildDecorator;
  @Input() searchBoxText: string = '';
  @Input() hasMissingData: boolean = false;
  @Input() configuration: IActivityHistoryTableConfiguration = {
    workOrderId: null,
  };
  @Input() calledFrom: EActivityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom.ACTIVITY_HISTORY;
  @Input() reloadLineViewAndMissingDataEmitter: EventEmitter<EActivityHistoryTableTypes>;

  private readonly subscriptions: Subscription[] = [];
  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  private content: TemplateRef<any>;
  private siteId$: number;
  private lineId$: number;
  private lineName$: string;
  private activityHistoryEditModalRef: NgbModalRef;
  private activityHistoryDeleteModalRef: NgbModalRef;
  private editWorkOrderTemplateRef: NgbModalRef;
  private searchLineViewAndMissingDataTimeOut: NodeJS.Timeout;
  private lineViewAndMissingDataSearchDelay: number = 1000;
  public taskPhases: IActivityTaskPhase[] = [
    {
      id: PhaseOptionNames.preRun,
      name: this.translate.instant('activities.activityPlannedType.preRun'),
      alias: ESitesFields.preRunPhaseName,
    },
    {
      id: PhaseOptionNames.run,
      name: this.translate.instant('activities.activityPlannedType.run'),
      alias: ESitesFields.runPhaseName,
    },
    {
      id: PhaseOptionNames.postRun,
      name: this.translate.instant('activities.activityPlannedType.postRun'),
      alias: ESitesFields.postRunPhaseName,
    },
  ];
  public readonly ETableModalTypes: typeof ETableModalTypes = ETableModalTypes;
  public readonly activityHistoryTableCalledFromType: typeof EActivityHistoryTableCalledFrom =
    EActivityHistoryTableCalledFrom;
  public selectedHistoryItems: number[] = [];
  public modalType: ETableModalTypes;
  public selectedActivityData: ActivityEditModalDataInterface = {};
  public activityEditShownFields: IShownFieldsForActivity;
  public activityEditEnabledFields: Record<ActivityFormEnabledFields, boolean> = {
    line: true,
    site: true,
    workOrder: true,
  };
  public activityEventsMissingDataLoading$: boolean = false;
  public cancelButtonClicked: Subject<ECloseDialogMessage> = new Subject<ECloseDialogMessage>();
  public activityEventsDataLoading$: boolean = false;
  public errorModalType: string;
  public unsuccessfulActivityBulkOperationData: IActivityHistoryTableData[] = [];
  public bulkErrorModalSuccessfulCount: number = 0;
  public datetimeFormat$: string;
  public selectedActivityHistory: IActivityHistoryTableData[] = [];
  public isFirstTimeOpening: boolean = true;
  public initialLineViewAdvancedFilter: IAdvancedFilterOutput;
  public initialMissingDataAdvancedFilter: IAdvancedFilterOutput;
  public dateFormat$: string;
  public datetimeFormatWithSecond$: string;
  public language$: ELanguages;
  public dateTimeFormat$: string;
  public locale$: string;
  public userId$: string;
  public activityHistoryTableCalledFrom: typeof EActivityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom;

  public missingDataTableHeaders: DatatableHeaderInterface[] = [
    {
      value: null,
      name: '',
      sortable: false,
      width: '50px',
    },
    {
      value: 'workOrder.woNumber',
      name: this.translate.instant('activityHistory.form.workOrderNumber'),
      class: 'nowrap',
      tooltip: this.translate.instant('activityHistory.listView.headers.workOrderTooltip'),
    },
    {
      value: 'activity.name',
      name: this.translate.instant('activityHistory.listView.headers.activity'),
      class: 'nowrap',
    },
    {
      value: 'task.title',
      name: this.translate.instant('activityHistory.listView.headers.task'),
      class: 'nowrap',
      sortable: false,
    },
    {
      value: 'start',
      name: this.translate.instant('activityHistory.listView.headers.start'),
      class: 'nowrap',
    },
    {
      value: '',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds'),
      }),
      class: 'nowrap',
      sortable: false,
    },
  ];
  public missingDataTableData: IActivityHistoryWithLastComment[] = [];
  public missingDataCompletedWoCount: number = 0;
  public missingDataTableDataCount: number = 0;
  public missingDataTableCurrentPage: number = 1;
  public missingDataTableParams: ILineViewParams = {
    page: 1,
    pageSize: 25,
    search: '',
    advancedFilter: null,
    advancedFilterPage: null,
  };

  public lineViewTableHeaders: DatatableHeaderInterface[] = [
    {
      value: null,
      name: '',
      sortable: false,
      width: '50px',
    },
    {
      value: 'workOrder.woNumber',
      name: this.translate.instant('activityHistory.form.workOrderNumber'),
      tooltip: this.translate.instant('activityHistory.listView.headers.workOrderTooltip'),
    },
    {
      value: 'activity.name',
      name: this.translate.instant('activityHistory.listView.headers.activity'),
    },
    {
      value: 'task.title',
      name: this.translate.instant('activityHistory.listView.headers.task'),
      sortable: false,
    },
    {
      value: 'start',
      name: this.translate.instant('activityHistory.listView.headers.start'),
    },
    {
      value: '',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds'),
      }),
      sortable: false,
    },
  ];
  public lineViewTableData: IActivityHistoryWithLastComment[] = [];
  public lineViewCompletedWOCount: number = 0;
  public lineViewTableDataCount: number = 0;
  public lineViewTableCurrentPage: number = 1;
  public lineViewTableParams: ILineViewParams = {
    page: 1,
    pageSize: 25,
    search: '',
    advancedFilter: null,
    advancedFilterPage: null,
  };
  public sites: SiteCRUDInterface[] = null;
  public lines: LineCRUDInterface[] = null;
  public tableStyle: { [p: string]: string } = { tableLayout: 'fixed' };
  public selectedCompletedWorkOrders: IActivityHistoryTableData[] = [];
  private lineActivityTasksWithoutRunTime: IActivityHistoryBulkCreateExcelUserData[];
  private lineRunTimeActivities: IActivityHistoryBulkCreateExcelUserData[];
  private mergedLineActivityTasks: IActivityHistoryBulkCreateExcelUserData[];
  private exampleData: IActivityHistoryBulkCreateExcelData[] = [];
  private isReadExcel: boolean = false;
  private dataFromExcel: IActivityHistoryBulkCreateExcelData[] = [];
  private readonly excelMaxLengthCount: number = 500;
  private woNumbersFromExcel: string[] = [];
  private activityAPIData: ActivityHistoryBulkUpdateModel[] = [];
  private workOrderData: IWorkOrdersForExcelResponse[] = [];
  public dropdownMenuItems: ScwMatDropdownMenuItem[] = [
    {
      key: 'manually',
      text: this.translate.instant('general.excel.dropdownElement.manually'),
    },
    {
      key: 'bulk-upload',
      text: this.translate.instant('general.excel.dropdownElement.bulkUpload'),
    },
  ];
  private requestParams: IActivitiesRequestParams = {
    page: 1,
    pageSize: 100,
    sort: {
      column: 'id',
      type: 'descending',
    },
    search: '',
    selectedActivityTypes: -1,
    selectedStatus: -1,
  };
  private readonly fileType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(
    private readonly activityHistoryDeleteModal: NgbModal,
    private readonly activityHistoryBulkErrorModal: NgbModal,
    private readonly activityEditModalNg: NgbModal,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly checkBoxElementRef: ElementRef,
    private readonly toastHelperService: ToastHelperService,
    private readonly excelHelperService: ExcelHelperService,
    private store: Store<OeeAppState>,
    private homeActions: ActionsSubject,
    public translate: TranslateService,
    public helperService: HelperService,
  ) {}

  public ngOnInit(): void {
    this.reloadLineViewOrMissingData(
      this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
    );

    if (this.calledFrom === EActivityHistoryTableCalledFrom.ACTIVITY_HISTORY && !this.hasMissingData) {
      this.reloadLineViewOrMissingData(EActivityHistoryTableTypes.MISSING_DATA, true);
    }

    this.subscriptions.push(
      this.cancelButtonClicked.subscribe((cancelValue: ECloseDialogMessage): void => {
        if (cancelValue === ECloseDialogMessage.CLOSE_DIALOG) {
          this.editWorkOrderTemplateRef.close();
        }
      }),

      this.reloadLineViewAndMissingDataEmitter.subscribe((tableToReload: EActivityHistoryTableTypes): void => {
        this.reloadLineViewOrMissingData(tableToReload);
      }),

      this.store.select('user').subscribe((state: User): void => {
        this.datetimeFormat$ = state.dateTimeFormat;
        this.dateFormat$ = state.dateFormat;
        this.datetimeFormatWithSecond$ = state.dateTimeFormatWithSecond;
        this.lineId$ = state.lineId;
        this.lineName$ = state.lineName;
        this.siteId$ = state.siteId;
        this.language$ = state.language as ELanguages;
        this.dateTimeFormat$ = state.dateTimeFormat;
        this.locale$ = state.locale;
        this.userId$ = state.userId;
      }),

      this.store.select('app').subscribe((state: App): void => {
        if (state.activityListUpdate) {
          this.store.dispatch(new AppActions.SetActivityListUpdate(false));
          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }
      }),

      combineLatest([
        this.store.select('user').pipe(filter((value: User) => value.isUserLoaded)),
        this.store.select('mainStore').pipe(filter((value: MainStateInterface) => value.getActiveSitesLoaded)),
        this.store.select('activityHistoryStore'),
      ]).subscribe(([user, main, state]: [User, MainStateInterface, ActivityHistoryState]): void => {
        this.activityEventsMissingDataLoading$ = state.activityEventsMissingDataLoading;
        this.activityEventsDataLoading$ = state.activityEventsDataLoading;

        if (!state.activityEventsDataLoading && state.activityEventsDataLoaded) {
          this.lineViewTableData = HelperService.cloneDeep(state.activityEvents).map(
            (item: ActivityEventsDataInterface) => ({
              ...item,
              batchNumber: _.get(item, 'workOrder.woNumber', null),
              isMissingData: ActivityHistoryTableComponent.isMissingData(item),
              start: this.helperService.convertFromISOFormatToGivenTimezone(item.start),
              end: this.helperService.convertFromISOFormatToGivenTimezone(item.end),
              duration: ActivityHistoryTableComponent.getFormattedDuration(item.start, item.end),
              taskName: ActivityHistoryTableComponent.getActivityTaskName(item),
              withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
              activityName: item?.activity?.name,
              activityColor: this.getActivityColor(item.activity.activityType),
              isLine: 0,
            }),
          );

          this.lineViewCompletedWOCount = HelperService.cloneDeep(state.activityEvents).filter(
            (item: ActivityEventsDataInterface) => item.workOrder !== null && item.workOrder.completed,
          ).length;
          this.lineViewTableDataCount = state.activityEventsTotalResults;
          this.lineViewTableCurrentPage = state.activityEventsCurrentPage;

          this.store.dispatch(new AppActions.HideLoader());
          this.selectedActivityData = HelperService.cloneDeep(state.selectedActivityData);
        }

        if (!state.activityEventsMissingDataLoading && state.activityEventsMissingDataLoaded) {
          this.missingDataTableData = HelperService.cloneDeep(state.activityEventsMissingData).map(
            (item: ActivityEventsDataInterface) => ({
              ...item,
              batchNumber: _.get(item, 'workOrder.woNumber', null),
              isMissingData: ActivityHistoryTableComponent.isMissingData(item),
              start: this.helperService.convertFromISOFormatToGivenTimezone(item?.start),
              duration: ActivityHistoryTableComponent.getFormattedDuration(item.start, item.end),
              taskName: ActivityHistoryTableComponent.getActivityTaskName(item),
              withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
              activityName: item?.activity?.name,
              activityColor: this.getActivityColor(item.activity.activityType),
              isLine: 0,
            }),
          );
          this.missingDataCompletedWoCount = HelperService.cloneDeep(state.activityEventsMissingData).filter(
            (item: ActivityEventsDataInterface) => item.workOrder !== null && item.workOrder.completed,
          ).length;
          this.missingDataTableDataCount = state.activityEventsMissingDataTotalResults;
          this.missingDataTableCurrentPage = state.activityEventsMissingDataCurrentPage;
        }

        if (!state.selectedActivityDataLoading && state.selectedActivityDataLoaded) {
          this.selectedActivityData = HelperService.cloneDeep(state.selectedActivityData);
          this.selectedActivityData = {
            ...this.selectedActivityData,
            start: this.helperService.convertFromISOFormatToGivenTimezone(this.selectedActivityData.start),
            end: this.helperService.convertFromISOFormatToGivenTimezone(this.selectedActivityData.end)
          }
          this.activityHistoryEditModalRef = this.activityEditModalNg.open(this.content, {
            windowClass: 'scw-modal-xl scw-modal-all-scrollable',
            keyboard: false,
            backdrop: 'static',
          });
        }
      }),

      this.homeActions
        .pipe(
          ofType(
            ActivityHistoryActions.ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted,
            ActivityHistoryActions.ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted,
          ),
        )
        .subscribe((): void => {
          this.selectedHistoryItems = [];

          this.toastHelperService.showToastMessage(
            true,
            this.translate.instant(this.successTitleKey),
            this.translate.instant(this.successMessageKey),
          );

          if (this.activityHistoryDeleteModalRef || this.activityHistoryEditModalRef) {
            this.activityHistoryDeleteModalRef?.close();
            this.activityHistoryEditModalRef?.close();
          }
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted))
        .subscribe((res: ActivityHistoryActions.SaveOrUpdateBulkActivityEventDataCompleted): void => {
          this.bulkErrorModalSuccessfulCount = 0;
          this.unsuccessfulActivityBulkOperationData = [];

          for (const [index, result] of Object.entries(res.response.data)) {
            if (result.success) {
              this.bulkErrorModalSuccessfulCount += 1;
            } else {
              this.unsuccessfulActivityBulkOperationData.push(
                _.find(this.lineViewTableData, { id: this.selectedHistoryItems[index] }),
              );
            }
          }

          this.selectedHistoryItems = [];

          if (this.unsuccessfulActivityBulkOperationData.length) {
            this.errorModalType = ETableModalTypes.bulkEdit;

            this.activityHistoryEditModalRef.close();
            this.showBulkErrorModal(this.bulkErrorModalTemplateRef);
          } else {
            this.activityHistoryEditModalRef.close();
            this.toastHelperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          }

          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.DeleteManyActivityHistoryDataCompleted))
        .subscribe((response: ActivityHistoryActions.DeleteManyActivityHistoryDataCompleted): void => {
          this.selectedHistoryItems = [];
          this.unsuccessfulActivityBulkOperationData = [];
          this.bulkErrorModalSuccessfulCount = 0;

          for (const [index, result] of Object.entries(response.response.data)) {
            if (result.success) {
              this.bulkErrorModalSuccessfulCount += 1;
            } else {
              this.unsuccessfulActivityBulkOperationData.push(
                _.find(this.lineViewTableData, { id: this.selectedHistoryItems[index] }),
              );
            }
          }

          if (this.unsuccessfulActivityBulkOperationData.length) {
            this.errorModalType = ETableModalTypes.bulkDelete;
            this.activityHistoryDeleteModalRef.close();
            this.showBulkErrorModal(this.bulkErrorModalTemplateRef);
          } else {
            this.activityHistoryDeleteModalRef.close();

            this.toastHelperService.showToastMessage(
              true,
              this.translate.instant(this.successTitleKey),
              this.translate.instant(this.successMessageKey),
            );
          }

          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }),

      this.homeActions
        .pipe(
          ofType(
            ActivityHistoryActions.ActivityHistoryActionTypes.GetActivityEventMissingDataLoaded,
            ActivityHistoryActions.ActivityHistoryActionTypes.GetActivityEventsLoaded,
          ),
        )
        .subscribe((): void => {
          this.selectedHistoryItems = [];

          if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
            this.fetchActivityHistoryCommentCounts();
          }
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.FetchError))
        .subscribe((response: ActivityHistoryActions.FetchError): void => {
          if (response.actionType === ActivityHistoryActionTypes.SaveBulkActivityHistoryCompleted) {
            this.handleBulkActivityHistoryError(response);
          } else {
            this.selectedHistoryItems = [];
            this.selectOrUnselectAll(false);
            this.activityHistoryDeleteModalRef?.close();
          }
        }),
      this.homeActions
        .pipe(ofType(CommentLogsActions.COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADED))
        .subscribe((response: CommentLogsActions.CommentLogsCountWithLastMessageDataLoaded) =>
          this.mapActivityCommentsWithLastMessageToActivityLogs(response.payload),
        ),
      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.GetSiteLineDataLoaded))
        .subscribe((response: ActivityHistoryActions.GetSiteLineDataLoaded): void => {
          const lineActivityIds: string = HelperService.cloneDeep(response.lineData?.activityIds);
          const activityParams: IActivitiesRequestParams = {
            ...this.requestParams,
            search: lineActivityIds,
          };
          this.taskPhases.forEach((phase) => {
            switch (phase.alias) {
              case ESitesFields.preRunPhaseName:
                phase.name = response.siteData.preRunPhaseName || phase.name;
                break;
              case ESitesFields.runPhaseName:
                phase.name = response.siteData.runPhaseName || phase.name;
                break;
              case ESitesFields.postRunPhaseName:
                phase.name = response.siteData.postRunPhaseName || phase.name;
                break;
            }
          });
          this.store.dispatch(
            new ActivityHistoryActions.GetLineActivityTaskDataLoading(
              activityParams,
              this.siteId$,
              this.lineId$,
              lineActivityIds,
            ),
          );
        }),
      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.GetLineActivityTaskDataLoaded))
        .subscribe((response: ActivityHistoryActions.GetLineActivityTaskDataLoaded): void => {
          this.lineActivityTasksWithoutRunTime = this.processLineActivities(response.tasks, this.language$);
          this.lineRunTimeActivities = this.processRunActivities(response.activities);
          this.mergedLineActivityTasks = [
            ...new Set([...this.lineRunTimeActivities, ...this.lineActivityTasksWithoutRunTime]),
          ];
          this.exampleData = [
            {
              startDate: moment().format(this.datetimeFormat$),
              endDate: moment().format(this.datetimeFormat$),
              activityName: this.translate.instant('activityHistory.excel.exampleData.activity.runTime'),
              taskName: '',
              equipmentName: '',
              phaseName: this.translate.instant('activityHistory.excel.exampleData.phase.run'),
              workOrder: `${this.translate.instant('activityHistory.excel.exampleData.workOrder')}-1`,
            },
            {
              startDate: moment().format(this.datetimeFormat$),
              endDate: moment().format(this.datetimeFormat$),
              activityName: `${this.translate.instant('activityHistory.excel.exampleData.activity.downTime')}-1`,
              taskName: `${this.translate.instant('activityHistory.excel.exampleData.task')}-1`,
              equipmentName: '',
              phaseName: this.translate.instant('activityHistory.excel.exampleData.phase.postRun'),
              workOrder: `${this.translate.instant('activityHistory.excel.exampleData.workOrder')}-2`,
            },
            {
              startDate: moment().format(this.datetimeFormat$),
              endDate: moment().format(this.datetimeFormat$),
              activityName: `${this.translate.instant('activityHistory.excel.exampleData.activity.downTime')}-2`,
              taskName: `${this.translate.instant('activityHistory.excel.exampleData.task')}-2`,
              equipmentName: this.translate.instant('activityHistory.excel.exampleData.equipment'),
              phaseName: this.translate.instant('activityHistory.excel.exampleData.phase.preRun'),
              workOrder: `${this.translate.instant('activityHistory.excel.exampleData.workOrder')}-3`,
            },
          ];

          if (this.isReadExcel) {
            this.woNumbersFromExcel = [];
            this.activityAPIData = [];
            this.dataFromExcel.forEach((excel: IActivityHistoryBulkCreateExcelData) => {
              const matchedItem: IActivityHistoryBulkCreateExcelUserData = this.mergedLineActivityTasks.find(
                (item: IActivityHistoryBulkCreateExcelUserData) =>
                  item.activityName === excel.activityName &&
                  item.taskName === excel.taskName &&
                  item.equipmentName === excel.equipmentName,
              );
              const matchedPhase: SimplifiedDataInterface = this.taskPhases.find(
                (phase: SimplifiedDataInterface) => phase.name === excel.phaseName,
              );

              this.activityAPIData.push({
                siteId: this.siteId$,
                lineId: this.lineId$,
                start: this.getDateWithFormat(excel.startDate),
                end: this.getDateWithFormat(excel.endDate),
                activityId: matchedItem?.activityId,
                taskId: matchedItem?.activityType !== ActivityTypes.RUN_TIME ? matchedItem?.taskId : null,
                userId: Number(this.userId$),
                phaseId: matchedPhase?.id ?? null,
                woNumber: excel.workOrder,
              });
              excel.activityId = matchedItem?.activityId;
              excel.taskId = matchedItem?.activityType !== ActivityTypes.RUN_TIME ? matchedItem?.taskId : null;
              excel.activityType = matchedItem?.activityType;

              if (!this.woNumbersFromExcel.some((woNumber: string) => woNumber === excel.workOrder)) {
                this.woNumbersFromExcel.push(excel.workOrder);
              }
            });
            this.store.dispatch(
              new ActivityHistoryActions.GetWorkOrdersForExcelLoading({
                siteId: this.siteId$,
                woNumbers: this.woNumbersFromExcel,
              }),
            );
          } else {
            this.store.dispatch(
              new ActivityHistoryActions.DownloadActivityHistoryExcel(
                this.lineName$,
                [],
                this.mergedLineActivityTasks,
                this.exampleData,
              ),
            );
          }
        }),
      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.GetWorkOrdersForExcelLoaded))
        .subscribe((response: ActivityHistoryActions.GetWorkOrdersForExcelLoaded) => {
          this.workOrderData = HelperService.cloneDeep(response.payload.data);
          let anyError: boolean = false;
          this.activityAPIData.forEach((data: ActivityHistoryBulkUpdateModel, index: number) => {
            const hasError: boolean = this.validateActivityData(data, this.dataFromExcel[index], this.workOrderData);
            delete data.woNumber;

            if (!anyError) {
              anyError = hasError;
            }
          });

          if (!anyError) {
            this.store.dispatch(
              new ActivityHistoryActions.SaveBulkActivityHistory({
                activityHistories: this.activityAPIData,
              }),
            );
            this.isReadExcel = false;
          } else {
            this.dataFromExcel.forEach((value: IActivityHistoryBulkCreateExcelData) => {
              if (!value.errorMessages) {
                value.errorMessages = this.translate.instant('activityHistory.excel.sheet.error.apiError');
              }
            });
            const propertyName: string = this.translate.instant('toast.message.property.activityHistory');
            this.handleErrorScenario(
              this.dataFromExcel,
              this.activityAPIData.length,
              this.activityAPIData.length,
              propertyName,
              true,
            );
            this.isReadExcel = false;
            this.store.dispatch(new AppActions.HideLoader());
          }
        }),
      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.SaveBulkActivityHistoryCompleted))
        .subscribe((response: ActivityHistoryActions.SaveBulkActivityHistoryCompleted) => {
          this.store.dispatch(new AppActions.ShowLoader());
          const excelResponse: IActivityHistoryBulkCreateExcelErrorData[] = HelperService.cloneDeep(response.payload);
          const formattedExcelResponse: IActivityHistoryBulkCreateExcelErrorData[] =
            this.formatExcelResponse(excelResponse);
          const totalCount: number = this.activityAPIData.length;
          const failCount: number = excelResponse.filter((res) => res.errorMessages !== '')?.length;
          const propertyName: string = this.translate.instant('toast.message.property.activityHistory');
          this.excelHelperService.logExcelUploadOperation(propertyName, this.activityAPIData, excelResponse);

          if (failCount === 0) {
            this.showSuccessToast(totalCount, propertyName);
          } else {
            this.handleErrorScenario(formattedExcelResponse, totalCount, failCount, propertyName);
          }

          this.store.dispatch(new AppActions.HideLoader());
        }),
    );

    if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.subscriptions.push(
        this.store.select('workOrderSchedule').subscribe((state: WorkOrderScheduleStateInterface): void => {
          if (!state.getSitesLoading && state.getSitesLoaded) {
            this.sites = state.sites.data;
          }

          if (!state.getLinesLoading && state.getLinesLoaded) {
            this.lines = state.lines.data;
          }
        }),
      );
    }
  }

  private validateActivityData(
    data: ActivityHistoryBulkUpdateModel,
    excelRow: IActivityHistoryBulkCreateExcelData,
    workOrderData: IWorkOrdersForExcelResponse[],
  ): boolean {
    let anyError: boolean = false;
    excelRow.start = excelRow.startDate;
    excelRow.end = excelRow.endDate;
    const isStartDateValid: boolean = moment(data.start, mysqlTimestampFormat, true).isValid();
    const isEndDateValid: boolean = moment(data.end, mysqlTimestampFormat, true).isValid();

    data.workOrderId = workOrderData.find((wo) => wo.woNumber === data.woNumber)?.id ?? null;

    const errors: string[] = [];

    if (!data.start || data.start === null) {
      errors.push(
        this.translate.instant('apiErrorMessages.isNotEmpty', {
          property: this.translate.instant('activityHistory.excel.startDate.header'),
        }),
      );
    }

    if (!data.end || data.end === null) {
      errors.push(
        this.translate.instant('apiErrorMessages.isNotEmpty', {
          property: this.translate.instant('activityHistory.excel.endDate.header'),
        }),
      );
    }

    if (!isStartDateValid) {
      errors.push(
        this.translate.instant('filterCard.messages.invalidInput', {
          fields: this.translate.instant('activityHistory.excel.startDate.header'),
        }),
      );
    }

    if (!isEndDateValid) {
      errors.push(
        this.translate.instant('filterCard.messages.invalidInput', {
          fields: this.translate.instant('activityHistory.excel.endDate.header'),
        }),
      );
    }

    if (isStartDateValid && isEndDateValid && data.start === data.end) {
      errors.push(this.translate.instant('activityHistory.form.activityStartEndCannotBeSame'));
    }

    if (!data.activityId && !excelRow.activityName) {
      errors.push(
        this.translate.instant('apiErrorMessages.isNotEmpty', {
          property: this.translate.instant('activityHistory.excel.activityName.header'),
        }),
      );
    }

    if (
      (!data.activityId && excelRow.activityName) ||
      (!data.taskId && excelRow.taskName && excelRow.activityType !== ActivityTypes.RUN_TIME)
    ) {
      errors.push(this.translate.instant('activityHistory.excel.sheet.error.activityTaskEquipmentDoNotMatch'));
    }

    if (!data.taskId && !excelRow.taskName && excelRow.activityType !== ActivityTypes.RUN_TIME) {
      errors.push(
        this.translate.instant('apiErrorMessages.isNotEmpty', {
          property: this.translate.instant('activityHistory.excel.taskName.header'),
        }),
      );
    }

    if (!data.workOrderId && data.woNumber) {
      errors.push(this.translate.instant('activityHistory.excel.sheet.error.workOrderNotFound'));
    }

    if (
      !data.workOrderId &&
      !data.woNumber &&
      excelRow.activityType !== ActivityTypes.IDLE_TIME &&
      !excelRow.withoutWorkOrder
    ) {
      errors.push(
        this.translate.instant('apiErrorMessages.isNotEmpty', {
          property: this.translate.instant('activityHistory.excel.workOrder.header'),
        }),
      );
    }

    if (data.phaseId === null && excelRow.phaseName !== null) {
      errors.push(this.translate.instant('activityHistory.excel.sheet.error.phaseDoNotMatch'));
    }

    if (errors.length > 0) {
      excelRow.errorMessages = errors.join(', ');
      anyError = true;
    }

    return anyError;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('configuration') && _.get(changes, 'configuration.currentValue', null) !== null) {
      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.lineViewTableHeaders.splice(
          1,
          1,
          ...[
            {
              value: 'workOrder.woNumber',
              name: this.translate.instant('activityHistory.form.workOrderNumber'),
            },
            {
              value: 'line.title',
              name: this.translate.instant('general.line'),
            },
          ],
        );

        this.lineViewTableHeaders.push(
          {
            value: 'activityComment',
            name: this.translate.instant('activityLogs.tableHeaders.activityComments'),
            sortable: false,
          },
          {
            value: 'editAction',
            name: '',
            sortable: false,
          },
        );

        this.missingDataTableHeaders = this.lineViewTableHeaders;
      }

      this.lineViewTableParams.pageSize = 10;
    }

    if (changes.hasOwnProperty('searchBoxText')) {
      clearTimeout(this.searchLineViewAndMissingDataTimeOut);
      const searchBoxText: string = _.get(changes, 'searchBoxText.currentValue', '');
      const isFirstChange: boolean = !!_.get(changes, 'searchBoxText.firstChange', null);

      if (searchBoxText.trim() === '' && isFirstChange) {
        return;
      }

      this.searchLineViewAndMissingDataTimeOut = setTimeout(() => {
        this.lineViewTableParams = { ...this.lineViewTableParams, search: searchBoxText.trim().toLowerCase() };
        this.missingDataTableParams = { ...this.missingDataTableParams, search: searchBoxText.trim().toLowerCase() };

        this.reloadLineViewOrMissingData(
          this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
        );
      }, this.lineViewAndMissingDataSearchDelay);
    }
  }

  public onDownloadTemplateClick(): void {
    this.store.dispatch(new ActivityHistoryActions.GetSiteLineDataLoading(this.siteId$, this.lineId$));
  }

  public dropDownClick(key: string, templateRef: TemplateRef<any>): void {
    switch (key) {
      case 'manually':
        this.activityEditModal([], templateRef, ETableModalTypes.add);
        break;
      case 'bulk-upload':
        this.uploaderElementRef.nativeElement.click();
        break;
    }
  }

  public async readExcel($event): Promise<void> {
    this.store.dispatch(new AppActions.ShowLoader());

    const target: DataTransfer = $event.target as DataTransfer;
    const file: File = _.first(target.files);
    $event.srcElement.value = null;

    if (file.type !== this.fileType) {
      this.errorModalComponent.showError(this.translate.instant(EErrorMessages.FILE_TYPE_ERROR));
      return;
    }

    this.isReadExcel = true;
    this.dataFromExcel = await this.activityHistoryService.getActivityHistoryFromExcel(file);

    if (!this.dataFromExcel) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.error'),
        this.translate.instant('activityHistory.excel.sheet.error.wrongExcelTemplate'),
      );
      this.store.dispatch(new AppActions.HideLoader());
      this.isReadExcel = false;
    } else if (this.dataFromExcel?.length > this.excelMaxLengthCount) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.error'),
        this.translate.instant('activityHistory.excel.sheet.error.limit', {
          limit: this.excelMaxLengthCount,
        }),
      );
      this.store.dispatch(new AppActions.HideLoader());
    } else if (this.dataFromExcel?.length === 0) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.error'),
        this.translate.instant('excel.messages.fileEmptyError'),
      );
      this.store.dispatch(new AppActions.HideLoader());
    } else {
      this.store.dispatch(new ActivityHistoryActions.GetSiteLineDataLoading(this.siteId$, this.lineId$));
    }
  }

  public activityEditModal(activityHistoryIds: number[], content: TemplateRef<any>, type: string): void {
    this.content = content;
    const isCalledFromWorkOrders: boolean = this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS;
    this.activityEditShownFields = ACTIVITY_HISTORY_SINGLE_EDIT_ENTRY_VISIBLE_PROPERTY;

    this.activityEditEnabledFields = {
      workOrder: !isCalledFromWorkOrders,
      line: !isCalledFromWorkOrders,
      site: !isCalledFromWorkOrders,
    };

    this.activityHistoryService.timeEntryModalWorkOrdersSubject.next(null);

    this.store.dispatch(new ActivityHistoryActions.FlushActivityEventData());

    if (type === ETableModalTypes.add) {
      this.modalType = ETableModalTypes.add;
      this.selectedActivityData = null;

      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.store
          .select('workOrderSchedule')
          .pipe(take(1))
          .subscribe((state: WorkOrderScheduleStateInterface) => {
            const selectedWorkOrder: WorkOrderInterface = this.getSelectedWorkOrder(state.workOrderScheduleList);
            this.activityHistoryService.timeEntryModalWorkOrdersSubject.next({
              workOrder: {
                ...selectedWorkOrder,
                quantityOrdered: Number(selectedWorkOrder.quantityOrdered),
                canceled: Number(selectedWorkOrder.canceled),
              },
              lineId: selectedWorkOrder?.lastLine ?? this.configuration?.workOrder?.lineId,
              siteId: selectedWorkOrder.siteId,
              batchNumber: selectedWorkOrder.woNumber,
            });
          });
      }

      this.activityHistoryEditModalRef = this.activityEditModalNg.open(content, {
        windowClass: 'scw-modal-xl scw-modal-all-scrollable',
        keyboard: false,
        backdrop: 'static',
      });
    } else if (type === ETableModalTypes.edit) {
      this.modalType = ETableModalTypes.edit;

      if (activityHistoryIds.length === 1) {
        const selectedActivityHistoryId: number = activityHistoryIds[0];

        this.store.dispatch(new AppActions.ShowLoader());
        this.store.dispatch(new ActivityHistoryActions.GetActivityEventData(selectedActivityHistoryId));

        if (this.hasMissingData) {
          this.selectedActivityData = _.find(this.missingDataTableData, { id: selectedActivityHistoryId });
        } else {
          this.selectedActivityData = _.find(this.lineViewTableData, { id: selectedActivityHistoryId });
        }

        return;
      }
      this.modalType = ETableModalTypes.bulkEdit;
      const [woMissingData, activityWithoutWo, taskMissingData] =
        this.activityHistoryService.checkMissingDataConditions(
          activityHistoryIds,
          this.hasMissingData ? this.missingDataTableData : this.lineViewTableData,
        );
      const { lineId, siteId }: { lineId: number; siteId: number } = (_.find(this.lineViewTableData, {
        workOrderId: this.configuration.workOrderId,
      }) as IActivityHistoryTableData) ?? { lineId: this.lineId$, siteId: this.siteId$ };
      this.selectedActivityData = {
        lineId,
        siteId,
        bulkEdit: {
          woMissingData,
          taskMissingData,
          activityWithoutWo,
          selectedBulkActivities: this.selectedHistoryItems,
        },
      };

      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.store
          .select('workOrderSchedule')
          .pipe(take(1))
          .subscribe((state: WorkOrderScheduleStateInterface) => {
            const selectedWorkOrder: WorkOrderInterface = this.getSelectedWorkOrder(state.workOrderScheduleList);

            this.selectedActivityData.workOrder = {
              id: selectedWorkOrder.id,
              woNumber: selectedWorkOrder.woNumber,
              quantityOrdered: Number(selectedWorkOrder.quantityOrdered),
              product: {
                id: _.get(selectedWorkOrder, 'productTableId', null),
                productId: _.get(selectedWorkOrder, 'productId', null),
                description: _.get(selectedWorkOrder, 'productDescription', null),
              },
            };
          });
      }

      this.activityEditShownFields = {
        ...ACTIVITY_HISTORY_BULK_EDIT_ENTRY_VISIBLE_PROPERTY,
        workOrderNumber: this.calledFrom !== EActivityHistoryTableCalledFrom.WORK_ORDERS,
      };
      this.activityHistoryEditModalRef = this.activityEditModalNg.open(content, {
        windowClass: 'scw-modal-xl scw-modal-all-scrollable',
        keyboard: false,
        backdrop: 'static',
      });
    }
  }

  private getSelectedWorkOrder(workOrderScheduleList: WorkOrderInterface[]): WorkOrderInterface {
    const selectedWorkOrder: WorkOrderInterface = HelperService.cloneDeep(
      _.find(workOrderScheduleList, {
        id: this.configuration.workOrderId,
      }),
    );

    if (selectedWorkOrder) {
      Object.assign(selectedWorkOrder, {
        product: {
          id: _.get(selectedWorkOrder, 'productTableId', null),
          productId: _.get(selectedWorkOrder, 'productId', null),
          description: _.get(selectedWorkOrder, 'productDescription', null),
        },
      });
    }

    if (!selectedWorkOrder) {
      return this.configuration.workOrder as unknown as WorkOrderInterface;
    }

    return selectedWorkOrder;
  }

  public cancelClicked(eventValue: boolean): void {
    if (!eventValue) {
      return;
    }

    this.activityHistoryEditModalRef.close();

    if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.reloadLineViewOrMissingData(EActivityHistoryTableTypes.LINE_VIEW);
      this.selectOrUnselectAll(false);
    }
  }

  public deleteActivityHistoryModal(content: TemplateRef<any>, isBulk: boolean): void {
    this.modalType = isBulk ? ETableModalTypes.bulkDelete : ETableModalTypes.delete;
    this.activityHistoryDeleteModalRef = this.activityHistoryDeleteModal.open(content, smallModal);
  }

  public deleteSelectedActivityHistoryData(): void {
    if (this.selectedHistoryItems.length === 1) {
      this.store.dispatch(
        new ActivityHistoryActions.SaveUpdateOrDeleteActivityEventData(
          ...HelperService.argumentClone(null, this.selectedHistoryItems[0]),
        ),
      );

      return;
    }

    this.store.dispatch(
      new ActivityHistoryActions.DeleteManyActivityHistoryData(
        ...HelperService.argumentClone(this.selectedHistoryItems),
      ),
    );
  }

  public selectOrUnselectAll(isSelectAll: boolean): void {
    if (this.checkBoxElementRef.nativeElement.querySelector('[id^="line-view-checkbox-"]') === null) {
      return;
    }

    (this.hasMissingData ? this.missingDataTableData : this.lineViewTableData).forEach(
      (item: IActivityHistoryTableData): void => {
        const checkbox = _.head(document.getElementById(`line-view-checkbox-${item.id}`).getElementsByTagName('input'));

        if ((isSelectAll && !checkbox.checked) || (!isSelectAll && checkbox.checked)) {
          checkbox.click();
        }
      },
    );
  }

  public onActivityHistoryCheckboxChange(event: MatCheckboxChange): void {
    const inputValue: number = Number(event.source.value);

    if (event.checked) {
      this.selectedHistoryItems.push(inputValue);
    } else {
      const index: number = this.selectedHistoryItems.indexOf(inputValue);

      this.selectedHistoryItems.splice(index, 1);
    }

    this.findSelectedCompletedWorkOrders(this.selectedHistoryItems);

    this.selectedActivityHistory = _.head(!this.hasMissingData ? this.lineViewTableData : this.missingDataTableData);
  }

  public findSelectedCompletedWorkOrders(workOrderIds: number[]): void {
    this.selectedCompletedWorkOrders = [];
    let workOrders: IActivityHistoryTableData[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    this.selectedCompletedWorkOrders = workOrders.filter(
      (workOrder: IActivityHistoryTableData) =>
        workOrderIds.includes(workOrder?.id) && workOrder?.workOrder?.completed === 1,
    );
  }

  public onDataRequestHandler(params, table): void {
    let query: IWorkOrderFilter = {
      page: 0,
      pageSize: 0,
      search: '',
      advancedFilter: null,
      advancedFilterPage: null,
    };

    this.store
      .select('activityHistoryStore')
      .pipe(take(1))
      .subscribe((state) => {
        query.advancedFilter = _.get(state.filters, 'advancedFilter', null);
      });

    if (params.advancedFilter) {
      query.advancedFilter = params.advancedFilter;
    }

    if (params.page) {
      query.page = params.page;
    }

    if (params.pageSize || params.rowsPerPage) {
      query.pageSize = params.pageSize || Number(params.rowsPerPage);
    }

    if (this.searchBoxText) {
      query.search = this.searchBoxText;
    }

    if (params.sort && params.sort.type !== ESortType.NONE) {
      query.orderBy = params.sort.column;

      if (params.sort.type === ESortType.ASCENDING) {
        query.orderDesc = '';
      }

      if (params.sort.type === ESortType.DESCENDING) {
        query.orderDesc = 1;
      }
    }

    if (params.orderBy) {
      query.orderBy = params.orderBy;
      query.orderDesc = params.orderDesc;
    }

    switch (table) {
      case EActivityHistoryTableTypes.LINE_VIEW:
        this.lineViewTableParams = query;
        break;
      case EActivityHistoryTableTypes.MISSING_DATA:
        this.missingDataTableParams = query;
        break;
    }

    this.reloadLineViewOrMissingData(table);
  }

  public onClickRow($event): void {
    const { event, item } = $event;

    const index: number = Array.from(event.target.parentElement.children).indexOf(event.target);

    if (
      index !== 0 ||
      event.target.classList.contains('mat-checkbox-inner-container') ||
      event.target.tagName !== 'TD'
    ) {
      return;
    }

    _.head(document.getElementById(`line-view-checkbox-${item.id}`).getElementsByTagName('input')).click();
  }

  public onLastCommentModalClosed(): void {
    this.selectedHistoryItems = [];

    this.reloadLineViewOrMissingData(
      this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
    );
  }

  private static isMissingData(item: ActivityEventsDataInterface): boolean {
    return (
      (item.activity.activityType !== 'runTime' &&
        (item.taskId === null || item.taskId === '' || item.task.isMissingData === 1)) ||
      item.activityId === null ||
      item.activityId === '' ||
      item.start === null ||
      item.start === '' ||
      item.end === null ||
      item.end === '' ||
      (item.activity.activityType !== 'idleTime' && item.workOrderId === null)
    );
  }

  private static getActivityTaskName(item: ActivityEventsDataInterface): string {
    if (item.activity.activityType === 'runTime') {
      return _.get(item, 'workOrder.product.description', '');
    }

    return _.get(item, 'task.title', '');
  }

  private static getFormattedDuration(startDate: string, endDate: string): string {
    const start: moment.Moment = moment(startDate, mysqlTimestampFormat);
    const end: moment.Moment = moment(endDate, mysqlTimestampFormat);
    const duration: moment.Duration = moment.duration(end.diff(start));

    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:ss');
  }

  public showBulkErrorModal(templateRef: ViewChildDecorator): void {
    this.activityHistoryDeleteModalRef = this.activityHistoryBulkErrorModal.open(templateRef, smallModal);
  }

  private reloadLineViewOrMissingData(
    tableToReload: EActivityHistoryTableTypes = null,
    getCountOnly: boolean = false,
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.isFirstTimeOpening && this.calledFrom !== EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.performFirstTimeOperations(tableToReload);

      this.isFirstTimeOpening = false;

      return;
    }

    if (!tableToReload || tableToReload === EActivityHistoryTableTypes.LINE_VIEW) {
      this.store
        .select('activityHistoryStore')
        .pipe(take(1))
        .subscribe((state: ActivityHistoryState) => {
          this.lineViewTableParams.advancedFilter = _.get(state.filters, 'lineViewAdvancedFilter', null);
          this.lineViewTableParams.advancedFilterPage = _.get(state.filters, 'lineViewAdvancedFilterPage', null);
        });

      this.store.dispatch(
        new ActivityHistoryActions.GetActivityEvents(
          this.lineViewTableParams.page,
          !getCountOnly ? this.lineViewTableParams.pageSize : 1,
          this.lineViewTableParams.orderDesc,
          this.lineViewTableParams.orderBy,
          this.lineViewTableParams.search,
          this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
            ? null
            : this.lineViewTableParams.advancedFilter,
          this.configuration.workOrderId,
        ),
      );
    }

    if (!tableToReload || tableToReload === EActivityHistoryTableTypes.MISSING_DATA) {
      this.store
        .select('activityHistoryStore')
        .pipe(take(1))
        .subscribe((state: ActivityHistoryState) => {
          this.missingDataTableParams.advancedFilter = _.get(state.filters, 'missingDataAdvancedFilter', null);
          this.missingDataTableParams.advancedFilterPage = _.get(state.filters, 'missingDataAdvancedFilterPage', null);
        });

      this.store.dispatch(
        new ActivityHistoryActions.GetActivityEventMissingData(
          this.missingDataTableParams.page,
          !getCountOnly ? this.missingDataTableParams.pageSize : 1,
          this.missingDataTableParams.orderDesc,
          this.missingDataTableParams.orderBy,
          this.missingDataTableParams.search,
          this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
            ? null
            : this.missingDataTableParams.advancedFilter,
          this.configuration.workOrderId,
        ),
      );
    }
  }

  private performFirstTimeOperations(tableToReload: EActivityHistoryTableTypes = null): void {
    this.store
      .select('advancedFilterStore')
      .pipe(
        filter((state: IAdvancedFilterStore) => !state.isDefaultFiltersLoading && state.isDefaultFiltersLoaded),
        take(1),
      )
      .subscribe((state: IAdvancedFilterStore) => {
        const pageName: string = 'activity-history';

        this.lineViewTableParams.advancedFilter = {
          filters: [],
          target: TargetEndpoints.NestJSCrud,
          page: pageName,
        };

        this.missingDataTableParams.advancedFilter = {
          filters: [],
          target: TargetEndpoints.NestJSCrud,
          page: pageName,
        };

        const isPageNameEmpty: boolean = _.isEmpty(_.get(state.defaultFilters, pageName, null));

        if (!isPageNameEmpty) {
          this.lineViewTableParams.advancedFilterPage = pageName;
          this.lineViewTableParams.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
            state.defaultFilters[pageName],
          );

          this.missingDataTableParams.advancedFilterPage = pageName;
          this.missingDataTableParams.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
            state.defaultFilters[pageName],
          );

          this.initialLineViewAdvancedFilter = this.lineViewTableParams.advancedFilter;
          this.initialMissingDataAdvancedFilter = this.missingDataTableParams.advancedFilter;
          this.store.dispatch(
            new ActivityHistoryActions.SetSelectedFilters({
              lineViewAdvancedFilter: this.lineViewTableParams.advancedFilter,
              lineViewAdvancedFilterPage: this.lineViewTableParams.advancedFilterPage,
              missingDataAdvancedFilter: this.missingDataTableParams.advancedFilter,
              missingDataAdvancedFilterPage: this.missingDataTableParams.advancedFilterPage,
            }),
          );
        }

        if (!tableToReload || tableToReload === EActivityHistoryTableTypes.LINE_VIEW) {
          this.store.dispatch(
            new ActivityHistoryActions.GetActivityEvents(
              this.lineViewTableParams.page,
              this.lineViewTableParams.pageSize,
              this.lineViewTableParams.orderDesc,
              this.lineViewTableParams.orderBy,
              this.lineViewTableParams.search,
              this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
                ? null
                : this.lineViewTableParams.advancedFilter,
              this.configuration.workOrderId,
            ),
          );
        }

        if (!tableToReload || tableToReload === EActivityHistoryTableTypes.MISSING_DATA) {
          this.store.dispatch(
            new ActivityHistoryActions.GetActivityEventMissingData(
              this.missingDataTableParams.page,
              this.missingDataTableParams.pageSize,
              this.missingDataTableParams.orderDesc,
              this.missingDataTableParams.orderBy,
              this.missingDataTableParams.search,
              this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
                ? null
                : this.missingDataTableParams.advancedFilter,
            ),
          );
        }
      });
  }

  private fetchActivityHistoryCommentCounts(): void {
    const rawData: IActivityHistoryTableData[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    if (!rawData || rawData.length === 0) {
      return;
    }

    const objectsToFetchCommentCounts: ICommentCountWithLastCommentMessageRequestedObject[] = rawData.map(
      (activityHistory: IActivityHistoryTableData) => ({
        objectId: Number(activityHistory.id),
        objectType: ECommentLogsObjectType.activityHistory,
      }),
    );

    this.store.dispatch(
      new CommentLogsActions.CommentLogsCountWithLastMessageDataLoading({
        commentType: ECommentLogsCommentType.activityComment,
        requestedObjects: objectsToFetchCommentCounts,
        containAllCommentMessages: true,
      }),
    );
  }

  private mapActivityCommentsWithLastMessageToActivityLogs(
    commentCountsWithLastMessage: ICommentCountWithLastCommentMessage[],
  ): void {
    if (!commentCountsWithLastMessage) {
      return;
    }

    const targetTableData: IActivityHistoryWithLastComment[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    targetTableData.forEach((activityHistory: IActivityHistoryWithLastComment, index: number) => {
      const objectType: ECommentLogsObjectType = ECommentLogsObjectType.activityHistory;
      const objectId: number = Number(activityHistory.id);

      const commentCountWithLastMessage: ICommentCountWithLastCommentMessage | null =
        commentCountsWithLastMessage.find(
          (countWithLastCommentMessage: ICommentCountWithLastCommentMessage) =>
            Number(countWithLastCommentMessage.objectId) === Number(objectId) &&
            countWithLastCommentMessage.objectType === objectType,
        ) ?? null;

      targetTableData[index].commentCountWithLastCommentMessage = commentCountWithLastMessage
        ? commentCountWithLastMessage
        : { lastCommentMessage: '', totalCommentCount: 0, objectId, objectType };
    });
  }

  private getActivityColor(activityType: ActivityTypes): BorderColors {
    switch (activityType) {
      case ActivityTypes.RUN_TIME:
        return BorderColors.runtime;
      case ActivityTypes.IDLE_TIME:
        return BorderColors.idleTime;
      case ActivityTypes.DOWN_TIME:
        return BorderColors.unplannedDownTime;
      case ActivityTypes.DOWN_TIME_PLANNED:
        return BorderColors.plannedDownTime;
    }
  }

  private processLineActivities(
    data: TaskCRUDInterface[],
    language: string,
  ): IActivityHistoryBulkCreateExcelUserData[] {
    return data.map((row: TaskCRUDInterface) => {
      const activityNameTranslation: string | null = _.get(row.activity.nameTranslations, language, null);
      const activityName: string =
        language === ELanguages.EN
          ? row.activity.name
          : activityNameTranslation === null
          ? row.activity.name
          : activityNameTranslation;

      const taskName: string =
        language === ELanguages.EN
          ? row.title
          : row.entityTranslations.find(
              (task: IEntityTranslation) => task.language === language && task.entityName === 'tasks',
            )?.value ?? row.title;

      const equipmentName: string =
        language === ELanguages.EN
          ? row.equipment?.equipmentName
          : row.entityTranslations.find(
              (task: IEntityTranslation) => task.language === language && task.entityName === 'equipment_list',
            )?.value ?? row.equipment?.equipmentName;

      return {
        activityName,
        taskName,
        equipmentId: row.equipment?.id ?? null,
        equipmentName: equipmentName ?? null,
        activityId: row.activity.id,
        activityType: row.activity.activityType,
        taskId: row.id,
        phaseId: row.phaseId ?? row.activity.activityPlannedType,
        withoutWorkOrder: row.meta.withoutWorkOrder === 1,
        phaseName: _.find(this.taskPhases, { id: row.phaseId ?? row.activity.activityPlannedType })?.name,
      };
    });
  }

  private processRunActivities(data: ActivitiesInterface[]): IActivityHistoryBulkCreateExcelUserData[] {
    return HelperService.cloneDeep(data).map((run: ActivitiesInterface) => ({
      activityId: run.id,
      activityName: run.name,
      activityType: run.activityType,
      taskId: null,
      taskName: null,
      equipmentName: null,
      phaseId: run.activityPhase,
      phaseName: _.find(this.taskPhases, { id: run.activityPhase })?.name,
    }));
  }

  private getDateWithFormat(date: string): string {
    if (_.isNil(date)) {
      return date;
    }

    let formattedDate: string = moment(date, this.dateTimeFormat$, this.locale$).format(mysqlTimestampFormat);

    if (moment.isMoment(date)) {
      formattedDate = moment(date).format(mysqlTimestampFormat);
    }

    if (this.helperService.isTouchDevice) {
      this.helperService.checkIsValidDateOrFail(date, mysqlTimestampFormat);
    }

    return formattedDate;
  }

  private formatExcelResponse(
    excelResponse: IActivityHistoryBulkCreateExcelErrorData[],
  ): IActivityHistoryBulkCreateExcelErrorData[] {
    return excelResponse.map((res) => ({
      ...res,
      start: moment(res.start).format(this.datetimeFormat$),
      end: moment(res.end).format(this.datetimeFormat$),
      activityName: this.mergedLineActivityTasks.find((item) => item.activityId === res.activityId)?.activityName,
      taskName: this.mergedLineActivityTasks.find((item) => item.taskId === res.taskId)?.taskName,
      equipmentName: this.mergedLineActivityTasks.find(
        (item) => item.taskId === res.taskId && item.activityId === res.activityId,
      )?.equipmentName,
      phaseName: this.mergedLineActivityTasks.find((item) => item.phaseId === res.phaseId)?.phaseName,
      workOrder: this.workOrderData.find((wo) => wo.id === res.workOrderId)?.woNumber,
    }));
  }

  private showSuccessToast(totalCount: number, propertyName: string): void {
    this.toastHelperService.showToastMessage(
      true,
      this.translate.instant('general.success'),
      this.translate.instant('excel.messages.uploadSuccessWithDataCount', {
        count: totalCount,
        items: propertyName,
      }),
    );
  }

  private handleErrorScenario(
    excelResponse: IActivityHistoryBulkCreateExcelErrorData[],
    totalCount: number,
    failCount: number,
    propertyName: string,
    errorFromDto: boolean = false,
  ): void {
    this.activityHistoryService.downloadExcel(this.lineName$, [], [], [], excelResponse);
    this.errorModalComponent.showError(
      errorFromDto
        ? this.translate.instant('activityHistory.excel.sheet.error.apiError')
        : this.translate.instant(EErrorMessages.FILE_HAS_ERRORS_ERROR),
      this.errorModalComponent.bulkUploadMessage,
      this.errorModalComponent.okMessage,
      totalCount,
      failCount,
      propertyName,
    );
  }

  private handleBulkActivityHistoryError(response: ActivityHistoryActions.FetchError): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const clonedResponse = HelperService.cloneDeep(response.payload as object);
    const errorValues = _.get(clonedResponse, 'error.message.0.children', []);
    const allValues = _.get(clonedResponse, 'error.message.0.value', []);

    this.populateErrorMessages(errorValues, allValues);

    const excelResponse = HelperService.cloneDeep(allValues);
    const formattedExcelResponse: IActivityHistoryBulkCreateExcelErrorData[] = this.formatExcelResponse(excelResponse);

    const propertyName = this.translate.instant('toast.message.property.activityHistory');
    this.excelHelperService.logExcelUploadOperation(propertyName, this.activityAPIData, excelResponse);

    this.handleErrorScenario(
      formattedExcelResponse,
      this.activityAPIData.length,
      this.activityAPIData.length,
      propertyName,
      true,
    );
    this.store.dispatch(new AppActions.HideLoader());
  }

  private populateErrorMessages(errorValues: any[], allValues: any[]): void {
    errorValues.forEach((errorData) => {
      const errorInfo = _.get(errorData, 'children.0', {});
      const index: number = Number(errorData.property);

      allValues[index].errorMessages = this.translate.instant(
        `apiErrorMessages.${Object.keys(errorInfo.constraints)[0]}`,
        { property: errorInfo.property },
      );
    });

    allValues.forEach((value) => {
      if (!value.errorMessages) {
        value.errorMessages = this.translate.instant('activityHistory.excel.sheet.error.apiError');
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SkillMatrixService } from './skill-matrix.service';
import * as ObjectActions from './skill-matrix.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  ISkillMatrixRequest,
  SkillMatrixChartDataInterface, SkillMatrixFilterInterface,
  SkillMatrixInterface,
  SkillMatrixRawDataInterface,
} from './skill-matrix.model';
import * as dateHelper from '../../../shared/helper/date';
import * as _ from 'lodash';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { EStackSkillMatrixChartFilterDropdown } from '../../../view/reports/skill-matrix/skill-matrix-chart-filter/skill-matrix-chart-filter.model';
import {HelperService} from "../../../shared/service/helper.service";

@Injectable()
export class SkillMatrixEffects {
  constructor(
    private readonly actions$: Actions,
    public service: SkillMatrixService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly entityTranslation: EntityTranslatorService,
    private readonly helperService: HelperService,
  ) {}

  getSkillMatrixData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SKILL_MATRIX_DATA_LOADING),
      switchMap((objectData: ObjectActions.SkillMatrixDataLoading) => {
        const clonedParams: SkillMatrixFilterInterface = HelperService.cloneDeep(objectData.params);

        const body: ISkillMatrixRequest = {
          siteId: clonedParams.siteId[0],
          startDate: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(clonedParams.dateRange.startDate),
          endDate: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(clonedParams.dateRange.endDate),
          groupBy: clonedParams.groupBy,
        };

        if (clonedParams.lineIds.length) {
          body.lines = clonedParams.lineIds;
        }

        if (clonedParams.productFamilyId.length) {
          body.productFamilies = clonedParams.productFamilyId;
        }

        if (clonedParams.productIds.length) {
          body.products = clonedParams.productIds;
        }

        if (clonedParams.userIds.length) {
          body.users = clonedParams.userIds;
        }

        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getData(body).pipe(
          map((response: GetManyResponseInterface<SkillMatrixRawDataInterface>) => {
            if (body.groupBy === EStackSkillMatrixChartFilterDropdown.PRODUCT) {
              response.data.forEach((skillMatrixRawData: SkillMatrixRawDataInterface) => {
                this.entityTranslation.translate(skillMatrixRawData);
                skillMatrixRawData.productName = `${skillMatrixRawData.productTitle} - ${skillMatrixRawData.description}`;
              });
            }

            const resultArray = _.orderBy(response.data, 'totalCheckInDuration', 'desc');
            const secondToHourMultiplier: number = 3600;
            const normalizeMultiplier: number = 100;
            const finalData: SkillMatrixInterface = {
              productsNameArray: [],
              userNameArray: [],
              rawData: [],
              maxDurationInHour: null,
              minDurationInHour: null,
              chartData: [],
              d3ChartData: [],
            };

            if (!resultArray[0]) {
              return finalData;
            }

            const usernameArray: string[] = [];
            const productsNameArray: string[] = [];
            const totalCheckInDurations: number[] = [];

            for (const resultRow of resultArray) {
              usernameArray.push(resultRow.fullName);
              productsNameArray.push(resultRow.productName);
              totalCheckInDurations.push(Number(resultRow.totalCheckInDuration));
            }

            const maxDuration: number =
              Math.max(...totalCheckInDurations, secondToHourMultiplier) / secondToHourMultiplier;
            const minDuration: number = Math.min(...totalCheckInDurations) / secondToHourMultiplier;
            const userNameUnique: string[] = usernameArray.filter(
              (userNameValue: string, index: number, usernameArray: string[]) =>
                usernameArray.indexOf(userNameValue) === index,
            );
            const productNameUnique: string[] = productsNameArray.filter(
              (productNameValue: string, index: number, productNameArray: string[]) =>
                productNameArray.indexOf(productNameValue) === index,
            );
            const dataMatrix: SkillMatrixChartDataInterface[][] = [];

            for (let chartRowIndex = 0; chartRowIndex < userNameUnique.length; chartRowIndex += 1) {
              const newRow: SkillMatrixChartDataInterface[] = [];

              for (let chartColumnIndex = 0; chartColumnIndex < productNameUnique.length; chartColumnIndex += 1) {
                newRow.push({
                  xAxisPosition: chartColumnIndex,
                  yAxisPosition: chartRowIndex,
                  normValue: 0,
                  value: '0',
                });
              }

              dataMatrix.push(newRow);
            }

            // normalize the results into 0-1
            for (const resultRow of resultArray) {
              const userNameIndex: number = userNameUnique.indexOf(resultRow.fullName);
              const productNameIndex: number = productNameUnique.indexOf(resultRow.productName);
              let normalizedDuration: number =
                maxDuration === minDuration
                  ? 1
                  : Number(
                      (
                        ((resultRow.totalCheckInDuration / secondToHourMultiplier - minDuration) *
                          normalizeMultiplier) /
                        (maxDuration - minDuration) /
                        normalizeMultiplier
                      ).toFixed(1),
                    );

              if (
                normalizedDuration < 0.1 &&
                0 < Number((resultRow.totalCheckInDuration / secondToHourMultiplier).toFixed(2))
              ) {
                normalizedDuration = 0.1;
              }

              dataMatrix[userNameIndex][productNameIndex].normValue = normalizedDuration;
              dataMatrix[userNameIndex][productNameIndex].value = this.decimalHelper.divide(
                resultRow.totalCheckInDuration,
                secondToHourMultiplier.toString(),
              );
            }

            return {
              rawData: Object.values(resultArray),
              productsNameArray: productNameUnique,
              userNameArray: userNameUnique,
              maxDurationInHour: maxDuration,
              minDurationInHour: minDuration === maxDuration ? 0 : minDuration,
              d3ChartData: dataMatrix,
            };
          }),
          switchMap((payload: SkillMatrixInterface) => {
            return of(new ObjectActions.SkillMatrixDataLoaded(payload), new AppActions.HideLoader());
          }),
          catchError((err: any) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );
}

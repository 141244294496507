<div
  *ngIf="metricSetItemsArrayWithProperties.length > 0 || lineViewMode"
  joyrideStep="step7"
  [attr.title]="homeScreenMetricTooltipTitle"
  [title]="homeScreenMetricTooltipTitle"
  [text]="homeScreenMetricTooltipText"
  class="home-screen-metrics main-card"
>
  <app-card [cardClass]="cardClass">
    <ng-container *ngIf="isMiniMetricsEnabled">
      <app-home-mini-metrics
        [temperatureSensors]="temperatureSensors"
        [humiditySensors]="humiditySensors"
      ></app-home-mini-metrics>
    </ng-container>
    <div *ngIf="lineViewMode && siteLineName" class="site-line-title">
      {{ siteLineName.siteName }} -
      <a (click)="goToHome()" class="cursor-pointer link-style">
        {{ siteLineName.lineTitle }} <em class="fas fa-external-link-alt fa-xs"></em
      ></a>
    </div>
    <div class="sub-card-content" *ngIf="!lineViewMode || homeMetricsLoaded || !isSiteLineChanging">
      <div class="row no-gutters metrics">
        <em
          *ngIf="metricSetItemsArrayWithProperties.length > 0"
          class="fas fa-md fa-info-circle home-metrics-info-button"
          (click)="openKpiCardInformationModal(kpiMetricInformationModal)"
        ></em>
        <div *ngIf="lineViewMode" class="overflow-hidden col-2 element-behind">
          <div class="w-100 h-100 p-025">
            <app-simplified-info-card
              [topText]="workOrderInfo?.workOrderName"
              [bottomText]="workOrderInfo?.productName"
              [bottomTextAlt]="workOrderInfo?.productDescription"
              [backgroundColour]="workOrderInfo?.backgroundColour"
            ></app-simplified-info-card>
          </div>
        </div>
        <div *ngIf="lineViewMode" class="overflow-hidden col-2 element-behind">
          <div class="w-100 h-100 p-025">
            <app-simplified-info-card
              [topText]="activityInfo?.activityName"
              [bottomText]="activityInfo?.taskName"
              [backgroundColour]="activityInfo?.backgroundColour"
            ></app-simplified-info-card>
          </div>
        </div>
        <div
          class="overflow-hidden col-sm-12 element-behind"
          *ngFor="
            let metricSetItems of metricSetItemsArrayWithProperties;
            index as index;
            trackBy: datatableHeaderTrackBy
          "
          [ngClass]="'col-md-' + 12 / metricSetItemsArrayWithProperties?.length"
          [ngClass]="'col-md-' + 12 / metricSetItemsArrayWithProperties?.length"
        >
          <div class="w-100 h-100 p-025">
            <ng-container
              *ngComponentOutlet="
                metricSetItems.component;
                injector: getInjector(metricSetItems.componentType, metricSetItems?.homeMetricSetProperties, index)
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</div>

<ng-template #kpiMetricInformationModal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ 'main.kpiCardsInformation.title' | translate }}</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <kpi-cards-information [kpiCards]="kpiCards"></kpi-cards-information>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (click)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EMetricType, IKpiCardInformation, IKpiCards, KpiCardParameterUnit } from './kpi-cards-information.model';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../service/helper.service';
import * as _ from 'lodash';
import {
  EDataSource,
  EHomeMetrics,
  ELayout,
  ETimeSpan,
  EUnitOfTime,
} from '../../../view/settings/home-page-display-settings/home-page-display-settings.constants';
import {
  IMetricsWithCustomPredefined,
  IPreDefinedKPIMetricsWithProperties,
} from '../../../view/home/home-metrics/speed-metric/speed-metric.model';
import { HomePageDisplaySettingsUtilities } from '../../../view/settings/home-page-display-settings/home-page-display-settings.utilities';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import {
  EOeeMetric,
  IActivitiesTotalDuration,
  ICountMeta,
  IOEEMetricMeta,
  IOEEMetricMultiselectMeta,
  IPostRunPhaseDurationMeta,
  IPreRunPhaseDurationMeta,
  ISpeedMeta,
  IUnitOfCount,
  IWorkOrderDurationMeta,
} from '../../../view/settings/home-page-display-settings/home-metrics/home-metrics.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import {
  IHomePageDisplaySettingsState,
  ILookup,
} from '../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { Observable, startWith } from 'rxjs';
import { IOeeMetricApq } from '../../../store/home/home.model';
import { propertyToMetricTitleTwelveHours } from '../../../view/home/home-metrics/work-order-oee-metric-twelve-hours/work-order-oee-metric-twelve-hours.component';
import { propertyToMetricTitleSixHours } from '../../../view/home/home-metrics/work-order-oee-metric-six-hours/work-order-oee-metric-six-hours.component';

@OnDestroyDecorator
@Component({
  selector: 'kpi-cards-information',
  templateUrl: './kpi-cards-information.component.html',
  styleUrls: ['./kpi-cards-information.component.scss', '../../../../scss/custom.scss'],
})
export class KpiCardsInformationComponent implements OnInit, OnChanges, DoCheck {
  @Input() kpiCards: IKpiCards[];
  @Input() layout: string = null;
  @Input() timeSpan: string = null;
  @Input() dataSource: string = null;
  @Input() countType: string = null;
  @Input() unitOfTime: string = null;
  @Input() actualWorkOrderDuration: string = null;
  @Input() targetWorkOrderDuration: string = null;
  @Input() unitOfCount: string | IUnitOfCount[] = null;
  @Input() metricLayout: EOeeMetric = EOeeMetric.OEE;
  @Input() metricLayoutMultiple: EOeeMetric[] = [EOeeMetric.OEE];
  @Input() unitOfCounts: IUnitOfCount[] = [];
  @Input() showMetricInfo: boolean = true;
  @Input() showAllMetric: boolean = false;
  @Input() sampleDataWarning: string = this.translate.instant('kpiCardInformation.sampleDataWarning');

  private readonly getPreDefinedSpeedMetricsWithProperties: IPreDefinedKPIMetricsWithProperties[] =
    HomePageDisplaySettingsUtilities.getPreDefinedSpeedMetricsWithProperties(this.translate, this.decimalHelper, null);

  private readonly getPreDefinedCountMetricsWithProperties: IPreDefinedKPIMetricsWithProperties[] =
    HomePageDisplaySettingsUtilities.getPreDefinedCountMetricsWithProperties(this.translate, this.decimalHelper, null);
  private readonly preDefinedMetricsForInfoModal: IMetricsWithCustomPredefined =
    HomePageDisplaySettingsUtilities.getPreDefinedMetricsForInfoModal();

  private readonly progressBarRate: string = '50';
  private readonly progressBarRateCapped: number = 50;
  private readonly ppmLabel: string = this.translate.instant('products.lookups.unit.unitPiece.formula');
  private readonly pphLabel: string = this.translate.instant('general.generalSpeedUnitByHour');
  private readonly hourLabel: string = this.translate.instant('general.shortHour');
  private readonly pcsLabel: string = this.translate.instant('homePageDisplaySettings.unit.unitPiece.formula');

  private sampleActualValue: string = '5';
  private sampleActualValueForHourly: string[] = [];
  private sampleExpectedValue: string = '10';
  private showProgressBar: boolean = true;
  private showExpectedValue: boolean = true;
  private actualValueClass: string = null;
  private formulaValue: string = '';
  private formulaValueDescription: string = '';
  private metricName: string = '';
  private info: string = '';
  private icons: string[] = [];
  private metricType: EMetricType = null;
  private actualFormula: string = '';
  private actualValueDescription: string = '';
  private expectedFormula: string = '';
  private expectedValueDescription: string = '';
  private titleBracketInfo: string = '';
  private metricAndProperties: string = '';
  private unit: string = '';

  public greenColor: string = '#6FC764';
  public barSize: string = 'w-60';
  public badgeBarSize: string = 'metric-item w-40';
  public kpiCardsInformation: IKpiCardInformation[] = [];
  private previousUnitOfCount: string | IUnitOfCount[] = null;
  private unitOfCountIds: string = null;

  private kpiCardValues: KpiCardParameterUnit & { jobOee: { unit: string; metricType: string } } = {
    activityDuration: { unit: '', metricType: EMetricType.PROGRESS_BAR },
    quantityWithComment: { unit: '', metricType: EMetricType.COUNT },
    postRunPhaseDuration: { unit: this.hourLabel, metricType: EMetricType.PROGRESS_BAR },
    preRunPhaseDuration: { unit: this.hourLabel, metricType: EMetricType.PROGRESS_BAR },
    runPhaseDuration: { unit: this.hourLabel, metricType: EMetricType.PROGRESS_BAR },
    sensorBadges: { unit: this.pcsLabel, metricType: EMetricType.BADGE },
    workOrderDuration: { unit: this.hourLabel, metricType: EMetricType.PROGRESS_BAR },
    speed: { unit: this.ppmLabel, metricType: EMetricType.PROGRESS_BAR },
    workOrderOee: { unit: '', metricType: EMetricType.OEE },
    workOrderOeeSixHours: { unit: '', metricType: EMetricType.HOURLY_OEE },
    workOrderOeeTwelveHours: { unit: '', metricType: EMetricType.HOURLY_OEE },
    count: { unit: this.ppmLabel, metricType: EMetricType.PROGRESS_BAR },
    activitiesTotalDuration: { unit: '', metricType: EMetricType.ACTIVITIES_TOTAL_DURATION },
    shiftOee: { unit: '', metricType: EMetricType.OEE },
    jobOee: { unit: '', metricType: EMetricType.OEE },
  };

  protected readonly EHomeMetrics = EHomeMetrics;
  public readonly apqDummyData$ = new Observable<IOeeMetricApq>().pipe(
    startWith({ oee: '54', a: '80', p: '75', q: '90' }),
  );

  constructor(
    public translate: TranslateService,
    public helperService: HelperService,
    public decimalHelper: DecimalHelper,
    private readonly store: Store<OeeAppState>,
  ) {}

  public ngOnInit(): void {
    if (this.showMetricInfo) {
      this.kpiCards = this.kpiCards.filter((element: IKpiCards, index: number) => {
        return this.kpiCards.indexOf(element) === index;
      });
    }

    this.pushKPIMetricInfo();
    this.barSize = this.showMetricInfo ? 'w-60' : 'w-100';
    this.badgeBarSize = this.showMetricInfo ? 'metric-item w-40' : 'metric-item w-90';

    this.store.select('homePageDisplaySettingsStore').subscribe((state: IHomePageDisplaySettingsState) => {
      if (!state.isProductUnitsLoading && state.isProductUnitsLoaded) {
        this.unitOfCounts = state.productUnits;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.hasRelevantChanges(changes)) {
      this.kpiCardsInformation = [];
      this.pushKPIMetricInfo();
    }
  }

  private hasRelevantChanges(changes: SimpleChanges): boolean {
    return [
      'kpiCards',
      'timeSpan',
      'countType',
      'dataSource',
      'unitOfTime',
      'layout',
      'unitOfCount',
      'metricLayout',
      'metricLayoutMultiple',
      'actualWorkOrderDuration',
    ].some((key: string) => key in changes && !changes[key].firstChange);
  }

  private addStaticMetrics(metrics: IPreDefinedKPIMetricsWithProperties[], metricId: EHomeMetrics): void {
    metrics.forEach((properties: IPreDefinedKPIMetricsWithProperties) => {
      this.kpiCards.push({
        id: metricId,
        name: metricId,
        homeMetricSetProperties: properties.meta,
      });
    });
  }

  private pushKPIMetricInfo(): void {
    this.kpiCards = _.uniq(this.kpiCards);

    if (this.showAllMetric) {
      this.kpiCards = this.kpiCards.filter(
        (metric: IKpiCards) =>
          [
            EHomeMetrics.COUNT,
            EHomeMetrics.SPEED,
            EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
            EHomeMetrics.PRE_RUN_PHASE_DURATION,
            EHomeMetrics.POST_RUN_PHASE_DURATION,
          ].indexOf(metric.id) === -1,
      );

      this.addStaticMetrics(this.getPreDefinedSpeedMetricsWithProperties, EHomeMetrics.SPEED);
      this.addStaticMetrics(this.getPreDefinedCountMetricsWithProperties, EHomeMetrics.COUNT);
      this.kpiCards.push(...this.preDefinedMetricsForInfoModal[EHomeMetrics.ACTIVITIES_TOTAL_DURATION]);
      this.kpiCards.push(...this.preDefinedMetricsForInfoModal[EHomeMetrics.PRE_RUN_PHASE_DURATION]);
      this.kpiCards.push(...this.preDefinedMetricsForInfoModal[EHomeMetrics.POST_RUN_PHASE_DURATION]);
    }

    this.kpiCards.forEach((metric: IKpiCards) => {
      const formattedCardName: string = _.lowerFirst(_.camelCase(metric.id));
      this.metricType = _.get(this.kpiCardValues, formattedCardName)?.metricType;
      this.metricAndProperties = formattedCardName;
      this.metricName = metric.id;
      this.setDefaultParameterOfMetrics();
      this.unit = _.get(this.kpiCardValues, formattedCardName)?.unit;

      switch (metric.id) {
        case EHomeMetrics.QUANTITY_WITH_COMMENT:
          this.showExpectedValue = false;
          this.showProgressBar = false;
          break;
        case EHomeMetrics.JOB_OEE:
        case EHomeMetrics.WORK_ORDER_OEE:
          this.setOeeMetrics(formattedCardName);
          metric.homeMetricSetProperties = { metricLayout: this.metricLayoutMultiple };
          break;
        case EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS:
          this.setOeeMetrics(formattedCardName);
          metric.homeMetricSetProperties = { metricLayout: this.metricLayout };
          this.metricName = propertyToMetricTitleSixHours[this.metricLayout as EOeeMetric];
          break;
        case EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS:
          this.setOeeMetrics(formattedCardName);
          metric.homeMetricSetProperties = { metricLayout: this.metricLayout };
          this.metricName = propertyToMetricTitleTwelveHours[this.metricLayout as EOeeMetric];
          break;
        case EHomeMetrics.ACTIVITY_DURATION:
          this.setActivityDurationMetrics();
          break;
        case 'speed':
          this.setSpeedMetricItem(metric.homeMetricSetProperties as ISpeedMeta);
          break;
        case EHomeMetrics.WORK_ORDER_DURATION:
          this.setWorkOrderDurationMetricItem(
            metric.homeMetricSetProperties as IWorkOrderDurationMeta,
            formattedCardName,
          );
          break;
        case EHomeMetrics.COUNT:
          this.setCountMetricItem(metric.homeMetricSetProperties as ICountMeta);
          break;
        case EHomeMetrics.ACTIVITIES_TOTAL_DURATION:
          this.setActivitiesTotalDurationMetricItem(metric.homeMetricSetProperties as IActivitiesTotalDuration);
          break;
        case EHomeMetrics.SHIFT_OEE:
          this.showExpectedValue = false;
          this.sampleExpectedValue = '100';
          metric.homeMetricSetProperties = { metricLayout: this.metricLayoutMultiple };
          break;
        case EHomeMetrics.PRE_RUN_PHASE_DURATION:
          this.setPreRunDurationMetricItem(
            metric.homeMetricSetProperties as IPreRunPhaseDurationMeta,
            formattedCardName,
          );
          break;
        case EHomeMetrics.POST_RUN_PHASE_DURATION:
          this.setPostRunDurationMetricItem(
            metric.homeMetricSetProperties as IPostRunPhaseDurationMeta,
            formattedCardName,
          );
          break;
      }

      this.kpiCardsInformation.push(this.createKpiCardInformation(metric));
    });
  }

  private createKpiCardInformation(metric: IKpiCards): IKpiCardInformation {
    return {
      metricType: this.metricType,
      metricKey: this.metricName,
      metricName: this.translate.instant(`kpiCards.metrics.${this.metricName}`),
      actualValue: this.sampleActualValue,
      actualValueForHourly: this.sampleActualValueForHourly,
      actualFormula: this.translate.instant(`kpiCardInformation.${this.actualFormula}.actualFormula`),
      actualValueDescription:
        metric.actualDescription ??
        this.translate.instant(`kpiCardInformation.${this.actualValueDescription}.actualDescription`),
      expectedValue: this.sampleExpectedValue,
      expectedFormula: this.translate.instant(`kpiCardInformation.${this.expectedFormula}.expectedFormula`),
      expectedValueDescription:
        metric.expectedDescription ??
        this.translate.instant(`kpiCardInformation.${this.expectedValueDescription}.expectedDescription`),
      progressBarRate: this.progressBarRate,
      progressBarRateCapped: this.progressBarRateCapped,
      actualValueClass: this.actualValueClass,
      showProgressBar: this.showProgressBar,
      showExpectedValue: this.showExpectedValue,
      unit: this.unit,
      formulaValue: this.formulaValue,
      formulaValueDescription: this.formulaValueDescription,
      info: this.info,
      icons: this.icons,
      titleBracketInfo: this.titleBracketInfo,
      unitOfCount: this.unitOfCountIds,
      unitOfTime: this.unitOfTime ?? null,
      metricLayout: (metric.homeMetricSetProperties as IOEEMetricMeta) ?? null,
      metricLayoutMultiSelect: (metric.homeMetricSetProperties as IOEEMetricMultiselectMeta) ?? null,
    };
  }

  private setDefaultParameterOfMetrics(): void {
    this.showProgressBar = true;
    this.showExpectedValue = true;
    this.sampleActualValue = '5' as string;
    this.sampleExpectedValue = '10';
    this.actualValueClass = null;
    this.formulaValue = '';
    this.formulaValueDescription = '';
    this.info = '';
    this.unit = '';
    this.icons = [];
    this.titleBracketInfo = null;
    this.actualFormula = this.metricAndProperties;
    this.actualValueDescription = this.metricAndProperties;
    this.expectedFormula = this.metricAndProperties;
    this.expectedValueDescription = this.metricAndProperties;
  }

  private setWorkOrderDurationMetricItem(meta: IWorkOrderDurationMeta, formattedCardName: string): void {
    if (!_.isNil(meta)) {
      this.actualWorkOrderDuration = meta.actualWorkOrderDuration;
      this.targetWorkOrderDuration = meta.targetWorkOrderDuration;
    }

    const dot: string = '.';
    this.metricName =
      this.actualWorkOrderDuration === null
        ? this.metricName
        : [formattedCardName, this.actualWorkOrderDuration].join(dot);
    this.actualValueDescription =
      this.actualWorkOrderDuration === null
        ? formattedCardName
        : [formattedCardName, this.actualWorkOrderDuration].join(dot);
    this.expectedValueDescription =
      this.actualWorkOrderDuration === null
        ? formattedCardName
        : [formattedCardName, this.targetWorkOrderDuration].join(dot);
  }

  private setPreRunDurationMetricItem(meta: IPreRunPhaseDurationMeta, formattedCardName: string): void {
    if (_.isNil(meta)) {
      return;
    }

    const dot: string = '.';

    this.expectedValueDescription = [formattedCardName, meta.targetPreRunPhaseDuration].join(dot);
  }

  private setPostRunDurationMetricItem(meta: IPostRunPhaseDurationMeta, formattedCardName: string): void {
    if (_.isNil(meta)) {
      return;
    }

    const dot: string = '.';

    this.expectedValueDescription = [formattedCardName, meta.targetPostRunPhaseDuration].join(dot);
  }

  private setOeeMetrics(formattedCardName: string): void {
    this.showExpectedValue = false;
    this.sampleActualValueForHourly =
      formattedCardName === 'workOrderOeeTwelveHours'
        ? ['20', '50', '80', '20', '50', '80', '20', '50', '80', '20', '50', '80']
        : ['20', '50', '80', '20', '50', '80'];
    this.sampleExpectedValue = '100';
    this.info = this.translate.instant(`kpiCardInformation.${formattedCardName}.info`);
  }

  private setActivityDurationMetrics(): void {
    const hour = this.translate.instant('general.shortHour');
    const minute = this.translate.instant('general.shortMinute');
    const second = this.translate.instant('general.shortSecond');
    this.sampleActualValue = `1${hour} 2${minute} 3${second}`;
    this.sampleExpectedValue = `2${hour}`;
    this.actualValueClass = 'c-red';
  }

  private setSpeedMetricItem(meta: ISpeedMeta): void {
    if (meta) {
      const dot: string = '.';
      const unitOfTime: string = meta.unitOfTime ? dot + meta.unitOfTime : '';
      this.metricAndProperties = [
        this.metricAndProperties,
        meta.layout,
        meta.dataSource,
        `${meta.countType}${unitOfTime}`,
        meta.timeSpan,
      ].join(dot);

      this.layout = meta.layout;
      this.dataSource = meta.dataSource;
      this.timeSpan = meta.timeSpan;
      this.countType = meta.countType;
      this.unitOfTime = meta.unitOfTime;
      this.unitOfCount = meta.unitOfCount;

      this.actualFormula = this.metricAndProperties;
      this.actualValueDescription = this.metricAndProperties;
      this.formulaValue = this.translate.instant('general.formula');
      this.formulaValueDescription = this.translate.instant(`kpiCardInformation.${this.metricAndProperties}.formula`);
    }

    this.titleBracketInfo = HomePageDisplaySettingsUtilities.setTitleBracketInfo(
      this.translate,
      this.dataSource,
      this.countType,
      this.timeSpan,
    );

    switch (this.unitOfTime) {
      case EUnitOfTime.HOUR:
        this.unit = this.pphLabel;
        break;
      case EUnitOfTime.MINUTE:
        this.unit = this.ppmLabel;
        break;
    }

    switch (this.dataSource) {
      case EDataSource.WORK_ORDER:
        this.metricName = 'work_order_speed';
        break;
      case EDataSource.HOURLY:
        this.metricName = 'hourly_speed';
        break;
      case EDataSource.SENSOR:
        this.metricName = 'sensor_speed';
        break;
      default:
        this.metricName = 'speed';
    }

    switch (this.timeSpan) {
      case ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE:
        this.icons = [
          'fas fa-running c-green padding-lr',
          'fas fa-calendar c-red padding-lr',
          'fas fa-pause-circle c-grey padding-lr',
        ];
        break;
      case ETimeSpan.WORK_ORDER_RUN_DOWN:
        this.icons = ['fas fa-running c-green padding-lr', 'fas fa-calendar c-red padding-lr'];
        break;
      case ETimeSpan.WORK_ORDER_RUN:
        this.icons = ['fas fa-running c-green padding-lr'];
        break;
      default:
        this.icons = [];
    }

    if (!_.isNil(this.unitOfCount) && this.unitOfCount !== '0') {
      const unitOfCountId: number =
        typeof this.unitOfCount === 'string' ? Number(this.unitOfCount) : this.unitOfCount[0]?.id;
      const unitName = this.unitOfCounts.find((unit: ILookup) => unit.id === unitOfCountId)?.name;

      if (!_.isNil(unitName)) {
        this.unit =
          this.unitOfTime === EUnitOfTime.HOUR
            ? this.translate.instant(`homePageDisplaySettings.unit.${_.camelCase(unitName)}ByHour.formula`)
            : this.translate.instant(`homePageDisplaySettings.unit.${_.camelCase(unitName)}ByMinute.formula`);
      }
    }
  }

  private setActivitiesTotalDurationMetricItem(meta: IActivitiesTotalDuration): void {
    this.showExpectedValue = false;
    this.unit = _.isNil(meta) ? this.unitOfTime : meta['unitOfTime'];
  }

  private setCountMetricItem(meta: ICountMeta): void {
    if (meta) {
      const dot: string = '.';

      this.metricAndProperties = [
        this.metricAndProperties,
        meta.layout,
        meta.dataSource,
        meta.countType,
        meta.timeSpan,
      ].join(dot);

      this.layout = meta.layout;
      this.dataSource = meta.dataSource;
      this.timeSpan = meta.timeSpan;
      this.countType = meta.countType;
      this.unitOfCount = meta.unitOfCount;

      this.actualFormula = this.metricAndProperties;
      this.actualValueDescription = this.metricAndProperties;
      this.expectedFormula = this.metricAndProperties;
      this.expectedValueDescription = this.metricAndProperties;
      this.formulaValue = this.translate.instant('general.description');
      this.formulaValueDescription = this.translate.instant(`kpiCardInformation.${this.metricAndProperties}.formula`);
    }

    this.unit = this.pcsLabel;
    this.titleBracketInfo = HomePageDisplaySettingsUtilities.setTitleBracketInfo(
      this.translate,
      this.dataSource,
      this.countType,
      this.timeSpan,
    );

    switch (this.dataSource) {
      case EDataSource.WORK_ORDER:
        this.metricName = 'work_order_count';
        break;
      case EDataSource.HOURLY:
        this.metricName = 'hourly_count';
        break;
      case EDataSource.SENSOR:
        this.metricName = 'sensor_count';
        break;
      case EDataSource.SHIFT:
        this.metricName = 'shift_count';
        break;
      case EDataSource.JOB:
        this.metricName = 'job_count';
        break;
      default:
        this.metricName = 'count';
    }

    this.showExpectedValue = this.layout !== ELayout.MULTIPLE_UNITS;
    this.metricType = this.layout === ELayout.MULTIPLE_UNITS ? EMetricType.MULTIPLE_UNITS : EMetricType.COUNT;
    this.unitOfCountIds =
      typeof this.unitOfCount === 'string'
        ? this.unitOfCount
        : this.concatenateArrayIdsAsString(HelperService.cloneDeep(this.unitOfCount));

    this.formulaValue = this.layout === ELayout.MULTIPLE_UNITS ? '' : this.formulaValue;
  }

  public concatenateArrayIdsAsString(value: IUnitOfCount[]): string {
    return Array.isArray(value) && value.length > 0 ? value.map((item: IUnitOfCount) => item.id).join(',') : undefined;
  }

  public ngDoCheck(): void {
    if (!_.isNil(this.unitOfCount) && !_.isEqual(this.unitOfCount, this.previousUnitOfCount)) {
      this.previousUnitOfCount = HelperService.cloneDeep(this.unitOfCount);

      this.kpiCardsInformation = [];
      this.pushKPIMetricInfo();
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { ECellTypes } from 'src/app/shared/service/excel/excel.enum';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  DownloadExcelFiltersInterface,
  ExcelColumnKeysInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../../shared/model/enum/excel-date-format';
import * as _ from 'lodash';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from './lines.actions';
import * as AppActions from '../../../app/actions';
import { LineCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  CountEntryEnum,
  CountEntryLanguageKeys,
  CountTypeEnum,
  CountTypeLanguageKeys,
  OeeEnum,
  OeeEnumTranslation,
  SensorTypeEnum,
  StatusesEnum,
  StatusesLanguageKeys,
  TrueFalse,
  YesNoEnum,
  YesNoLanguageKeys,
  sensorTypeTranslations,
} from '../../../../../constants';
import {
  ExcelDropdownDataWithDescription,
  ExcelDropdownDataWithDescriptionFormatted,
  GetManyShiftPlanItemsCrudResponse,
  IExcelDropdownWithStringId,
  IExcelSiteDataInterface,
  ILineExcelResponse,
  LineExcelInterface,
  LinesExcelDataInterface,
  LinesExcelDropdownContentInterface,
  ShiftPlanInterface,
  EDistributionMethodOfOrderedQuantity,
  ILineExcel,
} from './lines.model';
import { takeUntil } from 'rxjs/operators';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineTypeInterface } from '../../../site-line-selection/site-line-selection.model';
import { ActivityInterface } from '../../../task-groups/task-groups.model';
import { HelperService } from '../../../../shared/service/helper.service';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { LineAvailabilityPlanItemInterface } from '../../../line-availability/line-availability.model';
import { ExcelDropdownInterface } from '../../users/users.model';
import { mysqlTimestampFormat } from '../../../../shared/helper/date';
import { ILayout } from '../../home-page-display-settings/home-page-display-settings.model';

@Injectable({
  providedIn: 'root',
})
export class LinesExcelService {
  private readonly urls = {
    SITES_URL: `${this.baseUrl}/sites`,
    LINES_URL: `${this.baseUrl}/lines`,
    LINE_TYPES_URL: `${this.baseUrl}/line-types`,
    LAYOUTS_URL: `${this.baseUrl}/layouts`,
    SHIFT_PLANS_URL: `${this.baseUrl}/scheduler/shift-plans`,
    ACTIVITY_URL: `${this.baseUrl}/activities`,
    BULK_SAVE_URL: `${this.baseUrl}/lines/bulk/save`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly standardSpeed: string = 'general.excel.column.standardSpeed';
  private readonly excelUploadLimit: number = 1000;
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private activities: ActivityInterface[];
  private dateCharacterFormat$: string;

  constructor(
    public http: HttpClient,
    public readonly excelHelper: ExcelHelperService,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateCharacterFormat$ = state.dateFormat;
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getLayouts(params: HttpParams): Observable<GetManyResponseInterface<ILayout>> {
    return this.http.get<GetManyResponseInterface<ILayout>>(this.urls.LAYOUTS_URL, {
      params,
    });
  }

  public getShiftPlans(params: HttpParams): Observable<GetManyShiftPlanItemsCrudResponse> {
    return this.http.get<GetManyShiftPlanItemsCrudResponse>(this.urls.SHIFT_PLANS_URL, {
      params,
    });
  }

  public getSite(params: HttpParams, id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.urls.SITES_URL}/${id}`, { params });
  }

  public getLineTypes(params: HttpParams): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.urls.LINE_TYPES_URL}`, { params });
  }

  public getLines(params: HttpParams): Observable<GetManyResponseInterface<Partial<LineCRUDInterface>>> {
    return this.http.get<GetManyResponseInterface<Partial<LineCRUDInterface>>>(this.urls.LINES_URL, { params });
  }

  public getActivities(): Promise<GetManyResponseInterface<ActivityInterface>> {
    const params: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));

    return new Promise((resolve, reject) => {
      this.http.get(this.urls.ACTIVITY_URL, { params }).subscribe(
        (response: GetManyResponseInterface<ActivityInterface>) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }

  public async downloadExcel(
    withData: boolean,
    filters: ILineExcel,
    withErrorColumn: boolean = false,
    data?: LineExcelInterface[],
  ): Promise<void> {
    const httpParams: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));
    const lineTypesParams: HttpParams = httpParams.set('s', JSON.stringify({ status: { $in: [StatusesEnum.ACTIVE] } }));
    const lineParams: HttpParams = httpParams
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('page', filters.selectedDownloadOffset)
      .append('join', 'layoutAssignment||layoutId');
    const layoutParams: HttpParams = httpParams.set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));
    const siteParams: HttpParams = httpParams.set('fields', 'id,name,isNewShiftMechanismActive');
    const shiftParams: HttpParams = httpParams
      .append('join', 'schedulerShiftPlansSiteAssignment')
      .append('join', 'schedulerShiftPlanItem')
      .set(
        's',
        JSON.stringify({
          'schedulerShiftPlansSiteAssignment.siteId': { $eq: filters.siteId.toString() },
          isOfficial: { $eq: true },
        }),
      );
    const observables: any = [
      this.getLineTypes(lineTypesParams),
      this.getSite(siteParams, filters.siteId),
      this.getLayouts(layoutParams),
      this.getShiftPlans(shiftParams),
    ];
    this.activities = (await this.getActivities()).data;

    if (withData && !data) {
      observables.push(this.getLines(lineParams));
    }

    forkJoin(observables).subscribe((responseList) => {
      const lineTypes: LineTypeInterface[] = _.get(responseList, '0.data', [])
        .map((lineType: LineTypeInterface) => {
          return { ...lineType, name: lineType.lineType };
        })
        .sort(this.helperService.dropdownOptionCompareFunction);
      const microstopSensorType: IExcelDropdownWithStringId[] = [
        {
          id: SensorTypeEnum.TOTAL_COUNTER,
          description: this.translate.instant(sensorTypeTranslations[SensorTypeEnum.TOTAL_COUNTER]),
        },
        {
          id: SensorTypeEnum.GOOD_COUNTER,
          description: this.translate.instant(sensorTypeTranslations[SensorTypeEnum.GOOD_COUNTER]),
        },
      ];
      const site: IExcelSiteDataInterface = _.get(responseList, '1.data', []);
      const layouts: SimplifiedDataInterface[] = _.get(responseList, '2.data', []);
      const shiftPlans: ShiftPlanInterface[] = (_.get(responseList, '3.data', []) as ShiftPlanInterface[]).filter(
        (item: ShiftPlanInterface) =>
          item.schedulerShiftPlanItem.length &&
          item.schedulerShiftPlanItem.some(
            (planItem: LineAvailabilityPlanItemInterface) => !planItem.schedulerShiftExceptionId,
          ) &&
          (site.isNewShiftMechanismActive || item.schedulerShiftAlternatePlan.length === 1),
      );
      const statuses: ExcelDropdownDataWithDescription[] = [
        { id: StatusesEnum.ACTIVE, description: this.translate.instant(StatusesLanguageKeys.ACTIVE) },
        { id: StatusesEnum.INACTIVE, description: this.translate.instant(StatusesLanguageKeys.INACTIVE) },
      ];
      const oeeFormula: IExcelDropdownWithStringId[] = [
        { id: OeeEnum.APQ, description: OeeEnumTranslation.apq },
        { id: OeeEnum.AP, description: OeeEnumTranslation.ap },
        { id: OeeEnum.A, description: OeeEnumTranslation.a },
      ];
      const countEntries: ExcelDropdownDataWithDescription[] = [
        { id: CountEntryEnum.CUMULATIVE, description: this.translate.instant(CountEntryLanguageKeys.CUMULATIVE) },
        { id: CountEntryEnum.INCREMENTAL, description: this.translate.instant(CountEntryLanguageKeys.INCREMENTAL) },
      ];
      const countTypes: ExcelDropdownDataWithDescription[] = [
        { id: CountTypeEnum.INITIAL_SCRAP, description: this.translate.instant(CountTypeLanguageKeys.INITIAL_SCRAP) },
        { id: CountTypeEnum.INITIAL_YIELD, description: this.translate.instant(CountTypeLanguageKeys.INITIAL_YIELD) },
        { id: CountTypeEnum.YIELD_SCRAP, description: this.translate.instant(CountTypeLanguageKeys.YIELD_SCRAP) },
      ];
      const automatedPhaseComments: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const automatedPhaseDuration: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const automatedRunDuration: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const isMissingDataAttention: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const distributeOrderedQty: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const targetExceedMessage: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const distMethodOfOrdQty: ExcelDropdownDataWithDescription[] = [
        {
          id: EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_GOOD_COUNT_IF_ACTUAL_COUNT_ZERO,
          description: this.translate.instant(
            'settings.lines.lineForm.distributionMethodOfOrderedQuantity.orderedQuantityAsGoodCountIfActualCountZero',
          ),
        },
        {
          id: EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_INITIAL_COUNT_OW_ACTUAL_COUNT,
          description: this.translate.instant(
            'settings.lines.lineForm.distributionMethodOfOrderedQuantity.orderedQuantityAsInitialCountOwActualCount',
          ),
        },
        {
          id: EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_GOOD_COUNT_OW_ACTUAL_COUNT,
          description: this.translate.instant(
            'settings.lines.lineForm.distributionMethodOfOrderedQuantity.orderedQuantityAsGoodCountOwActualCount',
          ),
        },
      ];
      const isAutoCheckOutAct: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const highlightZeroCount: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const isReworkCountEnabled: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const sheetTitle: string = this.translate.instant('excel.items.lines');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      const defaultLayoutsIndex: number = layouts.findIndex((layout: SimplifiedDataInterface) => layout.id === 1);

      if (defaultLayoutsIndex !== -1) {
        layouts[defaultLayoutsIndex].name = this.translate.instant('lines.excel.layoutsDefault');
      }

      const content: LinesExcelDropdownContentInterface = {
        distributeOrderedQty,
        microstopSensorType,
        site,
        lineTypes,
        layouts,
        shiftPlans,
        statuses,
        oeeFormula,
        countEntries,
        countTypes,
        automatedPhaseComments,
        automatedPhaseDuration,
        automatedRunDuration,
        isMissingDataAttention,
        targetExceedMessage,
        distMethodOfOrdQty,
        isAutoCheckOutAct,
        highlightZeroCount,
        isReworkCountEnabled,
        unitTypes: filters.unitTypes,
      };
      const excelOptions: CreateExcelInterface = this.getExcelColumns(content, withErrorColumn);

      if (withData) {
        excelOptions.data = (!data ? _.get(responseList, '4.data', []) : data).map((line: LineExcelInterface) => {
          return this.getExcelFormattedData(line, automatedPhaseComments, content, !_.isEmpty(data));
        });
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          { withData, name: 'lines', siteId: filters?.siteId },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadLinesExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getExcelColumns(content: LinesExcelDropdownContentInterface, withErrorColumn: boolean): CreateExcelInterface {
    const booleanDropdownOptions: ExcelDropdownInterface[] = this.excelHelper.getExcelBooleanDropdownOptions();
    const numberInputTitle: string = 'general.excel.column.promptNumberInputTitle';
    const decimalInputPrompt: string = 'general.excel.column.promptDecimalInput';
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$, this.dateCharacterFormat$);
    const planEffectiveDateFormat: string = 'DD/MM/YYYY hh:mm A';

    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [content.site],
            prop: 'siteDropdown.name',
            dataProperty: 'siteDropdown.name',
            dataId: 'siteDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineTitle'),
          key: 'title',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.activities.name'),
          key: 'activityNames',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.activities.prompt'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.status'),
          key: 'statusId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.statuses,
            prop: 'description',
            dataProperty: 'statusDropdown.description',
            dataId: 'statusDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.unit.header'),
          key: 'unit',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.unitTypes ?? [],
            prop: 'name',
            dataProperty: 'unit.name',
            dataId: 'unit.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant(this.standardSpeed),
          key: 'standardSpeed',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant(numberInputTitle, {
              field: this.translate.instant(this.standardSpeed),
            }),
            prompt: this.translate.instant(decimalInputPrompt, {
              field: this.translate.instant(this.standardSpeed),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('general.excel.column.oeeFormula'),
          key: 'oeeFormula',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.oeeFormula,
            prop: 'description',
            dataProperty: 'oeeFormulaDropdown.description',
            dataId: 'oeeFormulaDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.kpiCard.name'),
          key: 'layoutId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.layouts,
            prop: 'layoutsDropdown.name',
            dataProperty: 'layoutsDropdown.name',
            dataId: 'layoutsDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            prompt: this.translate.instant('general.excel.column.kpiCard.prompt', {
              layouts: this.translate.instant('lines.excel.layoutsDefault'),
            }),
          },
        },
        {
          header: this.translate.instant('general.excel.column.countEntry'),
          key: 'countEntryMethod',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.countEntries,
            prop: 'countEntryDropdown.description',
            dataProperty: 'countEntryDropdown.description',
            dataId: 'countEntryDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.countType'),
          key: 'manualCountType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.countTypes,
            prop: 'countTypeDropdown.description',
            dataProperty: 'countTypeDropdown.description',
            dataId: 'countTypeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineAvailability.name'),
          key: 'shiftPlanId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.shiftPlans,
            prop: 'shiftPlansDropdown.name',
            dataProperty: 'shiftPlansDropdown.name',
            dataId: 'shiftPlansDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        ...(content?.site?.isNewShiftMechanismActive
          ? [
              {
                header: this.translate.instant('settings.lines.lineForm.planEffectiveDate.label'),
                key: 'planEffectiveDate',
                width: ExcelColumnWidthEnum.DEFAULT,
                isDateTimeFormat: true,
                type: ValueType.Date,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                  allowBlank: false,
                  showErrorMessage: true,
                  error: this.translate.instant('excel.workOrderSchedule.planEffectiveDate.error'),
                  formulae: [dateFormula],
                  errorTitle: this.translate.instant('excel.workOrderSchedule.planEffectiveDate.errorTitle'),
                  errorStyle: 'Error',
                  showInputMessage: true,
                  promptTitle: this.translate.instant('excel.workOrderSchedule.planEffectiveDate.promptTitle'),
                  prompt: this.translate.instant('excel.workOrderSchedule.planEffectiveDate.prompt', {
                    date: moment().tz(this.timezone).format(planEffectiveDateFormat),
                  }),
                },
                isRequired: true,
              },
            ]
          : []),
        {
          header: this.translate.instant('settings.lines.lineForm.laborCapacity.label'),
          key: 'laborCapacity',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.isWorkOrderTakeoverBySplitActive'),
          key: 'woTakeoverBySplit',
          width: 25,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'workOrderTakeoverBySplitActiveDropdown.name',
            dataId: 'workOrderTakeoverBySplitActiveDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isWorkOrderSplitActive'),
          key: 'isWorkOrderSplitActive',
          width: 25,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'workOrderSplitActiveDropdown.name',
            dataId: 'workOrderSplitActiveDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isAutomatedPhaseCommentsActive'),
          key: 'automatedPhaseComments',
          width: ExcelColumnWidthEnum.PHASE,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedPhaseComments,
            prop: 'automatedPhaseCommentsDropdown.description',
            dataProperty: 'automatedPhaseCommentsDropdown.description',
            dataId: 'automatedPhaseCommentsDropdown.id',
            primaryKeyColumnWidth: 25,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('settings.lines.lineForm.phaseDuration.label'),
          key: 'automatedPhaseDuration',
          width: 25,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedPhaseDuration,
            prop: 'automatedPhaseDurationDropdown.description',
            dataProperty: 'automatedPhaseDurationDropdown.description',
            dataId: 'automatedPhaseDurationDropdown.id',
            primaryKeyColumnWidth: 25,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isAutomatedScheduledRunDurationUpdateActive'),
          key: 'automatedRunDuration',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedRunDuration,
            prop: 'automatedRunDurationDropdown.description',
            dataProperty: 'automatedRunDurationDropdown.description',
            dataId: 'automatedRunDurationDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineType'),
          key: 'lineType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.lineTypes,
            prop: 'lineTypesDropdown.lineType',
            dataProperty: 'lineTypesDropdown.lineType',
            dataId: 'lineTypesDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.description'),
          key: 'description',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.isDistributeOrderedQuantity'),
          key: 'distributeOrderedQty',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.distributeOrderedQty,
            prop: 'distributeOrderedQtyDropdown.description',
            dataProperty: 'distributeOrderedQtyDropdown.description',
            dataId: 'distributeOrderedQtyDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.distributionMethodOfOrderedQuantity'),
          key: 'distMethodOfOrdQty',
          width: 60,
          type: ValueType.String,
          dropdownOptions: {
            data: content.distMethodOfOrdQty,
            prop: 'distMethodOfOrdQtyDropdown.id',
            dataProperty: 'distMethodOfOrdQtyDropdown.description',
            dataId: 'distMethodOfOrdQtyDropdown.id',
            primaryKeyColumnWidth: 50,
          },
          dataValidation: {
            type: ECellTypes.LIST,
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('general.excel.column.isAutoCheckOutActiveOnLine'),
          key: 'isAutoCheckOutAct',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.isAutoCheckOutAct,
            prop: 'isAutoCheckOutActiveDropdown.description',
            dataProperty: 'isAutoCheckOutActiveDropdown.description',
            dataId: 'isAutoCheckOutActiveDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.bufferTimeForAutoCheckOut'),
          key: 'bufferTimeForAutoCheckOut',
          width: 35,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.excludeTimeForAutoCheckOut'),
          key: 'excludeTimeForAutoCheckOut',
          width: 35,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.isMissingDataAttention'),
          key: 'isMissingDataAttention',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.isMissingDataAttention,
            prop: 'isMissingDataAttentionDropdown.description',
            dataProperty: 'isMissingDataAttentionDropdown.description',
            dataId: 'isMissingDataAttentionDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isShowTaskTargetExceedErrorMessage'),
          key: 'targetExceedMessage',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.targetExceedMessage,
            prop: 'targetExceedMessageDropdown.description',
            dataProperty: 'targetExceedMessageDropdown.description',
            dataId: 'targetExceedMessageDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.microstopSensorType'),
          key: 'microstopSensorType',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.microstopSensorType,
            prop: 'microstopSensorTypeDropdown.description',
            dataProperty: 'microstopSensorTypeDropdown.description',
            dataId: 'microstopSensorTypeDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: ECellTypes.LIST,
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('general.excel.column.highlightZeroCountOnProduction'),
          key: 'highlightZeroCount',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.highlightZeroCount,
            prop: 'highlightZeroCountDropdown.description',
            dataProperty: 'highlightZeroCountDropdown.description',
            dataId: 'highlightZeroCountDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: ECellTypes.LIST,
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('settings.lines.lineForm.enableReworkCounts.label'),
          key: 'isReworkCountEnabled',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.isAutoCheckOutAct,
            prop: 'isReworkCountEnabledDropdown.description',
            dataProperty: 'isReworkCountEnabledDropdown.description',
            dataId: 'isReworkCountEnabledDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getLinesFromExcel(file: File): Promise<ILineExcelResponse | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const lineSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.lines'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const dataSheetsToBeValidated: Worksheet[] = [
      lineSheet,
      siteIdDataSheet,
      workbook.getWorksheet('microstopSensorTypeDataSheet'),
      workbook.getWorksheet('statusIdDataSheet'),
      workbook.getWorksheet('oeeFormulaDataSheet'),
      workbook.getWorksheet('layoutIdDataSheet'),
      workbook.getWorksheet('countEntryMethodDataSheet'),
      workbook.getWorksheet('manualCountTypeDataSheet'),
      workbook.getWorksheet('shiftPlanIdDataSheet'),
      workbook.getWorksheet('automatedPhaseCommentsDataSheet'),
      workbook.getWorksheet('automatedPhaseDurationDataSheet'),
      workbook.getWorksheet('automatedRunDurationDataSheet'),
      workbook.getWorksheet('lineTypeDataSheet'),
      workbook.getWorksheet('isMissingDataAttentionDataSheet'),
      workbook.getWorksheet('distributeOrderedQtyDataSheet'),
      workbook.getWorksheet('distMethodOfOrdQtyDataSheet'),
      workbook.getWorksheet('targetExceedMessageDataSheet'),
      workbook.getWorksheet('isAutoCheckOutActDataSheet'),
      workbook.getWorksheet('highlightZeroCountDataSheet'),
      workbook.getWorksheet('isReworkCountEnabledDataSheet'),
    ];

    if (!dataSheetsToBeValidated.every((dataSheet) => dataSheet)) {
      return null;
    }

    const sites: SimplifiedDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SimplifiedDataInterface>(
      siteIdDataSheet,
      {
        id: {
          key: 'id',
          type: ValueType.String,
          dataValidationType: CellTypes.CUSTOM,
        },
        name: {
          key: 'name',
          type: ValueType.String,
          dataValidationType: CellTypes.CUSTOM,
        },
      },
    );

    if (!sites.length) {
      return null;
    }

    const { columns }: CreateExcelInterface = this.getExcelColumns(
      {
        distributeOrderedQty: [],
        microstopSensorType: [],
        site: null,
        lineTypes: [],
        layouts: [],
        shiftPlans: [],
        statuses: [],
        oeeFormula: [],
        countEntries: [],
        countTypes: [],
        automatedPhaseComments: [],
        automatedPhaseDuration: [],
        automatedRunDuration: [],
        isMissingDataAttention: [],
        targetExceedMessage: [],
        distMethodOfOrdQty: [],
        isAutoCheckOutAct: [],
        unitTypes: [],
        highlightZeroCount: [],
        isReworkCountEnabled: [],
      },
      false,
    );
    const columnKeys: ExcelColumnKeysInterface = this.excelHelper.getSheetColumnKeys(columns);
    const excelLines: LinesExcelDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<LinesExcelDataInterface>(
      lineSheet,
      columnKeys,
      {
        dateFormat: this.dateFormat$,
        timeFormat: this.timeFormat$,
        timezone: this.timezone,
      },
    );

    this.activities = (await this.getActivities()).data;
    const linesRequestData: LinesExcelDataInterface[] = excelLines.map(
      (line: LinesExcelDataInterface): LinesExcelDataInterface => {
        const activityIds: number[] | null =
          line.activityNames?.split(',').map((name) => {
            return (
              _.find(this.activities, { name: name.trim(), active: 1 })?.id ??
              _.find(this.activities, { name: name.trim() })?.id ??
              0
            );
          }) ?? null;

        const distOrderedQuantity: boolean | null = line.distributeOrderedQty
          ? Boolean(TrueFalse[line.distributeOrderedQty])
          : null;

        const distMethodOfOrdQty: boolean = _.includes(
          Object.values(EDistributionMethodOfOrderedQuantity),
          line.distMethodOfOrdQty,
        );

        const isAutoCheckOutActiveOnLine: boolean | null = line.isAutoCheckOutAct
          ? Boolean(TrueFalse[line.isAutoCheckOutAct])
          : null;

        const isBufferTimeEntered: boolean =
          line.bufferTimeForAutoCheckOut !== undefined && line.bufferTimeForAutoCheckOut !== null;

        const isExcludeTimeEntered: boolean =
          line.excludeTimeForAutoCheckOut !== undefined && line.excludeTimeForAutoCheckOut !== null;

        const isReworkCountEnabled: boolean | null = line.isReworkCountEnabled
          ? Boolean(TrueFalse[String(line.isReworkCountEnabled)])
          : null;

        return {
          activityIds,
          isReworkCountEnabled,
          activityNames: line.activityNames,
          microstopSensorType: line.microstopSensorType,
          siteId: line.siteId,
          title: line.title,
          statusId: line.statusId,
          standardSpeed: line.standardSpeed === null ? '' : line.standardSpeed,
          oeeFormula: line.oeeFormula,
          countEntryMethod: line.countEntryMethod,
          manualCountType: line.manualCountType,
          shiftPlanId: line.shiftPlanId,
          planEffectiveDate: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(
            moment(line.planEffectiveDate),
          ),
          lineType: line.lineType,
          description: line.description,
          isAutomatedPhaseCommentsActive: line.automatedPhaseComments
            ? Boolean(TrueFalse[line.automatedPhaseComments])
            : null,
          isAutomatedScheduledPhaseDurationUpdateActive: line.automatedPhaseDuration
            ? Boolean(TrueFalse[line.automatedPhaseDuration])
            : null,
          isAutomatedScheduledRunDurationUpdateActive: line.automatedRunDuration
            ? Boolean(TrueFalse[line.automatedRunDuration])
            : null,
          distributeOrderedQuantity: distOrderedQuantity,
          ...(line.layoutId ? { layoutId: line.layoutId } : {}),
          ...(line.id ? { id: line.id } : {}),
          isWorkOrderSplitActive: line.isWorkOrderSplitActive,
          isWorkOrderTakeoverBySplitActive: line.woTakeoverBySplit,
          isShowTaskTargetExceedErrorMessage: line.targetExceedMessage
            ? Boolean(TrueFalse[line.targetExceedMessage])
            : null,
          meta: JSON.stringify({ isMissingDataAttention: line.isMissingDataAttention === 'Yes' ? 1 : 0 }),
          laborCapacity: line.laborCapacity,
          distributionMethodOfOrderedQuantity: distOrderedQuantity
            ? distMethodOfOrdQty
              ? line.distMethodOfOrdQty
              : 1
            : null,
          isAutoCheckOutActiveOnLine: isAutoCheckOutActiveOnLine,
          bufferTimeForAutoCheckOut: isAutoCheckOutActiveOnLine
            ? isBufferTimeEntered
              ? line.bufferTimeForAutoCheckOut
              : 0
            : null,
          excludeTimeForAutoCheckOut: isAutoCheckOutActiveOnLine
            ? isExcludeTimeEntered
              ? line.excludeTimeForAutoCheckOut
              : 0
            : null,
          unit: line.unit,
          highlightZeroCountOnProduction:
            line.microstopSensorType === null
              ? Boolean(TrueFalse['False'])
              : line.highlightZeroCount
              ? Boolean(TrueFalse[String(line.highlightZeroCount)])
              : Boolean(TrueFalse['False']),
        };
      },
    );

    return {
      linesData: {
        lines: linesRequestData,
      },
      siteData: sites,
    };
  }

  public uploadExcel(lines: LineCRUDInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.urls.BULK_SAVE_URL, {
      lines: lines.map((line: LineCRUDInterface) => _.omit(line, 'activityNames')),
    });
  }

  private getExcelFormattedData(
    line: LineExcelInterface,
    checkboxFieldDropdown: IExcelDropdownWithStringId[],
    content: LinesExcelDropdownContentInterface,
    isErrorExcel: boolean,
  ): LineExcelInterface {
    const activityNames: string = isErrorExcel ? line.activityNames : this.getSelectedActivityNames(line.activityIds);
    line.automatedPhaseComments = line.isAutomatedPhaseCommentsActive;
    line.automatedPhaseDuration = line.isAutomatedScheduledPhaseDurationUpdateActive;
    line.automatedRunDuration = line.isAutomatedScheduledRunDurationUpdateActive;
    line.distributeOrderedQty = line.distributeOrderedQuantity;
    line.targetExceedMessage = line.isShowTaskTargetExceedErrorMessage;
    line.distMethodOfOrdQty = line.distributionMethodOfOrderedQuantity;
    line.isAutoCheckOutAct = line.isAutoCheckOutActiveOnLine;
    line.isReworkCountEnabled = Boolean(line.isReworkCountEnabled);

    if (!line.layoutId && !isErrorExcel) {
      line.layoutId = line.layoutAssignment?.layoutId ?? 1;
    }

    return {
      ...line,
      ...LinesExcelService.getCheckboxDropdowns(line, checkboxFieldDropdown),
      activityNames,
      microstopSensorTypeDropdown: _.find(content.microstopSensorType, { id: line.microstopSensorType }),
      siteDropdown: content.site,
      unit: !_.isNil(line.unit) ? _.find(content.unitTypes, { id: line.unit }) : null,
      standardSpeed: this.decimalHelper.removeTrailingZeros(line.standardSpeed),
      statusDropdown: _.find(content.statuses, { id: line.statusId }),
      oeeFormulaDropdown: _.find(content.oeeFormula, { id: line.oeeFormula }),
      layoutsDropdown: _.find(content.layouts, { id: line.layoutId }),
      shiftPlansDropdown: _.find(content.shiftPlans, { id: line.shiftPlanId }),
      countEntryDropdown: _.find(content.countEntries, { id: line.countEntryMethod }),
      countTypeDropdown: _.find(content.countTypes, { id: line.manualCountType }),
      lineTypesDropdown: _.find(content.lineTypes, { id: line.lineType }),
      workOrderSplitActiveDropdown: !_.isNil(line.isWorkOrderSplitActive)
        ? _.find(this.excelHelper.getExcelBooleanDropdownOptions(), { id: String(line.isWorkOrderSplitActive) })
        : null,
      workOrderTakeoverBySplitActiveDropdown: !_.isNil(line.isWorkOrderTakeoverBySplitActive)
        ? _.find(this.excelHelper.getExcelBooleanDropdownOptions(), {
            id: String(line.isWorkOrderTakeoverBySplitActive),
          })
        : null,
      distMethodOfOrdQtyDropdown: _.find(content.distMethodOfOrdQty, { id: line.distMethodOfOrdQty }),
      planEffectiveDate: this.helperService.convertFromISOFormatToGivenTimezone(line.planEffectiveDate),
    };
  }

  private static getCheckboxDropdowns(
    line,
    checkboxFieldDropdown: IExcelDropdownWithStringId[],
  ): {
    automatedPhaseCommentsDropdown: ExcelDropdownDataWithDescriptionFormatted;
    automatedPhaseDurationDropdown: ExcelDropdownDataWithDescriptionFormatted;
    automatedRunDurationDropdown: ExcelDropdownDataWithDescriptionFormatted;
    distributeOrderedQtyDropdown: ExcelDropdownDataWithDescriptionFormatted;
    isMissingDataAttentionDropdown: ExcelDropdownDataWithDescriptionFormatted;
    targetExceedMessageDropdown: ExcelDropdownDataWithDescriptionFormatted;
    isAutoCheckOutActiveDropdown: ExcelDropdownDataWithDescriptionFormatted;
    highlightZeroCountDropdown: ExcelDropdownDataWithDescriptionFormatted;
    isReworkCountEnabledDropdown: ExcelDropdownDataWithDescriptionFormatted;
  } {
    const automatedPhaseComments: ExcelDropdownDataWithDescriptionFormatted | null =
      line.isAutomatedPhaseCommentsActive !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedPhaseCommentsActive)] })
        : null;
    const automatedRunDuration: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.isAutomatedScheduledRunDurationUpdateActive,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedScheduledRunDurationUpdateActive)] })
      : null;

    const distributeOrderedQty: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.distributeOrderedQuantity,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.distributeOrderedQuantity)] })
      : null;

    const automatedPhaseDuration =
      line.isAutomatedPhaseDurationActive !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedScheduledPhaseDurationUpdateActive)] })
        : null;

    const isMissingDataAttention: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.meta?.isMissingDataAttention,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.meta?.isMissingDataAttention)] })
      : _.find(checkboxFieldDropdown, { id: 'False' });

    const targetExceedMessage: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.isShowTaskTargetExceedErrorMessage,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isShowTaskTargetExceedErrorMessage)] })
      : null;

    const isAutoCheckOutAct: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.isAutoCheckOutActiveOnLine,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutoCheckOutActiveOnLine)] })
      : null;

    const highlightZeroCountOnProduction: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.highlightZeroCountOnProduction,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.highlightZeroCountOnProduction)] })
      : null;

    const isReworkCountEnabled: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(line.isReworkCountEnabled)
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isReworkCountEnabled)] })
      : null;

    return {
      automatedPhaseCommentsDropdown: {
        ...automatedPhaseComments,
        id: automatedPhaseComments !== null ? automatedPhaseComments.id : null,
      },
      automatedPhaseDurationDropdown: {
        ...automatedPhaseDuration,
        id: automatedPhaseDuration !== null ? TrueFalse[automatedPhaseDuration.id] : null,
      },
      automatedRunDurationDropdown: {
        ...automatedRunDuration,
        id: automatedRunDuration !== null ? automatedRunDuration.id : null,
      },
      distributeOrderedQtyDropdown: {
        ...distributeOrderedQty,
        id: distributeOrderedQty !== null ? distributeOrderedQty.id : null,
      },
      isMissingDataAttentionDropdown: {
        ...isMissingDataAttention,
        id: isMissingDataAttention !== null ? isMissingDataAttention.id : null,
      },
      targetExceedMessageDropdown: {
        ...targetExceedMessage,
        id: targetExceedMessage !== null ? targetExceedMessage.id : null,
      },
      isAutoCheckOutActiveDropdown: {
        ...isAutoCheckOutAct,
        id: isAutoCheckOutAct !== null ? isAutoCheckOutAct.id : null,
      },
      highlightZeroCountDropdown: {
        ...highlightZeroCountOnProduction,
        id: highlightZeroCountOnProduction !== null ? highlightZeroCountOnProduction.id : null,
      },
      isReworkCountEnabledDropdown: {
        ...isReworkCountEnabled,
        id: isReworkCountEnabled !== null ? isReworkCountEnabled.id : null,
      },
    };
  }

  private getSelectedActivityNames(activityIds: string): string | null {
    return (
      activityIds
        ?.split(',')
        .map((activityId: string) => {
          return _.find(this.activities, { id: Number(activityId) })?.name;
        })
        .filter((activityId: number) => activityId)
        .join(', ') ?? null
    );
  }
}

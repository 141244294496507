<ng-template #feedback_modal>
  <div id="feedback-modal">
    <div class="modal-header feedback-modal-header">
      <h5 class="modal-title">{{ feedBackData.questionGroup[0].question.title | translate }}</h5>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <h6 class="scw-header">{{ feedBackData.questionGroup[0].question.questionText | translate }}</h6>
        </div>
        <div class="col-12">
          <div class="star-rating-container star-rating-animation">
            <div *ngFor="let star of stars"
                 [ngClass]="(!selectedRating || star.value > selectedRating) ? ratingStarClass : ratingStarHoverClass"
                 (click)="selectStar(star.value)">
              <mat-icon>star</mat-icon>
              <span class="star-hint">{{ star.hint | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 mt-1">
          <scw-mat-textarea
            [rows]="6"
            [block]="true"
            [rules]="[{ maxLength: 65535 }]"
            [placeholder]="textPlaceholder"
            [(inputModel)]="feedbackText"
          ></scw-mat-textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button (onClick)="dismissModal()" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button (onClick)="submitFeedback()" [disabled]="selectedRating === null">
          Submit
        </scw-mat-button>
      </div>
    </div>
  </div>
</ng-template>

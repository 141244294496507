<div
  class="bg-c-lightgrey metric-item d-flex flex-column h-100 p-2"
  (click)="metricIsClicked()"
  [ngClass]="isPhaseMetric && !dashboardMode ? 'cursor-pointer' : ''"
>
  <div class="nowrap text-center d-contents">
    <div *ngIf="isPhaseMetric && !dashboardMode" class="comment-container">
      <em
        class="{{
          !dashboardMode
            ? phaseComment$ !== null
              ? 'c-title far fa-comment-dots'
              : 'c-title fas fa-comment-medical'
            : ''
        }} "
        [class.phase-comment-is-not-entered]="isPhaseCommentEntered$ !== null && !isPhaseCommentEntered$"
      ></em>
    </div>
    <p class="head-text-2 three-dot c-title m-b-0">
      {{ progressBarTitle }}
      <ng-container *ngFor="let icon of icons">
        <em [ngClass]="icon"></em>
      </ng-container>
    </p>
   <p *ngIf="titleBracketInfo" class="head-text-2 three-dot c-title empty-space">({{ titleBracketInfo }})</p>
    <br *ngIf="!titleBracketInfo">
    <div class="d-contents">
      <p class="head-text three-dot m-b-5" [ngClass]="actualValueClass" [ngStyle]="{ color: actualValueColor }">
        {{ actualValue | callFunction : getFormattedValue: [actualValueUnit] }}
      </p>
      <p class="metric-info" *ngIf="showMetricInfo && showExpectedValue">{{ actualValueInfoText }}</p>
      <p class="head-text-3 three-dot c-darkgrey m-b-5">
        <span *ngIf="showExpectedValue">/{{ expectedValue | callFunction : getFormattedValue : [expectedValueUnit] }} </span>
      </p>
      <p class="metric-info" *ngIf="showMetricInfo && showExpectedValue">{{ expectedValueInfoText }}</p>
      <div
        class="progress-box bar-metric d-flex flex-row justify-content-center align-items-center"
        *ngIf="showProgressBar"
      >
        <div class="progress d-inline-block flex-grow-1 ml-0 d-flex align-items-center">
          <div class="progress-bar-text-block position-absolute progress-rate f-w-500 d-flex justify-content-end w-100">
            <p class="progress-bar-text text-truncate">&nbsp;{{ progressBarRate | callFunction: getFormattedValue }}<span> %</span></p>
          </div>
          <div class="progress-bar" [ngClass]="progressBarClass" [style.width.%]="progressBarRateCapped"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #work_order_phase_comment let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'homeScreenMetrics.phaseComment.title' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <work-order-phase-comment-modal
    [phase]="phaseField"
    [phaseType]="durationType"
    [phaseComment]="phaseComment$"
    (cancelButtonClicked)="modal.dismiss()"
    (isSubmitFromDeleteEmitter)="isSubmitFromDelete($event)"
  ></work-order-phase-comment-modal>
</ng-template>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ECicoAvailability,
  ECicoDestinationType,
  ECicoDestinationTypeId,
  ECicoType,
  EProgressBarType,
  IChangeStation,
  IFormattedCicoData,
  IProgressBar,
} from '../cico.model';
import { User, UserLevelInterface } from '../../../../store/user/model';
import { UserSettingsService } from '../../../../store/settings/users/user.service';
import * as _ from 'lodash';
import { animate, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { filter, take, takeWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ILaborAssetViewCheckIn } from '../../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { getStatusColor, getStatusName } from '../cico.utilities';
import {
  LineStationGetOneCRUDDataInterface,
  LineStationStateInterface,
} from '../../../../store/line-station/line-station.model';
import * as CheckInActions from '../../../../store/check-in/check-in.actions';
import { ICheckOut } from '../../../../store/check-in/check-in.model';
import { mysqlTimestampFormat } from '../../../helper/date';
import { TagInterface, TagsStateInterface } from '../../../../store/settings/tags/tags.model';
import { CheckInOngoingUpdateInterface } from '../../../../view/home/cico/cico.model';
import * as AppActions from '../../../../store/app/actions';
import { activityColors } from '../../../helper/app-helper';
import { Router } from '@angular/router';
import { HelperService } from '../../../service/helper.service';

@Component({
  selector: 'scw-cico-item',
  templateUrl: './cico-item.component.html',
  styleUrls: ['./cico-item.component.scss', '../../../../../scss/custom.scss'],
  animations: [
    trigger('expandableState', [
      transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),
        animate('200ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        animate('200ms', style({ height: '0', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CicoItemComponent implements OnInit, OnChanges {
  @Input() cicoData: IFormattedCicoData;
  @Input() progressBarText: string = this.translate.instant('cico.operatingTimeAndShiftDuration');
  @Input() isExpanded: boolean = true;
  @Input() selectMode: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() showWorkOrderRow: boolean = true;
  @Input() isMatMenuVisible: boolean = true;
  @Output() changeStationEmitter: EventEmitter<IChangeStation> = new EventEmitter<IChangeStation>();

  private readonly levels: UserLevelInterface[] = this.userSettingsService.getLevels();
  public readonly shortHour: string = this.translate.instant('general.shortHour');
  public readonly DECIMAL_DEFAULT_SCALE_LIMIT: number = DECIMAL_DEFAULT_SCALE_LIMIT;
  public readonly cicoDestinationType: typeof ECicoDestinationType = ECicoDestinationType;
  public readonly cicoType: typeof ECicoType = ECicoType;
  public readonly cicoAvailability: typeof ECicoAvailability = ECicoAvailability;
  public readonly progressBarType: typeof EProgressBarType = EProgressBarType;
  public readonly getStatusName = getStatusName;
  public readonly idleTimeColor: string = activityColors('idleTime');
  public timeFormat$: string;
  public dateTimeFormat$: string;
  public level: UserLevelInterface;
  public scheduledVsActualDiff: number;
  public lineStations: Partial<LineStationGetOneCRUDDataInterface>[] = [];
  public tags: Partial<TagInterface>[] = [];
  public progressBars: IProgressBar[] = [];
  public clickedBarContext!: IProgressBar;
  public isUserLate: boolean = false;
  public statusColor: string = activityColors('idleTime');
  public statusOppositeColor: string = activityColors('idleTime');
  public clickable: boolean = false;

  constructor(
    public readonly helperService: HelperService,
    private readonly userSettingsService: UserSettingsService,
    private readonly translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private router: Router,
  ) {
    this.clickable = !this.router.url.includes('home');
  }

  public ngOnInit(): void {
    this.store
      .select('lineStationStore')
      .pipe(
        filter((state: LineStationStateInterface) => !state.lineStationLoading && state.lineStationLoaded),
        takeWhile(() => !this.lineStations.length),
      )
      .subscribe((state: LineStationStateInterface): void => {
        this.lineStations = [
          ...(this.cicoData.destination.type !== ECicoDestinationType.LINE
            ? [{ id: null, name: this.translate.instant('cico.menu.changeToLine'), lineId: this.cicoData.lineId }]
            : []),
          ...state.lineStationData.data,
        ].filter(
          (data: LineStationGetOneCRUDDataInterface): boolean =>
            Number(data.lineId) === Number(this.cicoData.lineId) &&
            data.id !== this.cicoData.destination.object.objectId,
        );
      });

    this.store
      .select('tagStore')
      .pipe(
        filter((state: TagsStateInterface) => !state.tagsLoading && state.tagsLoaded),
        takeWhile(() => !this.tags.length),
      )
      .subscribe((state: TagsStateInterface): void => {
        this.tags = [
          {
            id: null,
            name: this.translate.instant('general.noStatus'),
            color: null,
          },
          ...(this.cicoData.tagIds
            ? state.tagsData.data.filter((tag: Partial<TagInterface>): boolean => this.cicoData.tagIds.includes(tag.id))
            : []),
        ]
          .filter((tag: TagInterface): boolean => Number(tag.id) !== Number(this.cicoData.tagId))
          .map((tag: TagInterface): TagInterface => {
            return { ...tag, color: getStatusColor(ECicoType.ASSET, null, tag.color) };
          });
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('cicoData') && this.cicoData) {
      if (this.cicoData?.meta && this.cicoData.meta.levelId) {
        this.level = _.find(this.levels, { id: this.cicoData.meta.levelId });
      }

      if (this.cicoData.scheduledCheckInTime) {
        this.progressBarText = this.translate.instant('cico.operatingTimeAndScheduledDuration');
      }

      this.isUserLate = CicoItemComponent.checkIsUserLate(
        this.cicoData.scheduledCheckInTime,
        this.cicoData.actualCheckInTime,
      );
      this.statusColor = getStatusColor(this.cicoData.type, this.cicoData.status, this.cicoData.tagColor);
      this.statusOppositeColor = getStatusColor(
        this.cicoData.type,
        this.cicoData.status === ECicoAvailability.busy ? ECicoAvailability.available : ECicoAvailability.busy,
        this.cicoData.tagColor,
      );

      this.calculateBarWidth();
    }
  }

  public onClickChangeStatus(status: ECicoAvailability, tagId?: number): void {
    const data: CheckInOngoingUpdateInterface = {
      status: this.cicoData.type === ECicoType.LABOR ? (status === ECicoAvailability.available ? 1 : 0) : tagId,
      destinationTypeId:
        this.cicoData.destination.type === ECicoDestinationType.LINE
          ? ECicoDestinationTypeId.LINE
          : ECicoDestinationTypeId.STATION,
      destinationObjectId: this.cicoData.destination.object.objectId,
    };

    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new CheckInActions.CheckOutUpdateOngoingLoading(data, this.cicoData.id));
  }

  public onClickChangeStation(stationId: number, stationName: string): void {
    this.changeStationEmitter.emit({
      id: this.cicoData.id,
      type: this.cicoData.type,
      status: this.cicoData.status,
      tagId: this.cicoData.tagId,
      fromType: this.cicoData.destination.type,
      fromObjectId: this.cicoData.destination.object.objectId,
      fromObjectName:
        this.cicoData.destination.type === ECicoDestinationType.STATION
          ? `${this.cicoData.destination.object.lineName} - ${this.cicoData.destination.object.objectName}`
          : this.cicoData.destination.object.objectName,
      toType: stationId ? ECicoDestinationType.STATION : ECicoDestinationType.LINE,
      toObjectId: stationId || this.cicoData.lineId,
      toObjectName: stationId
        ? `${this.cicoData.destination.object.lineName} - ${stationName}`
        : this.cicoData.destination.object.lineName,
    });
  }

  public onClickCheckOut(): void {
    const checkOutData: ICheckOut = {
      checkOutTime: this.helperService.nowAsISO(),
    };

    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new CheckInActions.CheckOutLoading(this.cicoData.id, checkOutData));
  }

  private calculateBarWidth(): void {
    // TODO on these changes, check scheduledCheckInTime....(for labor tracker)
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User): void => {
        this.timeFormat$ = user.timeFormatHourAndMinute;
        this.dateTimeFormat$ = user.dateTimeFormat;
      });

    const totalDuration: number = Number(this.cicoData.scheduledDuration || this.cicoData.shiftDuration);
    this.scheduledVsActualDiff = this.cicoData.scheduledCheckInTime
      ? moment(this.cicoData.checkInTime).diff(moment(this.cicoData.scheduledCheckInTime), 'seconds')
      : 0;

    if (totalDuration === 0) {
      this.progressBars = [];

      return;
    }

    this.cicoData.checkIns.forEach((checkInRecord: ILaborAssetViewCheckIn, index: number): void => {
      const timezoneConvertedDateTimes = {
        checkInTime: this.helperService.convertFromISOFormatToGivenTimezone(checkInRecord?.checkInTime),
        checkOutTime: this.helperService.convertFromISOFormatToGivenTimezone(checkInRecord?.checkOutTime),
        calculatedCheckInTime: this.helperService.convertFromISOFormatToGivenTimezone(
          checkInRecord?.calculatedCheckInTime,
        ),
        calculatedCheckOutTime: this.helperService.convertFromISOFormatToGivenTimezone(
          checkInRecord?.calculatedCheckOutTime,
        ),
      };

      if (index === 0) {
        const checkInTime: moment.Moment = moment(timezoneConvertedDateTimes.checkInTime);
        const startDate: moment.Moment = moment(
          this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.scheduledCheckInTime) ||
            this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.shiftStartDate),
        );

        if (checkInTime.isAfter(startDate)) {
          const diff: number = checkInTime.diff(startDate, 'seconds');

          this.progressBars.push({
            width: (diff / totalDuration) * 100,
            type: EProgressBarType.GAP,
            start: CicoItemComponent.formatDate(
              this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.scheduledCheckInTime) ||
                this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.shiftStartDate),
              this.timeFormat$,
            ),
            end: CicoItemComponent.formatDate(timezoneConvertedDateTimes.checkInTime, this.timeFormat$),
          });
        }
      }

      if (index > 0) {
        const diff: number = moment(timezoneConvertedDateTimes.checkInTime).diff(
          moment(this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.checkIns[index - 1].checkOutTime)),
          'seconds',
        );

        if (diff > 0) {
          this.progressBars.push({
            width: (diff / totalDuration) * 100,
            type: EProgressBarType.GAP,
            start: CicoItemComponent.formatDate(
              this.helperService.convertFromISOFormatToGivenTimezone(this.cicoData.checkIns[index - 1].checkOutTime),
              this.timeFormat$,
            ),
            end: CicoItemComponent.formatDate(timezoneConvertedDateTimes.checkInTime, this.timeFormat$),
          });
        }
      }

      this.progressBars.push({
        width: (Number(checkInRecord.duration) / totalDuration) * 100,
        type: EProgressBarType.ENTRY,
        start: CicoItemComponent.formatDate(
          index === 0 ? timezoneConvertedDateTimes.checkInTime : timezoneConvertedDateTimes.calculatedCheckInTime,
          !moment(timezoneConvertedDateTimes.checkInTime).isSame(moment(), 'day') ? this.dateTimeFormat$ : this.timeFormat$,
        ),
        end: timezoneConvertedDateTimes.checkOutTime
          ? CicoItemComponent.formatDate(
              timezoneConvertedDateTimes.calculatedCheckOutTime,
              moment(timezoneConvertedDateTimes.calculatedCheckOutTime).isSame(moment(), 'day')
                ? this.timeFormat$
                : this.dateTimeFormat$,
            )
          : null,
      });

      if (index + 1 === this.cicoData.checkIns.length && timezoneConvertedDateTimes.checkOutTime) {
        const diff: number = moment().diff(moment(timezoneConvertedDateTimes.checkOutTime), 'seconds');

        if (diff > 0) {
          this.progressBars.push({
            width: (diff / totalDuration) * 100,
            type: EProgressBarType.GAP,
            start: CicoItemComponent.formatDate(timezoneConvertedDateTimes.checkOutTime, this.timeFormat$),
            end: null,
          });
        }
      }
    });
  }

  public static formatDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  public static checkIsUserLate(scheduledCheckInTime: string, actualCheckInTime: string): boolean {
    if (!scheduledCheckInTime) {
      return false;
    }

    const scheduledTime: moment.Moment = moment(scheduledCheckInTime);
    const checkInTime: moment.Moment = moment(actualCheckInTime);

    return checkInTime.isAfter(scheduledTime);
  }

  public redirectToLaborOrAssetLogs(): void {
    if (this.clickable) {
      const route: string = this.cicoData.type === ECicoType.LABOR ? 'labor-logs' : 'asset-logs';
      this.store.dispatch(new CheckInActions.UpdateFilterState(this.cicoData.sourceObjectId));
      this.router.navigate([`/reports/${route}`]);
    }
  }
}

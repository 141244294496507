import { ETableModalTypes } from '../../../../shared/service/datatable/datatable.model';
import { WorkOrderScheduleInterface } from '../../../../store/work-order-schedule/work-order-schedule.model';
import {
  IDateInputForm,
  IDropdownForm,
  IRadioGroupInputForm,
  IStringInputForm,
} from '../../../../shared/model/interface/scw-form.model';

export interface ConvertedCountEntriesInterface {
  goodCount: string;
  scrapCount: string;
}

export interface CountEntriesInterface {
  entry1: string;
  entry2: string;
  reworkCount: string;
}

export interface ManualCountPostDataInterface extends ConvertedCountEntriesInterface {
  workOrderId: number;
  timestamp: string;
  lineId: number;
  reworkCount: string;
}

export interface IManualCountResponse {
  productionCounts: RegularOrHourlyManualCount[];
  manualCountType: number;
  countEntryMethod: number;
  siteDecimalScaleLimit?: number;
  totalGoodCount: string;
  totalScrapCount: string;
  totalReworkCount: string;
  count: number;
  page: number;
  total: number;
  pageCount: number;
}

export interface IHourlyManualCount {
  id: number;
  workOrderId: number;
  createdAt: string;
  timestamp: string;
  goodCount: string;
  scrapCount: string;
  reworkCountValue: string;
  productionCountCreatedBy: string;
  activityExistsOnTimestamp: number;
  countEntry1: string;
  countEntry2: string;
  reworkCount: string;
  checkedInUsers: ICheckedInUsers[] | string;
  isSystem: number;
  isApproved: number;
  isOngoingHour: number;
  commentId: number;
  comment: string;
  originalTimestamp?: string;
}

export interface IManualCount extends IHourlyManualCount {
  shiftId: number | null;
  shiftName: string | null;
  isInvalidatedByApproved: number;
  tags?: number[];
}

export interface ICheckedInUsers {
  id: number;
  fullName: string;
}

export interface IQuantityForm {
  timestamp: IDateInputForm;
  countEntry1: IStringInputForm;
  countEntry2: IStringInputForm;
  reworkCount: IStringInputForm;
  incrementDistributionMethod: IRadioGroupInputForm<IncrementDistributionMethod>;
  tags: IDropdownForm;
  commentMessage: IStringInputForm;
}

export interface IMinManualCount {
  countEntry1: string;
  countEntry2: string;
  reworkCount: string;
}

export interface IWorkOrderManualCountDeleteMany {
  productionCounts: IDeletedWorkOrderManualCounts[];
}

export interface IDeletedWorkOrderManualCounts {
  productionCountId: number;
  workOrderId: number;
}

export interface ICreateManualCount {
  timestamp: string;
  workOrderId: number;
  lineId: number;
  goodCount: string;
  scrapCount: string;
  reworkCount: string;
}

export interface ISetHourlyCount extends ICreateManualCount {
  shiftId?: number;
  backToSystemCount?: boolean;
}

export enum ManualCountModalTypes {
  setFinal = 'setFinal',
  setHourly = 'setHourly',
  ongoingDistribution = 'ongoingDistribution',
}
export type TQuantityModalType = ETableModalTypes | ManualCountModalTypes;

export interface IWorkOrderManualCountComment {
  id: number | null;
  commentTypeId: number;
  objectType: string;
  objectId: number;
  commentMessage: string;
  commentDate?: string | null;
  objectProperty?: string;
  siteId?: number;
  workOrderId?: number;
  shiftId?: number;
  shiftDay?: string;
  tags?: number[];
}

export interface ICommentsResponse {
  success: boolean;
}

export enum EInputValidationLimitValues {
  standardMaxValue = 2000000000,
  standardMinValue = 0,
  errorMaxValue = -999,
}

export interface IDistributionData {
  goodCount: string;
  scrapCount: string;
  reworkCount: string;
  workOrderId: number;
  incrementDistributionMethod?: IncrementDistributionMethod;
}

export interface IDistributionRequest extends IDistributionData {
  doApproveOngoingShiftHour: boolean;
}

export interface IDistributionResponse {
  timestamp: string;
  goodCount: string;
  scrapCount: string;
  reworkCount: string;
  isChanged: boolean;
  isDeletionDone: boolean;
}

export interface IProductionCountCrudData {
  id: number;
  createdBy: number | null;
  createdAt: string | null;
  changedBy: number | null;
  changedAt: string | null;
  workOrderId: number | null;
  goodCount: string | null;
  scrapCount: string | null;
  timestamp: string;
  checkedInUsers: ICheckedInUsersDto;
  shiftId: number;
  countType: EProductionCountType;
  lineId: number | null;
  workOrderSchedule: WorkOrderScheduleInterface;
  schedulerShift: ISchedulerShift;
}

export interface ICheckedInUsersDto {
  users: number[] | null;
}

export interface ISchedulerShift {
  createdBy: number | null;
  createdAt: string | null;
  changedBy: number | null;
  changedAt: string | null;
}

export type CompareData = Pick<IManualCount, 'timestamp' | 'countEntry1' | 'countEntry2' | 'reworkCount'> &
  Pick<IDistributionResponse, 'isChanged' | 'isDeletionDone'>;
export type RegularOrHourlyManualCount = IManualCount | IHourlyManualCount;
export type ManualCountTableData = RegularOrHourlyManualCount & {
  isSelected?: boolean;
  suggestedCountEntry1?: string;
  suggestedCountEntry2?: string;
  suggestedReworkCount?: string;
  isDisabled: boolean;
  elementId: string;
} & Pick<IDistributionResponse, 'isChanged' | 'isDeletionDone'>;

export enum CountModalType {
  countEntry,
  finalDistribution,
  ongoingDistribution,
}

export enum IncrementDistributionMethod {
  runTimes = 'runTimes',
  runTimesIfAvailable = 'runTimesIfAvailable',
  allActivities = 'allActivities',
  lastHour = 'lastHour',
}

export enum EProductionCountType {
  Regular = 0,
  System = 1,
  Approved = 2,
}

import { Line } from './model';
import * as Actions from './actions';

export const initialState: Line = {
  missingDataCount: null,
  productId: null,
  productDescription: null,
  workOrderId: null,
  workOrderQuantityOrdered: null,
  workOrderTarget: null,
  workOrderGoodCount: null,
  workOrderDuration: null,
  averageSpeed: null,
  expectedSpeed: null,
  activityAverageSpeed: null,
  activityId: null,
  activityName: null,
  activityNameTranslations: null,
  activityStart: null,
  activityHistoryActivityStart: null,
  activitySubType: null,
  activityTarget: null,
  activityType: null,
  activityCrewSize: null,
  activityDescription: null,
  batchFinalizer: null,
  equipmentName: null,
  goodCount: null,
  ignoreSensor: null,
  initialCount: null,
  scrapCount: null,
  taskId: null,
  taskName: null,
  taskNameTranslations: null,
  wOTableId: null,
  woDueDate: null,
  versionId: null,
  versionName: null,
  productTableId: null,
  unitName: null,
  unitId: null,
  lineChanged: null,
  countEntryMethod: 1,
  manualCountType: 1,
  ignorePhase: null,
  selectedPhase: null,
  lineType: null,
  isMissingData: null,
  isDurationExceedCommentRequired: null,
  isCommentRequired: null,
  missingActivityMode: null,
  goodSpeed: null,
  woSpeed: null,
  quantityWithCommentCount: null,
  originalActivityEntityProperty: null,
  oeeFormula: null,
  quantities: {
    goodCount: null,
    initialCount: null,
    scrapCount: null,
    reworkCount: null,
    initialSensorCountInOneMinute: null,
    initialSensorCountInTenMinutes: null,
    initialSensorCount: null,
    goodSensorCountInOneMinute: null,
    goodSensorCountInTenMinutes: null,
    goodSensorCount: null,
    scrapSensorCount: null,
    runPhaseDuration: null,
    postRunPhaseDuration: null,
    preRunPhaseDuration: null,
    totalDuration: null,
    runDuration: null,
    downDuration: null,
    idleDuration: null,
    unplannedDownTimeDuration: null,
    hourlyOee: [],
    quantityWithCommentCount: null,
    batchAverageSpeed: null,
    batchGoodSpeed: null,
    batchSpeed: null,
    runTimeAverageSpeed: null,
    oee: null,
    scheduledRunDuration: null,
    scheduledPreRunDuration: null,
    scheduledPostRunDuration: null,
    oeeValueFullScope: null,
    oeeValueOf6Hours: null,
    oeeValueOf12Hours: null,
    currentShiftInitialCount: null,
    currentShiftGoodCount: null,
    shiftCount: null,
    matrixBasedPostRunDuration: null,
    quantityBasedRunDuration: null,
    matrixBasedPreRunDuration: null,
    currentShiftName: null,
    shiftBasedOee: null,
    temperatures: [],
    humidities: [],
    checkMicroStop: false,
    microStopSensorType: null,
    preRunTaskTargetDuration: null,
    postRunTaskTargetDuration: null,
    taskTarget: null,
  },
  auditLogUuid: null,
};

export function lineReducer(state: Line = initialState, action: Actions.LineActions) {
  switch (action.type) {
    case Actions.SET_MISSING_DATA_COUNT:
      return {
        ...state,
        ...{ missingDataCount: action.missingDataCount },
      };

    case Actions.SET_WORK_ORDER_CARDS:
      return {
        ...state,
        ...{
          productId: action.payload.productId,
          productDescription: action.payload.productDescription,
          workOrderId: action.payload.workOrderId,
          workOrderQuantityOrdered: action.payload.workOrderQuantityOrdered,
          workOrderTarget: action.payload.workOrderTarget,
          expectedSpeed: action.payload.expectedSpeed,
          wOTableId: action.payload.wOTableId,
          woDueDate: action.payload.woDueDate,
          versionId: action.payload.versionId,
          versionName: action.payload.versionName,
          productTableId: action.payload.productTableId,
          unitName: action.payload.unitName,
          unitId: action.payload.unitId,
          woSpeed: action.payload.woSpeed,
          goodSpeed: action.payload.goodSpeed,
          quantityWithCommentCount: action.payload.quantityWithCommentCount,
        },
      };

    case Actions.SET_ACTIVITY_CARD_DATA:
      return {
        ...state,
        ...{
          activityId: action.payload.activityId,
          activityName: action.payload.activityName,
          activityNameTranslations: action.payload.activityNameTranslations,
          activityStart: action.payload.activityStart,
          activityHistoryActivityStart: action.payload.activityHistoryActivityStart,
          activitySubType: action.payload.activitySubType,
          activityTarget: action.payload.activityTarget,
          activityType: action.payload.activityType,
          activityCrewSize: action.payload.activityCrewSize,
          activityDescription: action.payload.activityDescription,
          batchFinalizer: action.payload.batchFinalizer,
          equipmentName: action.payload.equipmentName,
          ignoreSensor: action.payload.ignoreSensor,
          taskId: action.payload.taskId,
          taskName: action.payload.taskName,
          taskNameTranslations: action.payload.taskNameTranslations,
          ignorePhase: action.payload.ignorePhase,
          selectedPhase: action.payload.selectedPhase,
          isMissingData: action.payload.isMissingData,
          isCommentRequired: action.payload.isCommentRequired,
          isDurationExceedCommentRequired: action.payload.isDurationExceedCommentRequired,
          missingActivityMode: action.payload.isMissingData,
          originalActivityEntityProperty: action.payload.originalActivityEntityProperty,
          auditLogUuid: action.payload.auditLogUuid,
        },
      };

    case Actions.SET_QUANTITY_FROM_SENSOR:
      return {
        ...state,
        ...action.payload,
      };

    case Actions.UPDATE_ACTIVITY:
      return {
        ...state,
        ...action.payload,
      };

    case Actions.SET_MANUAL_COUNT_ENTRY_SETTINGS:
      return {
        ...state,
        countEntryMethod: action.payload.countEntryMethod,
        manualCountType: action.payload.manualCountType,
      };
    case Actions.SET_LINE_TYPE:
      return {
        ...state,
        lineType: Number(action.lineType),
      };
    case Actions.CLOSE_MISSING_ACTIVITY_MODE:
      return {
        ...state,
        missingActivityMode: false,
      };
    case Actions.SET_LINE_CHANGED:
      return {
        ...state,
        lineChanged: action.lineChanged,
      };
    case Actions.SET_QUANTITIES:
      return {
        ...state,
        quantities: action.quantities,
      };
    default:
      return state;
  }
}

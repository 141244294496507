import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  ECicoAvailability,
  ECicoDestinationType,
  ECicoDestinationTypeId,
  ECicoType,
  IAvatarToLoad,
  IChangeStation,
  ICicoData,
  IFormattedCicoData,
} from './cico.model';
import { TranslateService } from '@ngx-translate/core';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import * as moment from 'moment';
import { ActionsSubject, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { User } from '../../../store/user/model';
import { DECIMAL_DEFAULT_SCALE_LIMIT, smallModal } from '../../../../constants';
import * as _ from 'lodash';
import * as UserActions from '../../../store/settings/users/users.actions';
import * as EquipmentSettingsActions from '../../../store/settings/equipments/equipments.actions';
import { Subject, Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { ImageHelperService } from '../../helper/image-helper.service';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { TCacheOptions } from '../../service/cache-service';
import * as LineStationActions from '../../../store/line-station/line-station.actions';
import * as TagActions from '../../../store/settings/tags/tags.actions';
import { TagsObjectTypes, TagsStateInterface } from '../../../store/settings/tags/tags.model';
import { InputLimit } from '../../model/enum/input-limit';
import { CheckInOngoingUpdateInterface } from '../../../view/home/cico/cico.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as AppActions from '../../../store/app/actions';
import * as CheckInActions from '../../../store/check-in/check-in.actions';
import { LineStationStateInterface } from '../../../store/line-station/line-station.model';
import * as LaborAssetHomeActions from '../../../store/home/labor-asset-home/labor-asset-home.actions';
import { HelperService } from '../../service/helper.service';

@Component({
  selector: 'scw-cico',
  templateUrl: './cico.component.html',
  styleUrls: ['./cico.component.scss'],
})
export class CicoComponent implements OnChanges, OnDestroy {
  @Input() cicoData: ICicoData[] = [];
  @Input() progressBarText: string | undefined;
  @Input() isExpanded: boolean = true;
  @Input() selectMode: boolean = false;
  @Input() showWorkOrderRow: boolean = true;
  @Input() isMatMenuVisible: boolean = true;
  @Input() isCollapsedGroups: boolean = false;
  @Output() selectedItemsChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  private readonly shortHour: string = this.translate.instant('general.shortHour');
  private readonly subscriptions: Subscription[] = [];
  private readonly defaultAvatarCacheOptions: TCacheOptions = {
    ttl: 24,
    ttlUnit: 'hours',
  };
  private avatarsToLoad: IAvatarToLoad[] = [];
  private modifiedCicoData: IFormattedCicoData[] = [];
  private timeFormat$!: string;
  private dateTimeFormat$!: string;
  private changeStationModalRef: NgbModalRef;
  private selectedItems: number[] = [];
  protected readonly ECicoType = ECicoType;
  public readonly InputLimit: typeof InputLimit = InputLimit;
  public readonly laborModuleTranslation: string = this.translate.instant('cico.modules.laborTracker.name');
  public readonly assetModuleTranslation: string = this.translate.instant('cico.modules.assetTracker.name');
  public formattedCicoData: IFormattedCicoData[] = [];
  public checkInPin: string = null;
  public changeStationData: IChangeStation;

  constructor(
    private readonly translate: TranslateService,
    private readonly decimalHelper: DecimalHelper,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions: ActionsSubject,
    private readonly modal: NgbModal,
    public readonly helperService: HelperService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.hasOwnProperty('selectMode') && !this.selectMode) || changes.hasOwnProperty('cicoData')) {
      this.selectedItemsChanged.emit((this.selectedItems = []));
    }

    this.prepareCicoData();
  }

  private prepareCicoData(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User): void => {
        this.dateTimeFormat$ = user.dateTimeFormat;
        this.timeFormat$ = user.timeFormatHourAndMinute;
      });

    const lines: number[] = [];
    const sites: number[] = [];
    let assetExists: boolean = false;

    this.formattedCicoData = this.modifiedCicoData = this.cicoData
      .filter(
        (cicoEntry: ICicoData): boolean =>
          !this.selectMode || (cicoEntry.type === ECicoType.LABOR && cicoEntry.status !== ECicoAvailability.offline),
      )
      .map((cicoEntry: ICicoData): IFormattedCicoData => {
        const duration: number = _.sumBy(cicoEntry.checkIns, 'duration');
        const formattedDuration: string = `${this.decimalHelper.toFixedValue(
          this.decimalHelper.divide(String(duration), '3600'),
          DECIMAL_DEFAULT_SCALE_LIMIT,
        )} ${this.shortHour}`;
        const isSameDay: boolean = moment(cicoEntry.actualCheckInTime).isSame(moment(), 'day');
        const isSameDayScheduled: boolean = moment(cicoEntry.scheduledCheckInTime).isSame(moment(), 'day');
        const formattedCheckInTime: string = moment(
          this.helperService.convertFromISOFormatToGivenTimezone(cicoEntry.actualCheckInTime),
        ).format(isSameDay ? this.timeFormat$ : this.dateTimeFormat$);
        const formattedCheckOutTime: string | null =
          cicoEntry.actualCheckOutTime && cicoEntry.status === ECicoAvailability.offline
            ? moment(this.helperService.convertFromISOFormatToGivenTimezone(cicoEntry.actualCheckOutTime)).format(
                isSameDay ? this.timeFormat$ : this.dateTimeFormat$,
              )
            : null;
        const formattedScheduledCheckInTime: string | null = moment(cicoEntry.scheduledCheckInTime).format(
          isSameDayScheduled ? this.timeFormat$ : this.dateTimeFormat$,
        );
        const formattedScheduledCheckOutTime: string | null = moment(cicoEntry.scheduledCheckOutTime).format(
          isSameDayScheduled ? this.timeFormat$ : this.dateTimeFormat$,
        );
        let avatarIsLoading: boolean = false;

        if (cicoEntry.avatarPath) {
          avatarIsLoading = true;

          this.avatarsToLoad.push({ type: cicoEntry.type, path: cicoEntry.avatarPath });
        }

        lines.push(Number(cicoEntry.lineId));
        sites.push(Number(cicoEntry.siteId));

        assetExists = assetExists || cicoEntry.type === ECicoType.ASSET;

        return {
          ...cicoEntry,
          avatarIsLoading,
          formattedDuration,
          formattedCheckInTime,
          formattedCheckOutTime,
          formattedScheduledCheckInTime,
          formattedScheduledCheckOutTime,
        };
      });

    if (lines.length) {
      this.store
        .select('lineStationStore')
        .pipe(take(1))
        .subscribe((state: LineStationStateInterface) => {
          if (!state.lineStationLoading && !state.lineStationLoaded) {
            this.store.dispatch(new LineStationActions.LineStationLoading(_.uniq(lines)));
          }
        });
    }

    if (assetExists) {
      this.store
        .select('tagStore')
        .pipe(take(1))
        .subscribe((state: TagsStateInterface): void => {
          if (!state.tagsLoading && !state.tagsLoaded) {
            this.store.dispatch(
              new TagActions.LoadTags({
                filters: [
                  { field: 'objectType', ids: [TagsObjectTypes.Equipment] },
                  { field: 'siteId', ids: _.uniq(sites) },
                ],
              }),
            );
          }
        });
    }

    this.loadAvatars();
  }

  private loadAvatars(): void {
    const destroySubject: Subject<void> = new Subject<void>();

    this.subscriptions.push(
      this.actions
        .pipe(
          ofType(UserActions.GET_AVATAR_LOADED, EquipmentSettingsActions.GET_AVATAR_LOADED),
          takeUntil(destroySubject),
        )
        .subscribe((response: UserActions.GetAvatarLoaded | EquipmentSettingsActions.GetAvatarLoaded): void => {
          this.modifiedCicoData = this.modifiedCicoData.map((data: IFormattedCicoData): IFormattedCicoData => {
            const isPathEqual: boolean =
              data.avatarPath &&
              ImageHelperService.removeVersionInfoFromPath(data.avatarPath) ===
                ImageHelperService.removeVersionInfoFromPath(response.path);

            if (data.avatarPath && isPathEqual) {
              data.avatar = `data:image/png;base64,${response.response.data}`;
              data.avatarIsLoading = false;

              this.avatarsToLoad = this.avatarsToLoad.filter(
                (avatarToLoad: IAvatarToLoad): boolean => avatarToLoad.path != data.avatarPath,
              );

              _.remove(this.avatarsToLoad, { path: data.avatarPath });
            }

            return data;
          });

          if (!this.avatarsToLoad.length) {
            this.formattedCicoData = this.modifiedCicoData;

            destroySubject.next();
          }
        }),

      this.actions
        .pipe(ofType(LaborAssetHomeActions.ActionTypes.GET_LABOR_ASSET_HOME_DATA_COMPLETED))
        .subscribe((): void => {
          this.selectedItems = [];
          this.selectedItemsChanged.emit([]);
        }),
    );

    this.avatarsToLoad.forEach((avatarToLoad: IAvatarToLoad): void => {
      switch (avatarToLoad.type) {
        case ECicoType.LABOR:
          this.store.dispatch(new UserActions.GetAvatarLoading(avatarToLoad.path, this.defaultAvatarCacheOptions));
          break;
        case ECicoType.ASSET:
          this.store.dispatch(
            new EquipmentSettingsActions.GetAvatarLoading(avatarToLoad.path, this.defaultAvatarCacheOptions),
          );
          break;
      }
    });
  }

  public onClickCicoItem(item: IFormattedCicoData): void {
    if (!this.selectMode) {
      return;
    }

    const existingItemIndex: number = this.selectedItems.indexOf(item.id);

    if (existingItemIndex !== -1) {
      this.selectedItems.splice(existingItemIndex, 1);
    } else {
      this.selectedItems.push(item.id);
    }

    this.selectedItemsChanged.emit(this.selectedItems);
  }

  public onChangeStationClick(pinModalRef: TemplateRef<any>, cicoData: IChangeStation): void {
    this.changeStationData = cicoData;
    this.checkInPin = null;

    if (cicoData.type === ECicoType.LABOR) {
      this.changeStationModalRef = this.modal.open(pinModalRef, smallModal);

      return;
    }

    const data: CheckInOngoingUpdateInterface = {
      status: this.changeStationData.status === ECicoAvailability.available ? 1 : 0,
      destinationTypeId:
        this.changeStationData.toType === ECicoDestinationType.STATION
          ? ECicoDestinationTypeId.STATION
          : ECicoDestinationTypeId.LINE,
      destinationObjectId: this.changeStationData.toObjectId,
    };

    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new CheckInActions.CheckOutUpdateOngoingLoading(data, this.changeStationData.id));
  }

  public changeStationFormSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const data: CheckInOngoingUpdateInterface = {
      status:
        this.changeStationData.type === ECicoType.LABOR
          ? this.changeStationData.status === ECicoAvailability.available
            ? 1
            : 0
          : this.changeStationData.tagId,
      destinationTypeId:
        this.changeStationData.toType === ECicoDestinationType.STATION
          ? ECicoDestinationTypeId.STATION
          : ECicoDestinationTypeId.LINE,
      destinationObjectId: this.changeStationData.toObjectId,
      checkInPin: this.checkInPin,
    };

    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new CheckInActions.CheckOutUpdateOngoingLoading(data, this.changeStationData.id));

    this.checkInPin = null;
    this.changeStationModalRef.dismiss();
  }

  public isItemSelected(id: number): boolean {
    return this.selectedItems.indexOf(id) !== -1;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}

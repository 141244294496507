import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CountEntriesInterface } from '../../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatInputModule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.module';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ManualCountTypes } from '../../../view/home/models/line.model';
import { CountEntryEnum, ECountEntryMethods } from '../../../../constants';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import { FormHelperService } from '../../../shared/service/form/form.helper.service';
import { CountHelper, IManageCountOutput, TAllCounts } from '../../../shared/helper/decimal/count-helper';
import { ScwMatInputComponent } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.component';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { ScwMatFormModule } from '../../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { AsyncPipe, NgIf } from '@angular/common';

@OnDestroyDecorator
@Component({
  selector: 'scw-manage-count-modal',
  templateUrl: './manage-count-modal.component.html',
  standalone: true,
  imports: [
    BaseModalComponent,
    ScwMatButtonModule,
    TranslateModule,
    ScwMatInputModule,
    ScwMatFormModule,
    NgIf,
    AsyncPipe,
  ],
  providers: [CountHelper],
})
export class ManageCountModalComponent implements OnInit, OnDestroy {
  @ViewChild('countEntry1', { static: false }) countEntry1: ScwMatInputComponent;
  @ViewChild('countEntry2', { static: false }) countEntry2: ScwMatInputComponent;
  @ViewChild('rework', { static: false }) rework: ScwMatInputComponent;

  @Input() modal: NgbActiveModal;
  @Input() manualCountTypeSource$: Observable<ManualCountTypes> = this.store.select('line', 'manualCountType');
  @Input() countEntryMethodSource$: Observable<
    ECountEntryMethods.REDUCIBLE_CUMULATIVE | ECountEntryMethods.REDUCIBLE_INCREMENTAL
  > = this.store
    .select('line', 'countEntryMethod')
    .pipe(
      map((countEntryMethod) =>
        countEntryMethod === CountEntryEnum.INCREMENTAL
          ? ECountEntryMethods.REDUCIBLE_INCREMENTAL
          : ECountEntryMethods.REDUCIBLE_CUMULATIVE,
      ),
    );
  @Input() isReworkCountsEnabled$: Observable<boolean> = this.store.select('homeStore', 'line', 'isReworkCountEnabled');
  @Input() previousCountsSource$: Observable<TAllCounts> = this.store.select('line').pipe(
    map((line) =>
      this.countHelper.convertFlexibleCountToAllCounts({
        goodCount: this.decimalHelper.sanitizeString(line.quantities.goodCount ?? '0'),
        scrapCount: this.decimalHelper.sanitizeString(line.quantities.scrapCount ?? '0'),
        reworkCount: this.decimalHelper.sanitizeString(line.quantities.reworkCount ?? '0'),
      }),
    ),
  );
  @Input() unitNameSource$: Observable<string> = this.store
    .select('line', 'unitName')
    .pipe(map((unit) => this.translate.instant(`main.workOrder.${unit}`)));

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onConfirm: EventEmitter<IManageCountOutput> = new EventEmitter<IManageCountOutput>();
  @Output() onAdvancedModalButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public manualCountType = ManualCountTypes.YIELD_AND_SCRAP;
  public previousCounts: TAllCounts;
  public countEntryMethod: ECountEntryMethods.REDUCIBLE_CUMULATIVE | ECountEntryMethods.REDUCIBLE_INCREMENTAL =
    ECountEntryMethods.REDUCIBLE_CUMULATIVE;
  public inputLabels: CountEntriesInterface = { entry1: '', entry2: '', reworkCount: '' };
  public rules: { entry1: ScwMatInputRule[]; entry2: ScwMatInputRule[]; reworkCount: ScwMatInputRule[] } = {
    entry1: [],
    entry2: [],
    reworkCount: [],
  };
  public inputValues = { entry1: '0', entry2: '0', reworkCount: '0' };
  public readonly constantRules = {
    entry1: [this.formHelperService.getRequiredFormRule(), this.formHelperService.getDecimalRule(true)],
    entry2: [this.formHelperService.getRequiredFormRule(), this.formHelperService.getDecimalRule(true)],
    reworkCount: [this.formHelperService.getRequiredFormRule(), this.formHelperService.getDecimalRule(true)],
  } as const;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly countHelper: CountHelper,
    private readonly formHelperService: FormHelperService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        countEntryMethod: this.countEntryMethodSource$.pipe(filter(Boolean)),
        manualCountType: this.manualCountTypeSource$.pipe(filter(Boolean)),
        unitName: this.unitNameSource$.pipe(filter(Boolean)),
      })
        .pipe(withLatestFrom(this.previousCountsSource$))
        .subscribe(([{ countEntryMethod, manualCountType, unitName }, previousCounts]) => {
          this.countEntryMethod = countEntryMethod;
          this.manualCountType = manualCountType;
          this.previousCounts = previousCounts;

          const isCountEntry1Initial = [
            ManualCountTypes.INITIAL_AND_YIELD,
            ManualCountTypes.INITIAL_AND_SCRAP,
          ].includes(this.manualCountType);
          const isCountEntry2Good = this.manualCountType === ManualCountTypes.INITIAL_AND_YIELD;

          this.inputLabels = {
            entry1: isCountEntry1Initial
              ? `${this.translate.instant('main.workOrder.manuelCountHistoryTable.initialCount')} (${unitName})`
              : `${this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount')} (${unitName})`,
            entry2: isCountEntry2Good
              ? `${this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount')} (${unitName})`
              : `${this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount')} (${unitName})`,
            reworkCount: `${this.translate.instant(
              'main.workOrder.manuelCountHistoryTable.reworkCount',
            )} (${unitName})`,
          };

          if (this.countEntryMethod === ECountEntryMethods.REDUCIBLE_CUMULATIVE) {
            this.inputValues = this.countHelper.convertFlexibleCountsIntoInputCounts(
              this.previousCounts,
              this.manualCountType,
            );
          }

          this.resetRules(true);
        }),
      timer(60 * 1000).subscribe(() => {
        this.modal?.close();
      }),
    );
  }

  public resetRules(isInitialCall = false): void {
    const isCountEntry1Initial = [ManualCountTypes.INITIAL_AND_YIELD, ManualCountTypes.INITIAL_AND_SCRAP].includes(
      this.manualCountType,
    );
    const doesCountEntry2ChangeInitial = this.manualCountType === ManualCountTypes.YIELD_AND_SCRAP;

    if (this.decimalHelper.isValid(this.inputValues.entry1) && this.decimalHelper.isValid(this.inputValues.entry2)) {
      const inputCounts: TAllCounts = this.countHelper.convertInputCountsIntoAllCounts(
        this.inputValues,
        this.manualCountType,
      );

      this.rules.entry1 = [
        ...this.constantRules.entry1,
        ...this.countHelper.getErrorIfResultingCountIsNegative(
          isCountEntry1Initial ? 'initialCount' : 'goodCount',
          inputCounts,
          this.previousCounts,
          this.countEntryMethod,
        ),
      ];

      this.rules.entry2 = [
        ...this.constantRules.entry2,
        ...this.countHelper.getErrorIfResultingCountIsNegative(
          doesCountEntry2ChangeInitial ? 'initialCount' : 'goodCount',
          inputCounts,
          this.previousCounts,
          this.countEntryMethod,
        ),
      ];

      this.rules.reworkCount = [
        ...this.constantRules.reworkCount,
        ...this.countHelper.getErrorIfResultingCountIsNegative(
          'reworkCount',
          inputCounts,
          this.previousCounts,
          this.countEntryMethod,
        ),
      ];
    }

    this.changeDetectorRef.detectChanges();
    this.countEntry1.clearErrorMessage();
    this.countEntry1.checkRules();
    this.countEntry2.clearErrorMessage();
    this.countEntry2.checkRules();
    this.rework?.clearErrorMessage();
    this.rework?.checkRules();

    if (isInitialCall) {
      this.countEntry1.clearErrorMessage();
      this.countEntry2.clearErrorMessage();
      this.rework?.clearErrorMessage();
    }
  }

  public confirm(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const formattedInputCounts: TAllCounts = this.countHelper.convertInputCountsIntoAllCounts(
      this.inputValues,
      this.manualCountType,
    );
    const increments: TAllCounts =
      this.countEntryMethod === ECountEntryMethods.REDUCIBLE_CUMULATIVE
        ? this.countHelper.extractIncrementsFromTotalCounts(this.previousCounts, formattedInputCounts)
        : formattedInputCounts;
    const totalCounts: TAllCounts =
      this.countEntryMethod === ECountEntryMethods.REDUCIBLE_CUMULATIVE
        ? formattedInputCounts
        : {
            initialCount: this.decimalHelper.add(formattedInputCounts.initialCount, this.previousCounts.initialCount),
            goodCount: this.decimalHelper.add(formattedInputCounts.goodCount, this.previousCounts.goodCount),
            scrapCount: this.decimalHelper.add(formattedInputCounts.scrapCount, this.previousCounts.scrapCount),
            reworkCount: this.decimalHelper.add(formattedInputCounts.reworkCount, this.previousCounts.reworkCount),
          };

    this.onConfirm.emit({
      increments,
      totalCounts,
      previousCounts: this.previousCounts,
    });
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}

import { IFeedbackModalData, IRatingFeedback, ISubmitFeedback } from './feedback-modal.model';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { HelperService } from '../../../shared/service/helper.service';
import { distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY_ACTION, HideLoader, ShowLoader } from '../../../store/app/actions';
import { FeedbackModalService } from './feedback-modal.service';
import { HttpParams } from '@angular/common/http';
import { isEqual } from 'lodash';

export interface IFeedbackModalState {
  feedbackDataLoading: boolean;
  feedbackDataLoaded: boolean;
  feedbackData: IFeedbackModalData[] | null;
  submitFeedbackLoading: boolean;
  submitFeedbackLoaded: boolean;
  submitFeedbackData: IFeedbackModalData;
}

const defaultState: IFeedbackModalState = {
  feedbackDataLoading: false,
  feedbackDataLoaded: false,
  feedbackData: null,
  submitFeedbackLoading: false,
  submitFeedbackLoaded: false,
  submitFeedbackData: null,
};

@Injectable()
export class FeedbackModalComponentStore extends ComponentStore<IFeedbackModalState> {
  constructor(private readonly store: Store<OeeAppState>, private readonly service: FeedbackModalService) {
    super(HelperService.cloneDeep(defaultState));
  }

  // Effects
  public readonly getFeedbackData = this.effect((trigger$: Observable<void>) => {
    return trigger$.pipe(
      tap(() => {
        this.store.dispatch(new ShowLoader());
        this.patchState({ feedbackDataLoading: true, feedbackDataLoaded: false, feedbackData: null });
      }),
      switchMap(() => {
        const searchObject: any = {
          isCompleted: { eq: false },
          isDismissed: { eq: false },
        };

        const httpParams: HttpParams = new HttpParams().set('s', JSON.stringify(searchObject));

        return this.service.getFeedbacks(httpParams).pipe(
          tapResponse(
            (response) =>
              this.patchState({
                feedbackDataLoading: false,
                feedbackDataLoaded: true,
                feedbackData: response.data,
              }),
            () => {
              this.patchState(HelperService.cloneDeep(defaultState));
            },
            () => {
              this.store.dispatch(new HideLoader());
            },
          ),
          catchError(() => {
            this.store.dispatch(new HideLoader());

            return EMPTY_ACTION;
          }),
        );
      }),
      catchError(() => {
        this.store.dispatch(new HideLoader());

        return EMPTY_ACTION;
      }),
    );
  });

  public readonly submitFeedback = this.effect((trigger$: Observable<ISubmitFeedback<IRatingFeedback>>) => {
    return trigger$.pipe(
      tap(() => {
        this.store.dispatch(new ShowLoader());
        this.patchState({ submitFeedbackLoading: true, submitFeedbackLoaded: false, submitFeedbackData: null });
      }),
      switchMap((data: ISubmitFeedback<IRatingFeedback>) => {
        return this.service.submitFeedback(data).pipe(
          tapResponse(
            (response) =>
              this.patchState({
                submitFeedbackLoading: false,
                submitFeedbackLoaded: true,
                submitFeedbackData: response.data,
              }),
            () => {
              this.patchState(HelperService.cloneDeep(defaultState));
            },
            () => {
              this.store.dispatch(new HideLoader());
            },
          ),
          catchError(() => {
            this.store.dispatch(new HideLoader());

            return EMPTY_ACTION;
          }),
        );
      }),
      catchError(() => {
        this.store.dispatch(new HideLoader());

        return EMPTY_ACTION;
      }),
    );
  });

  // Selectors
  public readonly feedbackData$ = this.select((state: IFeedbackModalState) => {
    return {
      feedbackDataLoading: state.feedbackDataLoading,
      feedbackDataLoaded: state.feedbackDataLoaded,
      feedbackData: state.feedbackData,
    };
  }).pipe(distinctUntilChanged(isEqual));

  public readonly submitFeedbackData$ = this.select((state: IFeedbackModalState) => {
    return {
      submitFeedbackLoading: state.submitFeedbackLoading,
      submitFeedbackLoaded: state.submitFeedbackLoaded,
      submitFeedbackData: state.submitFeedbackData,
    };
  }).pipe(distinctUntilChanged(isEqual));
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as MicrostopsActions from './microstops.actions';
import * as AppActions from '../../../store/app/actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { MicrostopsService } from './microstops.service';
import {
  IMicrostopsDeviceMessagesElasticResponse,
  IMicrostopsAnalysisData,
  IMicrostopsActivityHistoriesElasticResponse,
  IMicrostop,
  IMicrostopActiveLine,
  IMicrostopsDeviceMessage,
} from './microstops.model';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import * as moment from 'moment';
import { ILineData } from '../root-cause-analysis/root-cause-analysis.model';
import { User } from '../../user/model';
import { HttpParams } from '@angular/common/http';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { mysqlDateFormat } from '../../../shared/helper/date';

@Injectable()
export class MicrostopsEffects {
  private startDate: moment.Moment;
  private endDate: moment.Moment;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly microstopsService: MicrostopsService,
    private readonly helperService: HelperService,
  ) {}

  getMicrostopAnalysisData = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrostopsActions.GET_MICROSTOPS_ANALYSIS_DATA),
      switchMap((objectData: MicrostopsActions.GetMicrostopsAnalysisData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        this.startDate = HelperService.cloneDeep(moment(objectData.payload.dateRange.startDate));
        this.endDate = HelperService.cloneDeep(moment(objectData.payload.dateRange.endDate));
        const timezone$: string = this.getTimezone();

        return forkJoin([
          this.microstopsService.getDeviceMessages(objectData.payload, timezone$),
          this.microstopsService.getActivityHistories(objectData.payload, timezone$),
          this.microstopsService.getOngoingActivity(objectData.payload),
        ]).pipe(
          switchMap((response: unknown) => {
            const microstopsAnalysisData: IMicrostopsAnalysisData = {
              sensorData: MicrostopsService.formatDeviceMessageData(
                _.get(response, '0.data') as IMicrostopsDeviceMessagesElasticResponse,
                timezone$,
                this.startDate,
                this.endDate,
              ),
              activityData: MicrostopsService.formatActivityData(
                _.get(response, '1.data') as IMicrostopsActivityHistoriesElasticResponse,
                _.get(response, '2.data') as ILineData[],
                timezone$,
                this.startDate,
                this.endDate,
              ),
            };

            return of(
              new MicrostopsActions.GetMicrostopsAnalysisDataCompleted(microstopsAnalysisData),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new MicrostopsActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new MicrostopsActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getMicrostops = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrostopsActions.GET_MICROSTOPS),
      switchMap((objectData: MicrostopsActions.GetMicrostops) => {
        let params: HttpParams;

        if (objectData.getWithActivityData) {
          params = new HttpParams()
            .append('join', 'activityHistory||id')
            .append('join', 'activityHistory.workOrder||id')
            .append('join', 'line||title')
            .set(
              's',
              JSON.stringify({
                type: { $eq: 'microstop' },
                'activityHistory.shiftDay': { $between: [objectData.params.start, objectData.params.end] },
                'activityHistory.siteId': { $in: objectData.params.sites },
                ...(objectData.params.lines && objectData.params.lines !== -1 && objectData.params.lines[0] !== -1
                  ? { 'activityHistory.lineId': { $in: objectData.params.lines } }
                  : {}),
                ...(objectData.params.shifts && objectData.params.shifts[0] !== -1
                  ? { 'activityHistory.shiftId': { $in: objectData.params.shifts } }
                  : {}),
                ...(objectData.params.products && objectData.params.products[0] !== -1
                  ? { 'activityHistory.workOrder.productId': { $in: objectData.params.products } }
                  : {}),
              }),
            )
            .set('limit', 5000);
        } else {
          const paramFilters: string = JSON.stringify({
            'line.siteId': { $in: objectData.params.sites },
            startedAt: { $between: [objectData.params.start, objectData.params.end] },
            endedAt: { $between: [objectData.params.start, objectData.params.end] },
            ...(objectData.params.lines && objectData.params.lines !== -1 && objectData.params.lines[0] !== -1
              ? { lineId: { $in: objectData.params.lines } }
              : {}),
          });
          params = new HttpParams().append('join', 'line||title').set('s', paramFilters).set('limit', 5000);
        }

        return this.microstopsService.getMicrostops(params).pipe(
          switchMap((response: GetManyResponseInterface<IMicrostop>) => {
            return of(new MicrostopsActions.GetMicrostopsCompleted(response.data));
          }),
          catchError((err) => {
            return of(new MicrostopsActions.FetchError(err));
          }),
        );
      }),
      catchError((error) => {
        return of(new MicrostopsActions.FetchError(error));
      }),
    ),
  );

  getSensorHistogramData = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrostopsActions.GET_SENSOR_HISTOGRAM_DATA_LOADING),
      switchMap((objectData: MicrostopsActions.GetSensorHistogramDataLoading) => {
        let params: HttpParams = new HttpParams()
          .append(
            'startDate',
            this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(objectData.filters.dateRange.startDate),
          )
          .append(
            'endDate',
            this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(objectData.filters.dateRange.endDate),
          )
          .append('lineId', objectData.filters.lineId[0])
          .append('siteId', objectData.filters.siteId[0])
          .append('sensorType', objectData.filters.sensorType[0]);

        if (
          objectData.filters.activityIds !== -1 &&
          Array.isArray(objectData.filters.activityIds) &&
          objectData.filters.activityIds.length
        ) {
          params = params.append('activityId', objectData.filters.activityIds.join(','));
        }

        if (objectData.filters.productId !== -1) {
          params = params.append('productId', objectData.filters.productId[0]);
        }

        this.store.dispatch(new AppActions.ShowLoader());

        return this.microstopsService.getSensorHistogramData(params).pipe(
          switchMap((response: { data: IMicrostopsDeviceMessage[] }) => {
            return of(new MicrostopsActions.GetSensorHistogramDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new MicrostopsActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new MicrostopsActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getMicrostopActiveLines = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrostopsActions.GET_MICROSTOP_ACTIVE_LINES),
      switchMap((objectData: MicrostopsActions.GetMicrostopActiveLines) => {
        const { startDate, endDate, lineIds, siteIds } = objectData.params;
        let params: HttpParams = new HttpParams().set('startDate', startDate).set('endDate', endDate);

        if (Array.isArray(lineIds) && lineIds?.length && lineIds[0] !== -1) {
          params = params.set('lineIds', lineIds.join(','));
        }

        if (siteIds?.length && (!Array.isArray(lineIds) || !lineIds?.length || lineIds[0] === -1)) {
          params = params.set('siteIds', siteIds.join(','));
        }

        return this.microstopsService.getMicrostopCalculationActiveLines(params).pipe(
          switchMap((response: GetManyResponseInterface<IMicrostopActiveLine>) => {
            return of(new MicrostopsActions.GetMicrostopActiveLinesCompleted(response.data));
          }),
          catchError((err) => {
            return of(new MicrostopsActions.FetchError(err));
          }),
        );
      }),
      catchError((error) => {
        return of(new MicrostopsActions.FetchError(error));
      }),
    ),
  );

  private getTimezone(): string {
    let timezone$: string = '';

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        timezone$ = user.timezone;
      });

    return timezone$;
  }
}

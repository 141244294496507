<filter-card
  [options]="filterOptions"
  [mainGridWide]="8"
  [additionalGridWide]="4"
  [strictControlForSelected]="true"
  (filterChange)="onFiltersChanged($event)"
>
  <div class="additional-fields" additionalFields>
    <div class="search-box-container">
      <div class="no-margin">
        <scw-mat-search
          placeholder="{{ 'general.search' | translate }}"
          behavior="delayed"
          [minLength]="0"
          [block]="true"
          [(inputModel)]="searchBoxText"
          (onSearch)="onKeyUpSearchBox($event)"
        ></scw-mat-search>
      </div>
    </div>
  </div>
</filter-card>

<div class="card">
  <div class="card-block">
    <div class="row p-b-15">
      <div class="col-md-6 col-sm-12">
        <scw-mat-small-button
          (onClick)="selectOrUnselectAll(floorPlans$?.length !== selectedFloorPlanIds?.length)"
          [disabled]="floorPlans$?.length === 0"
        >
          {{
            floorPlans$?.length === selectedFloorPlanIds?.length && floorPlans$?.length !== 0
              ? ('general.unselectAll' | translate)
              : ('general.selectAll' | translate)
          }}
        </scw-mat-small-button>
        <scw-mat-small-button
          class="p-l-15"
          [iconButton]="true"
          (onClick)="openModal(create_floor_plan_modal, 'edit_floor_plan_modal', selectedFloorPlan)"
          [disabled]="selectedFloorPlanIds?.length !== 1"
        >
          <em class="fas fa-pen"></em>
        </scw-mat-small-button>
        <scw-mat-small-button
          class="p-l-15"
          [iconButton]="true"
          (onClick)="openModal(delete_floor_plan_modal, 'delete_floor_plan_modal')"
          [disabled]="selectedFloorPlanIds?.length === 0"
        >
          <em class="fas fa-trash"></em>
        </scw-mat-small-button>
      </div>
      <div class="col-md-6 col-sm-12 text-right">
        <scw-mat-small-button (onClick)="openModal(create_floor_plan_modal, 'create_floor_plan_modal')">
          <em class="fas fa-plus"></em>
          {{ 'settings.lines.addFloorPlan' | translate }}
        </scw-mat-small-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-progress-bar mode="indeterminate" color="primary" [hidden]="!isDatatableLoading"></mat-progress-bar>
        <datatable
          noDataText="{{ 'datatable.noData' | translate }}"
          [serverSide]="true"
          [headers]="tableHeaders"
          [items]="floorPlans$"
          [itemsCount]="floorPlansCount$"
          [currentPage]="tableQuery.page"
          [rowsPerPage]="tableQuery.pageSize"
          (onDataRequest)="onDataRequestHandler($event)"
          [isLoading]="disableDatatable"
          heightMode="fill"
        >
          <ng-template let-item let-i="index">
            <td *ngFor="let header of tableHeaders; index as i" [class.sticky-left-col]="i === 0">
              <scw-mat-checkbox
                *ngIf="i === 0; else previewButton"
                [id]="checkboxIdPrefix + item.id"
                [value]="item.id"
                (onChange)="onChangeTableCheckboxes($event)"
              >
              </scw-mat-checkbox>
              <ng-template #previewButton>
                <ng-container *ngIf="header.value === 'preview'; else defaultTemplate">
                  <scw-mat-small-button
                    [iconButton]="true"
                    (onClick)="openModal(preview_floor_plan_modal, 'preview_floor_plan_modal', item['id'])"
                    ><em class="fas fa-eye"></em
                  ></scw-mat-small-button>
                </ng-container>
              </ng-template>
              <ng-template #defaultTemplate>
                {{ item[header.value] }}
              </ng-template>
            </td>
          </ng-template>
        </datatable>
      </div>
    </div>
  </div>
</div>

<ng-template #create_floor_plan_modal let-modal>
  <scw-mat-form #create_edit_floor_plan_form (scwMatFormSubmit)="submitFloorPlanForm($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        <ng-template [ngIf]="selectedModalType === FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL">
          {{ 'floorPlan.modal.addFloorPlan' | translate }}
        </ng-template>
        <ng-template [ngIf]="selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL">
          {{ 'floorPlan.modal.editFloorPlan' | translate }}
        </ng-template>
      </h3>
      <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="onClose(modal)">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body floor-plan-filter-card">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 d-flex justify-content-between">
              <div [ngClass]="!isPlanForSite ? 'col-3' : 'col-4'" class="p-l-0">
                <scw-mat-input
                  label="{{ 'floorPlan.modal.floorPlanName' | translate }}*"
                  [disabled]="!floorPlanForm.floorPlanName.isEnabled"
                  [(inputModel)]="floorPlanForm.floorPlanName.value"
                  [rules]="floorPlanForm.floorPlanName.rules"
                  (inputModelChange)="changesMade()"
                ></scw-mat-input>
              </div>
              <div [ngClass]="!isPlanForSite ? 'col-3' : 'col-4'" class="p-x-0">
                <scw-mat-select
                  class="scw_mat-add_edit_modal-item-input"
                  label="{{ 'floorPlan.modal.category' | translate }}*"
                  [data]="floorPlanCategoryData"
                  [disabled]="!floorPlanForm.floorPlanCategory.isEnabled"
                  [(inputModel)]="floorPlanForm.floorPlanCategory.value"
                  [rules]="floorPlanForm.floorPlanCategory.rules"
                  (inputModelChange)="onCategoryInputModelChange($event[0]?.id, false, siteField)"
                >
                </scw-mat-select>
              </div>
              <div [ngClass]="!isPlanForSite ? 'col-3' : 'col-4'" class="p-r-0">
                <scw-mat-select
                  class="scw_mat-add_edit_modal-item-input"
                  label="{{ 'floorPlan.modal.site' | translate }}*"
                  [data]="sites$"
                  [enableSearchFilter]="true"
                  [disabled]="!floorPlanForm.site.isEnabled || !floorPlanForm.floorPlanCategory.value"
                  [(inputModel)]="floorPlanForm.site.value"
                  [rules]="floorPlanForm.site.rules"
                  (inputModelChange)="onSiteInputModelChange($event[0]?.id)"
                  #siteField
                >
                </scw-mat-select>
              </div>
              <div *ngIf="floorPlanForm.floorPlanCategory.value && !isPlanForSite" class="col-3 p-r-0">
                <scw-mat-select
                  class="scw_mat-add_edit_modal-item-input"
                  label="{{ 'floorPlan.modal.line' | translate }}*"
                  [isLoading]="areLinesLoading"
                  [data]="linesForDropdown"
                  [enableSearchFilter]="true"
                  [disabled]="!floorPlanForm.line.isEnabled || !floorPlanForm.site.value || areLinesLoading"
                  [(inputModel)]="floorPlanForm.line.value"
                  [rules]="floorPlanForm.line.rules"
                  (inputModelChange)="onLineInputModelChange($event[0]?.id)"
                >
                </scw-mat-select>
              </div>
            </div>
          </div>
          <div class="row dragArea">
            <ng-container
              [ngTemplateOutlet]="
                (floorPlanForm?.floorPlanCategory?.value?.length && isCardViewMode) ||
                (previousFloorPlanCategory?.value?.length > 0 && isCardViewMode)
                  ? cardView
                  : globalView
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="onClose(modal)">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button
          [isSubmitButton]="true"
          #create_edit_modal_submit_button
          id="createEditModalSubmitButton"
          [disabled]="
            this.paths?.length > 0 ? !this.paths[0].name === null || this.paths[0].selectedNodeIds?.length === 0 : true
          "
        >
          <ng-template
            [ngIf]="selectedModalType === FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL"
            [ngIfElse]="saveChanges"
          >
            {{ 'settings.lines.addFloorPlan' | translate }}
          </ng-template>
          <ng-template #saveChanges>
            {{ 'general.saveChanges' | translate }}
          </ng-template>
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<ng-template #cardView>
  <div class="w-100 h-100">
    <div class="row m-0 w-100 px-3">
      <div class="mb-3">
        <div class="d-flex">
          <scw-mat-button-group
            [disabled]="!!paths?.length"
            [class]="'mr-3'"
            size="xs"
            [stateless]="true"
            [buttons]="zoomButtonGroupCardView"
            (inputModelChange)="zoomInOutForCardView($event)"
          >
          </scw-mat-button-group>
          <scw-mat-small-button
            class="mr-3"
            (onClick)="removeAllDraggedElements(delete_path_confirmation_modal)"
            [disabled]="!pathNodesUsedCardView?.length || hasAssigmentProductConfiguration"
          >
            <em class="fa fa-trash"></em>
            {{
              !isPlanForSite
                ? ('floorPlan.modal.removeStations' | translate)
                : ('floorPlan.modal.removeLines' | translate)
            }}
          </scw-mat-small-button>
        </div>
      </div>
      <div class="draggable-boundary w-100">
        <div class="row m-0">
          <div class="col-9 pl-0">
            <div class="draggable-box w-100" #dragContainer>
              <span id="menu" class="fa fa-trash delete-arrow" (click)="deleteArrow()"></span>
              <ng-template #arrowTemplate>
                <svg class="px-3 w-100 h-100 position-absolute svg-container arrow-container" style="left: 0; top: 0">
                  <line
                    (click)="openDeleteArrowIcon($event)"
                    class="arrow"
                    id="color-changing-line"
                    stroke-width="3"
                    stroke="#3580f9"
                    marker-end="url(#arrow)"
                  />
                  <marker markerWidth="6" markerHeight="6" refY="3" orient="auto" markerUnits="strokeWidth">
                    <path d="M0,0 L6,3 L0,6 Z" fill="#3580f9" />
                  </marker>
                </svg>
              </ng-template>
            </div>
          </div>
          <div class="col-3 p-r-0 d-flex flex-column">
            <div
              *ngIf="floorPlanForm?.floorPlanCategory?.value || previousFloorPlanCategory?.value?.length > 0"
              class="line-view-type-group-buttons"
            >
              <scw-mat-button-group
                size="sm"
                class="d-inline-block switch-button"
                [buttons]="linePlanViewTypeGroupButtons"
                [(inputModel)]="linePlanViewType"
                (inputModelChange)="onLinePlanViewTypeChange($event)"
              ></scw-mat-button-group>
              <em
                [ngClass]="'fas fa-info-circle line-view-type-group-buttons-tooltip'"
                [ngbTooltip]="isPlanForSite ? sitePlanViewTypeTooltip : linePlanViewTypeTooltip"
                [autoClose]="false"
                container="body"
              ></em>
            </div>

            <scw-mat-select
              *ngIf="(!floorPlanForm.floorPlanCategory.value || isPlanForSite) && !paths?.length"
              class="scw_mat-add_edit_modal-item-input flex-grow-0"
              label="{{ 'floorPlan.modal.departmentName' | translate }}"
              [disabled]="!floorPlanForm.site.value"
              [data]="departmentsToDisplay"
              [isRequired]="true"
              [enableSearchFilter]="true"
              [singleSelection]="false"
              [(inputModel)]="floorPlanDepartmentInputModel"
              (inputModelChange)="onLinesToDisplayChange()"
              [showCheckbox]="true"
              [enableCheckAll]="true"
            >
            </scw-mat-select>

            <div
              id="accordion"
              [style.height]="!isPlanForSite || paths?.length ? 'calc(100vh - 400px)' : 'calc(100vh - 480px)'"
              [style.maxHeight]="isPlanForSite && !paths?.length ? '572px' : '652px'"
            >
              <div
                class="card mb-3 w-100 m-b-0 p-3 overflow-auto path-node-list"
                [class.flex-child]="isSideListExpanded"
                [class.card-with-message]="!isPlanForSite && (!paths?.length && stations$.length !== 0 && stations$?.length === pathNodesUsedCardView?.length)"
                [ngClass]="{
                  'path-node-card-invalid':
                    (
                      (stations$?.length !== pathNodesUsedCardView?.length && !isPlanForSite) ||
                      (isPlanForSite && pathNodesUsedCardView?.length < 2) ||
                      (stations$.length === 1 && pathNodesUsedCardView?.length !== 1)
                    ) &&
                    !hasAssigmentProductConfiguration
                }"
                [style.height]="isPlanForSite ? 'calc(100vh - 550px)' : 'calc(100vh - 470px)'"
              >
                <div
                  class="card-header row justify-content-between align-items-center mx-0 border-0"
                  [ngClass]="paths?.length ? 'pb-0' : 'pb-1'"
                >
                  <h5 class="mb-0">
                    <scw-mat-small-button
                      id="pathNodeListCollapseButton"
                      class="btn btn-link position-absolute collapse-button"
                      data-toggle="collapse"
                      data-target="#pathNodeList"
                      aria-expanded="false"
                      aria-controls="pathNodeList"
                    >
                      <em class="fas fa-pen"></em>
                    </scw-mat-small-button>
                  </h5>
                  <div class="col-12 d-flex justify-content-between align-items-center px-0 path-list-title-card">
                    <div>
                      <scw-mat-button
                        *ngIf="isPlanForSite && !paths?.length && !hasAssigmentProductConfiguration"
                        class="m-r-5 lines-chevron-button"
                        type="ghost"
                        size="sm"
                        [iconButton]="true"
                        (onClick)="collapseOrExpandPathNodeList()"
                      >
                        <em *ngIf="!isSideListExpanded" class="fas fa-chevron-right"></em>
                        <em *ngIf="isSideListExpanded" class="fas fa-chevron-down"></em>
                      </scw-mat-button>
                      <h5 class="m-0 mr-2 d-inline-block pt-1">
                        {{
                          !isPlanForSite
                            ? ('floorPlan.modal.stations' | translate)
                            : ('floorPlan.modal.lines' | translate)
                        }}
                      </h5>
                    </div>
                    <ng-container *ngIf="paths?.length">
                      <ng-container *ngTemplateOutlet="editFloorPlanButton"></ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (!isPlanForSite && pathNodesUsedCardView?.length !== stations$?.length) ||
                        (isPlanForSite && !paths?.length && !hasAssigmentProductConfiguration)
                      "
                    >
                      <ng-container *ngTemplateOutlet="sideListItemSearch"></ng-container>
                    </ng-container>
                  </div>
                  <h6
                    class="col-12 d-flex card-view-warning-message px-0 mb-0"
                    *ngIf="
                      modifiedSideListItems?.length > 0 &&
                      stations$?.length !== pathNodesUsedCardView?.length &&
                      !isPlanForSite &&
                      !hasAssigmentProductConfiguration
                    "
                  >
                    <i class="fas fa-info-circle m-r-5"></i> {{ 'floorPlan.warningMessage.dragAllStation' | translate }}
                  </h6>
                  <h6
                    class="col-12 d-flex card-view-warning-message px-0 mb-0"
                    *ngIf="modifiedSideListItems?.length > 0 && pathNodesUsedCardView?.length < 2 && isPlanForSite"
                  >
                    <i class="fas fa-info-circle m-r-5"></i> {{ 'floorPlan.warningMessage.dragTwoLines' | translate }}
                  </h6>
                  <h6
                    *ngIf="!isPlanForSite && (paths.length == 0 && stations$.length !== 0 && stations$?.length === pathNodesUsedCardView?.length)"
                    class="col-12 d-flex card-view-info-message px-0 mb-0"
                  >
                    <i class="fas fa-info-circle m-r-5"></i>
                    {{ 'floorPlan.infoMessage.arrowConnection' | translate }}
                  </h6>
                </div>

                <div
                  id="pathNodeList"
                  class="collapse path-node-list-content show"
                  data-parent="#accordion"
                  [style.overflow]="paths?.length && !hasAssigmentProductConfiguration ? 'hidden' : 'auto'"
                  [style.display]="isPlanForSite && paths.length ? 'none' : 'block'"
                >
                  <hr
                    *ngIf="!isPlanForSite && !(stations$.length !== 0 && stations$?.length === pathNodesUsedCardView?.length && !paths?.length)"
                    class="my-2"
                  />
                  <div
                    class="d-flex align-items-center side-list-item justify-content-center"
                    (cdkDragStarted)="onDragStart($event.source.element.nativeElement.id)"
                    (cdkDragEnded)="onDragEnd($event)"
                    (click)="onClickPathNodeBox($event)"
                    *ngFor="let sideListItem of modifiedSideListItems"
                    [attr.id]="sideListItem.id"
                    [cdkDragFreeDragPosition]="sideListItem.position"
                    [style.color]="sideListItem.colorSetting.text"
                    [style.background]="sideListItem.colorSetting.backgroundColor"
                    [cdkDragBoundary]="getElementBoundary(sideListItem.id)"
                    (cdkDragMoved)="updateElementSize($event.source.element.nativeElement)"
                    [cdkDragDisabled]="sideListItem.isNotDraggable"
                    [style.display]="!isPlanForSite && stations$?.length !== pathNodesUsedCardView?.length ? 'none' : 'block'"
                    cdkDrag
                  >
                    <em class="fas fa-sign-in-alt initial-line-icon"></em>
                    <em class="fas fa-sign-out-alt good-line-icon"></em>
                    <span id="nodeName" class="d-inline-block w-100">{{ sideListItem.name }}</span>
                    <span
                      class="arrow-point shadow point-left"
                      (click)="drawArrow($event)"
                      [style.pointer-events]="
                        drawArrowOptions.drawStartLocation.lineId === sideListItem.id.toString() ? 'none' : 'all'
                      "
                    ></span>
                    <span
                      class="arrow-point shadow point-right"
                      (click)="drawArrow($event)"
                      [style.pointer-events]="
                        drawArrowOptions.drawStartLocation.lineId === sideListItem.id.toString() ? 'none' : 'all'
                      "
                    ></span>
                    <span
                      class="arrow-point shadow point-top"
                      (click)="drawArrow($event)"
                      [style.pointer-events]="
                        drawArrowOptions.drawStartLocation.lineId === sideListItem.id.toString() ? 'none' : 'all'
                      "
                    ></span>
                    <span
                      class="arrow-point shadow point-bottom"
                      (click)="drawArrow($event)"
                      [style.pointer-events]="
                        drawArrowOptions.drawStartLocation.lineId === sideListItem.id.toString() ? 'none' : 'all'
                      "
                    ></span>
                    <span class="delete-button" (click)="deleteDraggedElement($event, sideListItem.id)">x</span>
                  </div>
                </div>
              </div>
              <div
                class="card p-3 path-list-card mb-0"
                [class.flex-child]="!isSideListExpanded"
                [class.path-node-card-invalid]="showCreatePathWarningMessage"
              >
                <div class="card-header border-0" [ngClass]="paths?.length ? 'pb-1' : 'pb-0'">
                  <div class="d-flex justify-content-between">
                    <div>
                      <scw-mat-button
                        *ngIf="isPlanForSite && (!paths?.length && !hasAssigmentProductConfiguration)"
                        class="m-r-5 lines-chevron-button"
                        type="ghost"
                        size="sm"
                        [iconButton]="true"
                        (onClick)="collapseOrExpandPathNodeList()"
                      >
                        <em *ngIf="isSideListExpanded" class="fas fa-chevron-right"></em>
                        <em *ngIf="!isSideListExpanded" class="fas fa-chevron-down"></em>
                      </scw-mat-button>
                      <h5 class="m-0 mr-2 d-inline-block">
                        {{ 'floorPlan.createPath' | translate }}
                      </h5>
                      <em
                        ngClass="fas fa-info-circle"
                        container="body"
                        [ngbTooltip]="isPlanForSite ? linePathJobCountDropdownInfo : stationPathWorkOrderCountDropdownInfo"
                        [autoClose]="false"
                      ></em>
                    </div>
                    <scw-mat-small-button
                      [disabled]="
                        (!isPlanForSite && paths?.length >= 20) || (isPlanForSite && pathNodesUsedCardView?.length < 2)
                      "
                      id="pathListCollapseButton"
                      class="collapse-button"
                      (click)="createPath()"
                    >
                      <em class="fas fa-plus font-weight-bolder"></em>
                    </scw-mat-small-button>
                  </div>
                  <h6 class="card-view-warning-message px-0 mb-0" *ngIf="showCreatePathWarningMessage">
                    <i class="fas fa-info-circle m-r-5"></i> {{ 'floorPlan.warningMessage.createPath' | translate }}
                  </h6>
                </div>
                <div id="pathList" class="collapse path-list-card-body h-100" data-parent="#accordion">
                  <div class="card-body px-0" [ngClass]="isSideListExpanded ? 'py-0' : 'py-2'">
                    <h6 class="text-muted font-weight-normal" *ngIf="paths?.length">
                      {{
                        isPlanForSite
                          ? ('floorPlan.warningMessage.chooseLines' | translate)
                          : ('floorPlan.warningMessage.chooseStations' | translate)
                      }}
                    </h6>
                    <div
                      *ngFor="let path of paths; let i = index"
                      class="card border-left border-right border-top mb-2 p-2 path-settings-card"
                      (click)="onClickSelectPathCard(i)"
                      [ngClass]="{
                        'selected-path': path.isSelected,
                        'cursor-pointer': !path.isSelected,
                        'default-path': path.isDefaultPath,
                        'duplicate-path': path.isDuplicate
                      }"
                    >
                      <div *ngIf="path.isDuplicate" class="px-1 duplicate-path-warning-message">
                        <em class="fas fa-info-circle"></em>
                        {{ 'floorPlan.duplicatePath' | translate : { pathName: isPlanForSite ? duplicateLinePathName : duplicateStationPathName } }}
                      </div>
                      <div class="row m-0">
                        <div class="col-5 px-1 py-0">
                          <scw-mat-input
                            class="path-name-input"
                            *ngIf="path.isSelected"
                            size="xs"
                            placeholder="{{ 'floorPlan.pathName' | translate }}"
                            [disabled]="
                              !!path?.lineProductStationPathAssignments?.length ||
                              !!path.linePathProductConfigurations?.length
                            "
                            [hasErrors]="path.errorSettings.pathNameHasError"
                            [errorText]="path.errorSettings.pathNameHasError ? pathNameErrorMessage : null"
                            [(inputModel)]="path.name"
                            (inputModelChange)="isPathFormInvalid(true)"
                          ></scw-mat-input>
                          <h6 class="my-1 font-weight-normal" *ngIf="!path.isSelected">
                            {{ path.name }}
                          </h6>
                        </div>
                        <div class="col-1 px-1 py-0">
                          <span
                            class="p-2 rounded-circle d-inline-block mt-1"
                            [style.background]="path.colorSettings.backgroundColor"
                          ></span>
                        </div>
                        <div class="col-6 px-1 py-0 text-right">
                          <span
                            class="d-inline-block p-1"
                            [ngClass]="{ 'selected-path-node-warning': path.errorSettings.selectedPathNodeHasError }"
                          >
                            {{
                              'floorPlan.selectedPathNodeCount.info'
                                | translate
                                  : {
                                      count: path.selectedNodeIds?.length,
                                      pathNodeType: isPlanForSite
                                        ? ('floorPlan.selectedPathNodeCount.line' | translate)
                                        : ('floorPlan.selectedPathNodeCount.station' | translate)
                                    }
                            }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="job-count-dropdown-container">
                          <h6 class="pl-1 my-1 font-weight-normal p-r-10 align-self-start job-count-label">
                            {{ (
                                isPlanForSite ?
                                  'floorPlan.linePath.jobInitialCount' :
                                  'floorPlan.stationPath.workOrderInitial'
                               ) | translate
                            }}*
                          </h6>
                          <scw-mat-select
                            class="scw_mat-add_edit_modal-item-input pr-1"
                            size="xs"
                            noDataLabel="{{
                              (
                                isPlanForSite ?
                                  'floorPlan.linePath.pathHasNoSelectedLine' :
                                  'floorPlan.linePath.pathHasNoSelectedStation'
                              ) | translate
                            }}"
                            [class.job-count-line-dropdown]="i < 2"
                            [position]="i < 2 ? 'bottom' : 'top'"
                            [autoPosition]="false"
                            [disabled]="!path.isSelected || !!path.linePathProductConfigurations?.length || !!path?.lineProductStationPathAssignments?.length"
                            [singleSelection]="false"
                            [data]="
                              isPlanForSite ?
                                jobCountLines :
                                workOrderCountStations
                            "
                            [hasErrors]="
                              isPlanForSite ?
                                path.errorSettings.selectedPathJobInitialCountHasError :
                                path.errorSettings.selectedPathWorkOrderInitialCountHasError
                            "
                            [errorText]="
                              (
                                isPlanForSite ?
                                path.errorSettings.selectedPathJobInitialCountHasError :
                                path.errorSettings.selectedPathWorkOrderInitialCountHasError
                              ) ? requiredLabel : null
                            "
                            [inputModel]="isPlanForSite ? path.initialCountLines : path.initialCountStations"
                            (inputModelChange)="
                              isPlanForSite ? path.initialCountLines=$event : path.initialCountStations=$event;
                              isPlanForSite ?
                                onJobCountLinesChange(path, 'initialCountLines') :
                                onWorkOrderCountStationsChange(path, 'initialCountStations')
                            "
                          >
                          </scw-mat-select>
                        </div>
                        <div class="job-count-dropdown-container">
                          <h6 class="pl-1 my-1 font-weight-normal p-r-10 align-self-start job-count-label">
                            {{
                              (
                                isPlanForSite ?
                                  'floorPlan.linePath.jobGoodCount' :
                                  'floorPlan.stationPath.workOrderGood'
                              ) | translate
                            }}*
                          </h6>
                          <scw-mat-select
                            class="scw_mat-add_edit_modal-item-input pr-1 job-count-line-dropdown"
                            size="xs"
                            noDataLabel="{{
                              (
                                isPlanForSite ?
                                'floorPlan.linePath.pathHasNoSelectedLine' :
                                'floorPlan.linePath.pathHasNoSelectedStation'
                              ) | translate
                            }}"
                            [class.job-count-line-dropdown]="i < 2"
                            [position]="i < 2 ? 'bottom' : 'top'"
                            [autoPosition]="false"
                            [disabled]="!path.isSelected || !!path.linePathProductConfigurations?.length || !!path?.lineProductStationPathAssignments?.length"
                            [singleSelection]="false"
                            [data]="isPlanForSite ? jobCountLines : workOrderCountStations"
                            [hasErrors]="
                              isPlanForSite ?
                              path.errorSettings.selectedPathJobGoodCountHasError :
                              path.errorSettings.selectedPathWorkOrderGoodCountHasError
                            "
                            [errorText]="
                              (
                                isPlanForSite ?
                                path.errorSettings.selectedPathJobGoodCountHasError :
                                path.errorSettings.selectedPathWorkOrderGoodCountHasError
                              ) ? requiredLabel : null"
                            [inputModel]="isPlanForSite ? path.goodCountLines : path.goodCountStations"
                            (inputModelChange)="
                            isPlanForSite ? path.goodCountLines=$event : path.goodCountStations=$event ;
                              isPlanForSite ?
                              onJobCountLinesChange(path, 'goodCountLines') :
                              onWorkOrderCountStationsChange(path, 'goodCountStations')
                            "
                          >
                          </scw-mat-select>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between px-1 path-bottom-button" *ngIf="path.isSelected">
                        <span
                          class="text-danger cursor-pointer"
                          *ngIf="
                            !path?.lineProductStationPathAssignments?.length &&
                            !path.linePathProductConfigurations?.length
                          "
                          (click)="deletePath()"
                          >{{ 'floorPlan.deletePath' | translate }}</span
                        >
                        <span
                          class="text-muted"
                          *ngIf="
                            path?.lineProductStationPathAssignments?.length ||
                            path.linePathProductConfigurations?.length
                          "
                        >
                          <em
                            [ngClass]="'fas fa-info-circle'"
                            [ngbTooltip]="floorPlanReadOnlyPathTooltip"
                            [autoClose]="false"
                            container="body"
                          ></em>
                          {{ 'floorPlan.readOnly' | translate }}</span
                        >
                        <span
                          [ngClass]="{
                            'text-muted': path.isDefaultPath,
                            'cursor-pointer text-primary': !path.isDefaultPath
                          }"
                          (click)="setAsDefaultPath(path.isDefaultPath)"
                          >{{
                            path.isDefaultPath
                              ? ('floorPlan.defaultPath' | translate)
                              : ('floorPlan.setAsDefaultPath' | translate)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #globalView>
  <div class="col-12 d-flex justify-content-between floor-plan-section">
    <div class="col-9 p-l-0 d-flex flex-column">
      <div class="mb-3 d-flex justify-content-between">
        <div class="d-flex">
          <i class="fas fa-info-circle p-r-12 lh-25" [ngbTooltip]="editorTooltipTemplate" container="body"></i>
          <scw-mat-button-group
            [class]="'mr-3'"
            size="xs"
            [stateless]="true"
            [buttons]="zoomButtonGroup"
            (inputModelChange)="applyZoom($event)"
          >
          </scw-mat-button-group>
          <scw-mat-small-button class="mr-3" (onClick)="removeImage(true)" [disabled]="!isImageUploaded">
            <em class="fa fa-trash"></em> {{ 'floorPlan.modal.removeImage' | translate }}
          </scw-mat-small-button>
          <scw-mat-small-button
            class="mr-3"
            (onClick)="removePlanItemsFromFloorPlan(false, true)"
            [disabled]="!isImageUploaded || (!linesUsed?.length && !stationsUsed?.length)"
          >
            <em class="fa fa-trash"></em>
            {{
              !isPlanForSite
                ? ('floorPlan.modal.removeStations' | translate)
                : ('floorPlan.modal.removeLines' | translate)
            }}
          </scw-mat-small-button>
        </div>

        <div>
          <scw-mat-small-button (onClick)="replaceImage()" [disabled]="!isImageUploaded">
            <em class="fa fa-plus"></em> {{ 'floorPlan.modal.replaceImage' | translate }}
          </scw-mat-small-button>
        </div>
      </div>

      <div
        id="floorPlanCard"
        class="card w-100 position-relative m-b-0"
        #floorPlanCard
        (dragenter)="$event.preventDefault()"
        (dragover)="$event.preventDefault()"
        (drop)="sideListItemDropHandler($event)"
      >
        <input
          hidden
          id="imageSelectionDialogInput"
          #imageSelectionDialogInput
          type="file"
          accept=".png,.jpg,.jpeg"
          (change)="openModal(crop, 'crop_image_modal', $event)"
          class="upload input-common"
        />
        <ng-container *ngIf="isEditModeInitialImageLoading && !isImageUploaded">
          <div class="loader-block m-auto h-100" [hidden]="!isEditModeInitialImageLoading || isImageUploaded">
            <svg id="loader2" viewBox="0 0 100 100">
              <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
            </svg>
          </div>
        </ng-container>
        <scw-mat-button
          class="upload-button"
          [disabled]="!floorPlanForm.floorPlanCategory.value || floorPlanForm.floorPlanCategory.value?.length === 0"
          [hidden]="isImageUploaded || isEditModeInitialImageLoading"
          (onClick)="uploadImage(crop)"
        >
          {{ 'floorPlan.modal.uploadImage' | translate }}
        </scw-mat-button>
      </div>
    </div>

    <div class="col-3 p-r-0 p-t-41 d-flex flex-column">
      <div *ngIf="floorPlanForm.floorPlanCategory.value" class="line-view-type-group-buttons">
        <scw-mat-button-group
          size="sm"
          class="d-inline-block switch-button"
          [buttons]="linePlanViewTypeGroupButtons"
          [(inputModel)]="linePlanViewType"
          (inputModelChange)="onLinePlanViewTypeChange($event)"
        ></scw-mat-button-group>
        <em
          [ngClass]="'fas fa-info-circle line-view-type-group-buttons-tooltip'"
          [ngbTooltip]="isPlanForSite ? sitePlanViewTypeTooltip : linePlanViewTypeTooltip"
          [autoClose]="false"
          container="body"
        ></em>
      </div>
      <scw-mat-select
        *ngIf="!floorPlanForm.floorPlanCategory.value || isPlanForSite"
        class="scw_mat-add_edit_modal-item-input flex-grow-0"
        label="{{ 'floorPlan.modal.departmentName' | translate }}"
        [disabled]="!floorPlanForm.site.value"
        [data]="departmentsToDisplay"
        [isRequired]="true"
        [enableSearchFilter]="true"
        [singleSelection]="false"
        [(inputModel)]="floorPlanDepartmentInputModel"
        (inputModelChange)="onLinesToDisplayChange()"
        [showCheckbox]="true"
        [enableCheckAll]="true"
      >
      </scw-mat-select>

      <div class="card w-100 h-100 flex-grow-1 d-flex m-b-0 p-3 overflow-auto">
        <div class="card-header d-flex justify-content-between align-items-center m-b-10">
          <div class="d-flex align-items-center">
            <i
              class="fas fa-info-circle m-r-5"
              [ngbTooltip]="
                !isPlanForSite
                  ? ('floorPlans.modal.stationsTooltip.mainMessage' | translate)
                  : ('floorPlans.modal.linesTooltip.mainMessage' | translate)
              "
              container="body"
            ></i>
            <h6 class="m-0 mr-2">
              {{ !isPlanForSite ? ('floorPlan.modal.stations' | translate) : ('floorPlan.modal.lines' | translate) }}
            </h6>
          </div>
          <scw-mat-search
            size="xs"
            [disabled]="isSideListSearchBoxDisabled"
            [(inputModel)]="sideListSearchTextInputModel"
            (inputModelChange)="onSideListItemsChange()"
          ></scw-mat-search>
        </div>
        <div class="d-flex flex-column overflow-auto flex-grow-1">
          <mat-progress-bar mode="indeterminate" color="primary" [hidden]="!areSideItemsLoading"></mat-progress-bar>
          <ng-template [ngIf]="isSideListDataNotAvailable && !areSideItemsLoading" [ngIfElse]="sideList">
            {{ 'datatable.noData' | translate }}
          </ng-template>
          <ng-template #sideList>
            <div
              *ngFor="let item of sideListItems"
              [draggable]="isImageUploaded"
              class="side-list-item"
              (dragend)="sideListItemIncorrectDropHandler($event)"
              (dragstart)="sideListItemDragStartHandler($event)"
              [id]="item.id"
              [style.color]="item.colorSetting.text"
              [style.background]="item.colorSetting.backgroundColor"
            >
              {{ item.name }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #delete_floor_plan_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <ng-template [ngIf]="selectedFloorPlanIds?.length > 1" [ngIfElse]="singleDeleteHeader">
        {{ 'floorPlan.modal.deleteFloorPlans' | translate }}
      </ng-template>
      <ng-template #singleDeleteHeader>
        {{ 'floorPlan.modal.deleteFloorPlan' | translate }}
      </ng-template>
    </h3>
    <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          <ng-template [ngIf]="selectedFloorPlanIds?.length > 1" [ngIfElse]="singleDelete">
            {{ 'floorPlan.modal.deleteBulkInfo' | translate : { count: selectedFloorPlanIds?.length } }}
          </ng-template>
          <ng-template #singleDelete>
            {{ 'floorPlan.modal.deleteInfo' | translate : { floorPlan: selectedFloorPlan?.name } }}
          </ng-template>
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="deleteFloorPlans()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #arrowTemplatePreview>
  <svg class="px-3 w-100 h-100 position-absolute svg-container" style="left: 0; top: 0">
    <line class="arrow" id="color-changing-line" stroke-width="3" stroke="#3580f9" marker-end="url(#arrow)" />
    <marker markerWidth="6" markerHeight="6" refY="3" orient="auto" markerUnits="strokeWidth">
      <path d="M0,0 L6,3 L0,6 Z" fill="#3580f9" />
    </marker>
  </svg>
</ng-template>

<ng-template #preview_floor_plan_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'floorPlan.modal.floorPlanPreview' | translate }}
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      onfocus="blur()"
      (click)="dismissFloorPlanWithImage(modal, true)"
    >
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>

  <div class="line-view-type-group-buttons mt-4 mx-4">
    <scw-mat-button-group
      [class]="'mb-3'"
      size="xs"
      [stateless]="true"
      [buttons]="zoomButtonGroup"
      (inputModelChange)="
        linePlanViewTypeForPreview === ELinePlanViewTypeEnum.cardView
          ? previewZoomForCardView($event)
          : applyZoom($event)
      "
    >
    </scw-mat-button-group>

    <scw-mat-button-group
      [class]="'mb-3'"
      size="xs"
      [buttons]="linePlanPreviewModalGroupButtons"
      [(inputModel)]="linePlanViewTypeForPreview"
      (inputModelChange)="this.onPreviewLinePlanViewTypeChange()"
    ></scw-mat-button-group>
  </div>

  <ng-template [ngIf]="linePlanViewTypeForPreview === ELinePlanViewTypeEnum.cardView" [ngIfElse]="mapView">
    <div #cardViewPreviewBox class="draggable-box preview-box-card-view">
      <div
        class="dragged-element side-list-item d-flex preview-item"
        *ngFor="let sideListItem of modifiedSideListItems"
        [attr.id]="sideListItem.id"
        [cdkDragFreeDragPosition]="sideListItem.position"
        [cdkDragDisabled]="true"
        cdkDrag
      >
        <span class="d-inline-block w-100 my-auto">{{ sideListItem.name }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #mapView>
    <div class="mx-4 mb-4">
      <div class="loader-block" *ngIf="!isSelectedFloorPlanImageLoaded">
        <svg id="loader2" viewBox="0 0 100 100">
          <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
        </svg>
      </div>

      <div id="floorPlanPreviewCard" class="card mb-0" [hidden]="!isSelectedFloorPlanImageLoaded"></div>
    </div>
  </ng-template>

  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="dismissFloorPlanWithImage(modal, true)">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #crop let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'cropModal.title' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-crop-modal
    [file]="floorPlanImageFile"
    [previouslySelectedImage]="floorPlanForm.image.value"
    (onSubmit)="updateFloorPlanImageInputModel($event)"
    (onMaxAllowedImageSizeExceeded)="showMaxAllowedImageSizeExceededWarning()"
    (onSelectNewClicked)="uploadImage()"
    [isRoundCropper]="false"
    [isMaintainingAspectRatio]="false"
    [resizeToWidth]="0"
    [resizeToHeight]="0"
  >
  </app-crop-modal>
</ng-template>

<ng-template #bulk_error_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'floorPlan.modal.deleteFloorPlans' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" onfocus="blur()" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <div>
        <h6 class="scw-header">
          {{ 'floorPlans.modal.bulkErrorSentence1' | translate : { count: bulkDeleteErrorModalSuccessfulCount } }}
        </h6>
        <h6 class="scw-header mt-22 color-red">
          {{ 'floorPlans.modal.bulkErrorSentence2' | translate }}
        </h6>
        <div class="mt-22">
          <div class="h6" *ngFor="let item of bulkDeleteErrorModalData">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #unsaved_changes_modal_for_cardView let-modal>
  <scw-mat-modal (onClickClose)="discardModalButtonsClick('close-modal', modal)">
    <h3 class="modal-title scw-header" title>{{ 'general.discardChanges' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'kpiCards.pageConfiguration.unsavedComments.label' | translate"
      ></scw-mat-modal-text-with-icon>
    </div>
    <div class="modal-footer-buttons w-100" footer>
      <div class="buttons-right">
        <scw-mat-button (click)="discardModalButtonsClick('close-modal', modal)" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button (click)="discardModalButtonsClick('discard-modal', modal)">
          {{ 'general.discard' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #delete_path_confirmation_modal let-modal>
  <scw-mat-modal (onClickClose)="modal.close('cancel')">
    <h3 class="modal-title scw-header" title>{{ 'general.discardChanges' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="
          isPlanForSite
            ? ('floorPlan.deletePathConfirmationMessageSitePlan' | translate)
            : ('floorPlan.deletePathConfirmationMessage' | translate)
        "
      ></scw-mat-modal-text-with-icon>
    </div>
    <div class="modal-footer-buttons w-100" footer>
      <div class="buttons-right">
        <scw-mat-button (click)="modal.close()" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button (click)="proceedToDeletePath(modal)">
          {{ 'general.proceed' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #editorTooltipTemplate>
  <div>{{ editorInfoTooltipMessage }}</div>
  <ul>
    <li>{{ editorInfoTooltipLeftClickMessage }}</li>
    <li>{{ editorInfoTooltipRightClickMessage }}</li>
    <li>{{ editorInfoTooltipDoubleClickMessage }}</li>
  </ul>
</ng-template>

<ng-template #editFloorPlanButton>
  <div>
    <em
      *ngIf="hasAssigmentProductConfiguration"
      ngClass="fas fa-info-circle mr-1"
      [ngbTooltip]="isPlanForSite ? siteFloorPlanNotChangeableInfoTooltip : lineFloorPlanNotChangeableInfoTooltip"
      [autoClose]="false"
      container="body"
    ></em>
    <scw-mat-small-button
      class="p-0"
      [disabled]="hasAssigmentProductConfiguration"
      (click)="openDeletePathConfirmationModal(delete_path_confirmation_modal)"
    >
      <em class="fas fa-pen font-weight-bolder"></em>
    </scw-mat-small-button>
  </div>
</ng-template>

<ng-template #sideListItemSearch>
  <scw-mat-search
    size="xs"
    class="card-view-side-list-item-search"
    [disabled]="isSideListSearchBoxDisabled"
    [(inputModel)]="sideListSearchTextInputModelForCardView"
    (inputModelChange)="onSideListItemsChange()"
  ></scw-mat-search>
</ng-template>
